<div class="col-12" (click)="openModal()">
  <div class="row">
    <div class="col-icon-img">
      <img
        src="assets/images/icons/gallery.on.rectangle.fill.svg"
        class="img-fluid"
        alt=""
        style="cursor:pointer;"
      />
    </div>
  </div>
</div>
<ng-template #modalContent>
  <div class="no-data" *ngIf="order.files.length == 0">
    <div class="dashboard" style="padding-left: 1rem;">
      <div class="mensajes mensaje-warning">
        <div class="mensajes-text">
          <strong>No se encontraron documentos asociados</strong>
        </div>
      </div>
      <div class="buttons">
        <a
          href="javascript:void(0)"
          (click)="closeModal()"
          class="btn btn-primary btn-back btn-l-md"
          ><i class="icon icon-back"></i>Volver</a
        >
      </div>
    </div>
  </div>

  <div class="dashboard container" *ngIf="order.files.length > 0" style="padding: 1rem;">
    <button
      mat-icon-button
      class="close-icon close-button"
      (click)="closeModal()"
    >
      <mat-icon aria-label="Cerrar">close</mat-icon>
    </button>
    <div class="header-container">
      <h6 class="text-title title">Fotos de entrega</h6>
    </div>
    <div class="images d-flex">
      <div class="img-container" *ngFor="let file of order.files">
        <img [src]="file.url" class="img-fluid" alt="" />
      </div>
    </div>
    <div class="buttons">
      <a
        href="javascript:void(0)"
        (click)="closeModal()"
        class="btn back-button"
        >Volver</a
      >
    </div>

    <div class="buttons" (click)="download()">
      <a href="javascript:void(0)" class="btn btn-primary btn-back"
        >Descargar</a
      >
    </div>
  </div>
</ng-template>
