import { Component, OnInit } from '@angular/core';
import { EnumRoles } from 'src/app/shared/Enumeradores/enumRoles.enum';

@Component({
  selector: 'app-dashboard-bottom',
  templateUrl: './dashboard-bottom.component.html'
})
export class DashboardBottomComponent implements OnInit {
  tamanoDefault = "width:100%"
  perfilView = {
    btnMenuPrincipal: EnumRoles.menuPrincipal,
  }
  IsPPEE=false;//bandera de proyectos especiales
  constructor() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.PPEE>0) {
      this.IsPPEE=true;
    }else{
      this.IsPPEE=false;
    }
   }

  ngOnInit(): void {

  }
}
