import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EncuestaService } from 'src/app/services/Encuesta/encuesta.service';
import { AccountService } from 'src/app/services/auth';

@Component({
  selector: 'app-encuesta-guia',
  templateUrl: './encuesta-guia.component.html',
  styleUrls: ['./encuesta-guia.component.css'],
})
export class EncuestaGuiaComponent implements OnInit {
  @Input() Origen_Apartado;
  @Input() Pregunta;
  @Input() ShowModalEncuesta;
  @Input() ObraFiltrada;
  @Input() NombreObraFiltrada;
  @Input() NroGuia;
  @Output() surveySubmittedSuccessEvent = new EventEmitter();
  IsVisible = false;
  IsCompleteEncuesta = false;
  IsMovil = false;

  IsProgramacion = false;

  categories = ['Despacho (entrega)', 'Atención del operador', 'Producto'];
  selectedStars: number[] = [];
  constructor(
    private deviceService: DeviceDetectorService,
    private encuestaService: EncuestaService,
    private accountService: AccountService
  ) {
    this.categories.forEach(() => this.selectedStars.push(0));
  }

  ngOnInit(): void {
    this.IsMovil = this.deviceService.isMobile();
  }

  hoveredStars: number;

  comment: string = '';

  selectStars(categoryIndex: number, stars: number): void {
    this.selectedStars[categoryIndex] = stars;
  }

  isValidData(): boolean {
    return this.selectedStars.every((stars) => stars > 0);
  }

  sendFeedback() {
    let data = {
      pregunta: this.categories,
      puntos: this.selectedStars.toString(),
      comentario: this.comment,
      apartado: this.Origen_Apartado,
      CodObra: this.ObraFiltrada == undefined ? '' : this.ObraFiltrada,
      NombreObra:
        this.NombreObraFiltrada == undefined ? '' : this.NombreObraFiltrada,
      NroGuia: this.NroGuia == undefined ? '' : this.NroGuia,
    };
    this.encuestaService.SaveEncuesta(data).subscribe((res) => {
      let result = res;
      this.surveySubmittedSuccessEvent.emit();
      setTimeout(() => {}, 2000);
    });
  }
}
