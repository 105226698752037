<div class="col-12" (click)="onOpenModal()" [ngClass]="{ hide: hide }">
  <div class="row" *ngIf="isOnlyIcon">
    <div class="col-icon-img" style="cursor: pointer;">
      <img src="assets/images/icons/paste_24px.svg" class="img-fluid" alt="" />
    </div>
  </div>
  <div *ngIf="!isOnlyIcon" style="text-align: end; margin-top: 0.5rem">
    <div>
      <label class="textHelp" style="cursor: pointer;">Ver guía</label>
    </div>
  </div>
</div>
<ng-template #contenidoModal>
  <div class="no-data" *ngIf="etapa.sinData">
    <div class="dashboard">
      <div class="mensajes mensaje-warning">
        <div class="mensajes-text">
          <strong>No se encontraron documentos asociados</strong>
        </div>
      </div>
      <div class="buttons">
        <a
          href="javascript:void(0)"
          (click)="goBack()"
          class="btn btn-primary btn-back btn-l-md"
          ><i class="icon icon-back"></i>Volver</a
        >
      </div>
    </div>
  </div>

  <div class="dashboard custom-dashboard" *ngIf="etapa.MostrarData" style="padding: 1rem; padding-top: 0px">
    <div class="header-container">
      <h6 class="text-title"></h6>
      <button
        mat-icon-button
        class="close-icon margin-cerrar"
        (click)="onCierrenModal()"
      >
        <mat-icon aria-label="Cerrar">close</mat-icon>
      </button>
    </div>
    <div class="facturas text-size-sm d-flex justify-content-center">
      <div class="ancho-pdf">
        <div class="products contenido-impresion">
          <div class="card-box-body border-top-1 pt-4 pb-2 border-bottom-0">
            <div class="text-center">
              <pdf-viewer
                [src]="rutaPdf"
                [zoom-scale]="'page-width'"
                [original-size]="false"
                [render-text]="true"
              ></pdf-viewer>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 d-md-flex justify-content-end">
      <div class="buttons mr-md-3">
        <a href="javascript:void(0)" (click)="goBack()" class="btn btn-volver"
          >Volver</a
        >
      </div>

      <div class="buttons">
        <a
          href="javascript:void(0)"
          (click)="eventoDescarPdf()"
          class="btn btn-primary btn-back btn-l-md"
          >Descargar</a
        >
      </div>
    </div>
  </div>
</ng-template>
