import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-historial-programacion-item-guias',
  templateUrl: './historial-programacion-item-guias.component.html',
  styleUrls: ['./historial-programacion-item-guias.component.css'],
})
export class HistorialProgramacionItemGuiasComponent implements OnInit {
  @Input() Guias: any;
  constructor() {}

  ngOnInit(): void {
    console.log(this.Guias);
  }

  abrirEnNuevaPestana(url: string): void {
    window.open(url, '_blank');
  }

  isUnsigned(item): boolean {
    return (
      (item.MX_CLIENTE_FIRMA_H == 0 || item.MX_CLIENTE_FIRMA_H == '') &&
      item.MX_CLI_FIRMA == '' &&
      item.ES_GUIA_DIGITAL == '1' && item.MX_MOTIVO_RECHAZO == ''
    );
  }
}
