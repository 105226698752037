import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { HistorialPedidosService } from 'src/app/services/historial-pedidos/historial-pedidos.service';
import { MenuService } from 'src/app/services/menu/menu-service';
import { Constantes } from 'src/app/shared/Constantes/constantes';
import { Utilidades } from 'src/app/shared/FuntionsGlobales/Utilidades';
import { DataHistorialPedidosModel } from 'src/app/shared/models/HistorialPedidosModel';

@Component({
  selector: 'app-historial-programacion-detalle',
  templateUrl: './historial-programacion-detalle.component.html',
})
export class HistorialProgramacionDetalleComponent implements OnInit {
  public id: string = '';
  public productItem: any = {};
  public foundItem: boolean = false;

  showButtons: boolean = true;
  classModalEliminarPedido = Constantes.classCierreModal;
  classModalEditarPedido = Constantes.classCierreModal;
  finishedProcess: boolean = false;
  finishedEditProcess: boolean = false;
  successfulProcess: boolean = false;
  itemSelected: any;
  objSelected: any;
  user:any;
  constructor(
    private pedido: HistorialPedidosService,
    private route: ActivatedRoute,
    private menu: MenuService,
    private router: Router
  ) {
    menu.btnPrevius = true;
    menu.paginaPrevius = '/historialprogramacion';
    menu.tilteMenu = 'Detalle de programación';

    this.user = JSON.parse(localStorage.getItem('user'));
    debugger;
    if (this.user.PPEE>0) {//proyectos especiales
      this.showButtons=false;
    }else{
      this.showButtons=true;
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params?.id;
    });

    if (!this.id) {
      this.foundItem = false;
      return;
    }
    this.pedido.getHistorialPedidos().subscribe((pedido) => {
      if (pedido?.unknownError) {
        this.foundItem = false;
        return;
      }
      let detallePedido = pedido.datos?.find(
        (pedido) => pedido.ID_WEB === this.id
      );

      //validar si esta vacio
      if (detallePedido) {
        detallePedido.FECHA_SOL = Utilidades.getFormattedDate(
          detallePedido.FECHA_SOL
        );
        detallePedido.ESPECIFICAC = detallePedido.ESPECIFICAC
          ? detallePedido.ESPECIFICAC
          : '--';
        this.productItem = detallePedido;
        this.foundItem = true;
      } else {
        this.foundItem = false;
      }

      this.ruleHours(detallePedido);
    });
  }

  getFormattedDate(fechaSolPedido) {
    return Utilidades.getFormattedDate(fechaSolPedido);
  }

  private anularPedido() {
    this.productItem.CANT = 0;
    this.pedido.anularPedido(this.productItem).subscribe(
      (pedidoResult) => {
        this.finishedProcess = true;
        let anyError =
          pedidoResult.id_error ||
          pedidoResult.unknownError ||
          !pedidoResult.id;
        this.successfulProcess = anyError ? false : true;
      },
      (error) => {
        this.finishedProcess = true;
        this.successfulProcess = false;
      }
    );
  }

  editarPedido(obj) {
    this.pedido.updatePedido(obj).subscribe(
      (pedidoResult) => {
        this.finishedProcess = true;
        let anyError =
          pedidoResult.id_error ||
          pedidoResult.unknownError ||
          !pedidoResult.id;
        this.successfulProcess = anyError ? false : true;
      },
      (error) => {
        this.finishedProcess = true;
        this.successfulProcess = false;
      }
    );
  }

  enumEstados = {
    Pendiente: '1',
    Programado: '2',
    Rechazado: '3',
    Eliminado: '4',
  };

  getEstado(pedido) {
    switch (pedido) {
      case this.enumEstados.Pendiente:
        return 'Pendiente';
      case this.enumEstados.Programado:
        return 'Programado';
      case this.enumEstados.Rechazado:
        return 'Rechazado';
      case this.enumEstados.Eliminado:
        return 'Eliminado';

      default:
        return '-';
    }
  }

  getAvailabilityColor(pedido) {
    switch (pedido) {
      case this.enumEstados.Pendiente:
        return 'text-color-warning-primary';

      case this.enumEstados.Rechazado:
        return 'text-color-red-primary';

      case this.enumEstados.Eliminado:
        return 'text-color-light-blue-800';

      case this.enumEstados.Programado:
      default:
        return '';
    }
  }

  deletePedido() {
    this.eliminarPedidoResumen();
  }

  editPedido(form) {
    this.editarPedido(form);
  }

  closeModalEliminarPedido() {
    this.classModalEliminarPedido = Constantes.classCierreModal;
    if (this.finishedProcess) this.ngOnInit();
  }

  openModalEliminarPedido() {
    this.classModalEliminarPedido = Constantes.classAperturaModal;
  }

  //abre modal
  openModalEditarPedido(productItem: any) {
    debugger
    this.itemSelected = productItem.CANT;
    this.objSelected = productItem;
    this.classModalEditarPedido = Constantes.classAperturaModal;
  }
  //cierra modal
  closeModalEditarPedido() {
    this.classModalEditarPedido = Constantes.classCierreModal;
    if (this.finishedEditProcess) this.ngOnInit();
  }

  eliminarPedidoResumen() {
    //TODO: llamado a servicio de
    this.anularPedido();

    // esperar respuesta para mostrar ok or no-ok
  }

  convertTimeToAMPM(hora) {
    return Utilidades.getTimeAMPM(hora);
  }

  backHistorialList() {
    this.router.navigate(['/historialprogramacion/']);
  }

  //regla para editar una programación
  ruleHours(productItem: DataHistorialPedidosModel) {
    let fechaActual = moment(new Date()).format('YYYY-MM-DD HH:mm');
    const fechaSolicitud = moment(productItem.FECHA_SOL, 'DD/MM/YYYY').format(
      'YYYY-MM-DD HH:mm:ss'
    );
    let fechaTope = moment(fechaSolicitud).subtract(9, 'hours').toString();

    const fechaTopesanitize = new Date(fechaTope);
    const fechaActualsanitize = new Date(fechaActual);
    if (productItem.CONFIR == '1') {
      this.showButtons = false;
    } else {
      if (
        fechaActualsanitize < fechaTopesanitize &&
        (productItem.ESTADO == '1' || productItem.ESTADO == '2')
      ) {
        this.showButtons = true;

        if (this.user.PPEE>0) {//proyectos especiales
          this.showButtons=false;
        }else{
          this.showButtons=true;
        }

      } else {
        this.showButtons = false;
      }
    }

   
  }

  getServicio(id):string{
      console.log(id);
      
      let Service="";
      if (id=="" || id == undefined) {
        return Service;
      }
      let ServiciosContratados=id.split(',');

      for (let i = 0; i < ServiciosContratados.length; i++) {
        switch (i) {
            case 0:
              Service +="--> "+"Muestras: " + ServiciosContratados[i]   ;
            break;
            case 1:
              Service +=" -->"+"Bombeo: " + ServiciosContratados[i]  ;
            break;
            case 2:
              Service +="--> "+ "Aditivos: " + ServiciosContratados[i] ;
            break;
        
          default:
            break;
        }
        
      }
      return Service;
  }
}
