<div [class]="'call-to-action ' + classModal">
    <div class="content lightbox-content-show">
        <div class="form-remove-file">
            <div class="content-header">
                <div class="icon-hide close-call icon-close" style="cursor:pointer;" (click)="cerrarModal()">
                    Cerrar
                </div>
            </div>
            <ng-container *ngIf="!finishedProcess">
                <h2 class="text-center">Eliminar programación</h2>
                <p class="text-center">Si optas por eliminar la programación perderás todos los datos guardados.</p>
                <div class="buttons">
                    <button (click)="quitarPedido()" class="btn btn-secondary btn-remove-file">Eliminar</button>
                </div>
            </ng-container>
            <ng-container *ngIf="finishedProcess && successfulProcess">
                <div class="response">
                    <h2 class="text-center">Programación eliminada</h2>
                    <p class="text-center">La programación fue eliminada con éxito.</p>

                    <div class="number-ticket">
                        <i class="icon-form-ok"></i>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="finishedProcess && !successfulProcess">
                <div class="response">
                    <h2 class="text-center">Lo sentimos, tu solicitud no pudo ser enviada</h2>
                    <p class="text-center">Por favor intenta enviar tu programación nuevamente más tarde.</p>

                    <div class="number-ticket">
                        <i class="icon-form-error"></i>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>