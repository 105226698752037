import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataHistorialPedidosModel } from 'src/app/shared/models/HistorialPedidosModel';

@Component({
  selector: 'app-historial-programacion-item-editar',
  templateUrl: './historial-programacion-item-editar.component.html',
  styleUrls: ['./historial-programacion-item-editar.component.css']
})
export class HistorialProgramacionItemEditarComponent implements OnInit {
  DetalleForm: FormGroup;
  @Input() ItemHistoria = new DataHistorialPedidosModel();
  @Output() editarPedidoEmitter = new EventEmitter<void>();
  @Output() CerrarModalEmitter = new EventEmitter<void>();

  titleColor: string = 'white';
  backgroundColor: string = 'blue';
  minCantidad = 1;
  maxCantidad = 10000;

  IsCantidadPermitidad=false;
  constructor(private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.minCantidad=parseFloat(this.ItemHistoria.CANT)-3;
    this.maxCantidad=parseFloat(this.ItemHistoria.CANT)+3;

    this.DetalleForm = this.formBuilder.group({
      fecha: [this.ItemHistoria.FECHA_SOL],
      hora: [this.ItemHistoria.HORA_SOL],
      obra: [this.ItemHistoria.OBRA],
      producto: [this.ItemHistoria.PRODUCTO],
      cantidad: [this.ItemHistoria.CANT, [Validators.required, Validators.min(this.minCantidad), Validators.max(this.maxCantidad), Validators.required]],
      tamano: [this.ItemHistoria.T_CARGA],
      frecuencia: [this.ItemHistoria.FRECUENCIA],
      muestra: [this.ItemHistoria.MUESTRA],
      especificacion: [this.ItemHistoria.ESPECIFICAC]
    });


  }

  get f() { return this.DetalleForm.controls; }

  CerrarModal() {
    this.CerrarModalEmitter.emit();
  }

  editarPedido() {
    debugger
    let newCant=parseFloat(this.f.cantidad.value);

    

    if (newCant> this.minCantidad  && newCant <= this.maxCantidad) {
      this.editarPedidoEmitter.emit(this.createObject());
    } 

  }

  createObject(): any {
    this.ItemHistoria.CANT = this.f.cantidad.value;
    this.ItemHistoria.HORA_SOL = this.f.hora.value;
    return this.ItemHistoria;
  }


  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode === 44 || charCode === 58) {
      return true;
    } else {
      event.preventDefault(); 
      return false;
    }
  }

  validarCantidad(){
    if (!this.f.valid) {
      this.DetalleForm.controls['cantidad'].updateValueAndValidity();
    } 
  }


}
