import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataHistorialPedidosModel, HistorialPedidosModel, RequestAnulacionPedido } from 'src/app/shared/models/HistorialPedidosModel';
import { environment } from 'src/environments/environment';

@Injectable()
export class HistorialPedidosService {

  private endpointHistorial: string;
  private endpointAnulacion: string;

  constructor(
    private http: HttpClient,) {
    this.endpointHistorial = environment.ResourcePolpaico + environment.EPPedidosH;
    this.endpointAnulacion = environment.ResourcePolpaico + environment.EPPedidosHSet;
  }

  getHistorialPedidos(): Observable<HistorialPedidosModel> {
    return this.http.get<any>(this.endpointHistorial)
      .pipe(catchError(error => of({ 'unknownError': error })));
  }

  anularPedido(formulario: DataHistorialPedidosModel): Observable<ResponseNuevoPedido> {

    let formattedFields: RequestAnulacionPedido = {
      cod_obra: formulario.COD_OBRA,
      confir: formulario.CONFIR,
      obra: formulario.OBRA,
      cod_cli: formulario.COD_CLI,
      rut_empresa: formulario.RUT_EMPRESA,
      nombre_empresa: formulario.NOMBRE_EMPRESA,
      nombre_recepciona: formulario.NOMBRE_RECEPCIONA,
      fono_recepciona: formulario.FONO_RECEPCIONA,
      email_recepciona: formulario.EMAIL_RECEPCIONA,
      nombre_recepciona2: formulario.NOMBRE_RECEPCIONA2,
      fono_recepciona2: formulario.FONO_RECEPCIONA2,
      email_recepciona2: formulario.EMAIL_RECEPCIONA2,
      fecha_sol: formulario.FECHA_SOL,
      hora_sol: formulario.HORA_SOL,
      cant: '0',
      prod_cod: formulario.PRODUCTO,
      producto: formulario.PRODUCTO,
      muestra: formulario.MUESTRA,
      t_carga: formulario.T_CARGA,
      m_descarga: formulario.M_DESCARGA,
      frecuencia: formulario.FRECUENCIA,
      especificac: formulario.ESPECIFICAC,
      servicio: formulario.SERVICIO,
      ID_WEB: formulario.ID_WEB,
      cod_anul:formulario.COD_ANUL
    }

    let headersData: {} = { ...formattedFields };

    return this.http.get<ResponseNuevoPedido>(this.endpointAnulacion, { headers: headersData })
      .pipe(catchError(error => of({ 'unknownError': error })));
  }

  updatePedido(formulario: DataHistorialPedidosModel): Observable<ResponseNuevoPedido> {
     let formattedFields: RequestAnulacionPedido = {
      cod_obra: formulario.COD_OBRA,
      confir: formulario.CONFIR,
      obra: formulario.OBRA,
      cod_cli: formulario.COD_CLI,
      rut_empresa: formulario.RUT_EMPRESA,
      nombre_empresa: formulario.NOMBRE_EMPRESA,
      nombre_recepciona: formulario.NOMBRE_RECEPCIONA,
      fono_recepciona: formulario.FONO_RECEPCIONA,
      email_recepciona: formulario.EMAIL_RECEPCIONA,
      nombre_recepciona2: formulario.NOMBRE_RECEPCIONA2,
      fono_recepciona2: formulario.FONO_RECEPCIONA2,
      email_recepciona2: formulario.EMAIL_RECEPCIONA2,
      fecha_sol: formulario.FECHA_SOL,
      hora_sol: formulario.HORA_SOL,
      cant: formulario.CANT,
      prod_cod: formulario.PRODUCTO,
      producto: formulario.PRODUCTO,
      muestra: formulario.MUESTRA,
      t_carga: formulario.T_CARGA,
      m_descarga: formulario.M_DESCARGA,
      frecuencia: formulario.FRECUENCIA,
      especificac: formulario.ESPECIFICAC,
      servicio: formulario.SERVICIO,
      ID_WEB: formulario.ID_WEB,
      cod_anul:formulario.COD_ANUL
    }

    let headersData: {} = { ...formattedFields };

    return this.http.get<ResponseNuevoPedido>(this.endpointAnulacion, { headers: headersData })
      .pipe(catchError(error => of({ 'unknownError': error })));
  }
}


export class ResponseNuevoPedido {
  public Estado?: string;
  public id?: string;
  public id_error?: string;
  public unknownError?: string;
}
