import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ExcelService } from '../../services/export/excel.service';

@Component({
  selector: 'app-modal-descargar',
  templateUrl: './modal-descargar.component.html',
  styleUrls:["./modal-descargar.component.css"]
})
export class ModalDescargarComponent implements OnInit {
  ServicioExcel= new ExcelService();
  @Input() data;
  @Input() nameFile;
  @Input() classModal;
  @Input() mensaje="";
  @Output() enventoCerrarModalPadre =new EventEmitter<boolean>();
  etapa={
    etapaDescargar:true,
    etapaOk:false
  }
  constructor() { }

  ngOnInit(): void {
  }
  eventoCerrarModal()
  {
    
    this.enventoCerrarModalPadre.emit(true);
    this.etapa.etapaDescargar=true;
    this.etapa.etapaOk=false;
  }
  enventoExportarDataExcel(){
    this.ServicioExcel.exportAsExcelFile(this.data, this.nameFile);
    this.etapa.etapaDescargar=false;
    this.etapa.etapaOk=true;
  }
}
