import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { set } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AccountService } from 'src/app/services/auth';
import { MenuService } from 'src/app/services/menu/menu-service';
import { EnumRoles } from 'src/app/shared/Enumeradores/enumRoles.enum';
import { ObraModel } from 'src/app/shared/models/UserLoginModel.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  products = new FormControl();
  obras: ObraModel[];

  obrasArray: ObraModel[];
  @ViewChild('InputSearch', { static: true }) nameSearchElement: ElementRef;

  loaded: boolean = false;
  codigoObraSelecionado: string = '';
  perfilView = {
    itemProgramacion: EnumRoles.historialprogramacion,
    ItemPedidosPorDespachar: EnumRoles.PedidosPorDespachar,
    itemHistorialMixer: EnumRoles.HistorialMixer,
  };
  IsMovil = false;
  @ViewChild('modalAdvertising') modalAdvertising!: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;

  showModal = true;
  constructor(
    private accountService: AccountService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    public menuService: MenuService,
    nameSearchElement: ElementRef,
    private dialog: MatDialog
  ) {
    menuService.btnPrevius = true;
    menuService.tilteMenu =
        'Hola ' +
        this.formatName(
          this.accountService.userValue.NOMBRE_USUARIO.split(' ')[0] +
            ' ' +
            this.accountService.userValue.NOMBRE_USUARIO.split(' ')[1]
        );

    this.showModal=  menuService.showModalFirstTime ;
    this.nameSearchElement = nameSearchElement;
    this.menuService.showModalFirstTime = false;
  }

  formatName(name: string): string {
    const words = name.toLowerCase().split(' ');
    const formattedName = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return formattedName.join(' ');
  }

  ngOnInit(): void {
    this.IsMovil = this.deviceService.isMobile();
    this.loaded = true;
  }

  

  logout() {
    this.menuService.showModalFirstTime = true;
    this.accountService.logout();
  }


  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

 

  updateSelectWork() {
    this.menuService.productoSelecionado = this.obrasArray.find(
      (x) => x.OB_CODIGO == this.menuService.codigoproductoSelecionado
    ).OB_NOMBRE;
  }
}
