import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AlertService } from 'src/app/services/auth';
import { GeolocationService } from 'src/app/services/geolocation/geolocation.service';
import { MenuService } from 'src/app/services/menu/menu-service';
import { OrderService } from 'src/app/services/pedidos/pedidos.service';
import { Order } from 'src/app/shared/models/orders';

@Component({
  selector: 'app-pedido-pendiente-detalle',
  templateUrl: './pedido-pendiente-detalle.component.html',
  styleUrls: ['./pedido-pendiente-detalle.component.css'],
})
export class PedidoPendienteDetalleComponent implements OnInit {
  steps = [0, 1, 2, 3]; // 4 pasos en total
  isLinear = false;
  center = { lat: 51.505, lng: -0.09 };
  zoom = 12;
  isAccordionOpen = false;
  statuses = [
    'Cargando',
    'En Ruta',
    'En  Obra',
    'Inicio Descarga',
    'Fin Descarga',
  ];
  currentStatusIndex = -1; // -1 significa que no se ha seleccionado ningún estado
  clickedPoint: string = '';
  clickedLocation: google.maps.LatLngLiteral | null = null;
  Paso1 = true; //detalle pedido
  Paso2 = false; //observacion
  Paso3 = false; //qr
  Paso4 = false; //firma
  Paso5 = false; //Rechazo camion

  isArido=false;
  isHormiCemen=false;
  isMobile: boolean;

  dialogRef: MatDialogRef<any>;
  @ViewChild('contenidoModal') contenidoModal!: TemplateRef<any>;
  orderLoaded: Promise<boolean>;
  order: Order;
  safeUrl: any;
  lat: number;
  lng: number;
  showAllComments = false;
  subscription: any;
  getMessagesOnInterval: any;

  pasoParamt="";

  constructor(
    private menu: MenuService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private orderService: OrderService,
    private geolocationService: GeolocationService,
    private deviceService: DeviceDetectorService
  ) {
    this.menu.tilteMenu = 'Pedidos por despachar';
    this.isMobile=this.deviceService.isMobile();
  }

  toggleAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
  }

 

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {

      this.pasoParamt=params.paso;
      this.orderService.getOrder(params.tkt).subscribe((data) => {
        this.order = data[0];
        this.order.operatorComments = data[0].operatorComments;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.order.urlMap
        );
        const status = Math.max(this.order.status, this.order.manualStatus);
        this.currentStatusIndex = this.orderStatusToIndex(status);
        this.orderLoaded = Promise.resolve(true);
        this.isArido=this.order.neg=="3400"? true: false;

        if (!this.isArido ) {

          if (!this.isMobile) {
            this.isAccordionOpen=true;
          }
          this.ContinuarPaso2()
        }else{
          if (this.pasoParamt=="3") {
            this.regenerateQr();
          }
        }

      });
    });
    this.subscription = this.menu.return.asObservable().subscribe((value) => {
      if (value) this.goBack();
    });


    
  }

  regenerateQr(){
    this.Paso3=true;
    this.Paso2=false;
    this.Paso1=false;

  }

  getOrder(tkt: string, onlyComments: boolean = false) {
    this.orderService.getOrder(tkt).subscribe((data) => {
      if (onlyComments) {
        const status = Math.max(data[0].status, data[0].manualStatus);
        this.currentStatusIndex = this.orderStatusToIndex(status);
        this.order.operatorComments = data[0].operatorComments;
        return;
      }

      this.order = data[0];

      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.order.urlMap
      );
      const status = Math.max(this.order.status, this.order.manualStatus);
      this.currentStatusIndex = this.orderStatusToIndex(status);
      this.orderLoaded = Promise.resolve(true);

    });
  }

  goBack() {
    switch (this.getCurrentStep()) {
      case 0:
        window.history.back();
        break;
      case 1:

        if (!this.isArido) {
          window.history.back();
        }else{
          this.Paso1 = true;
          this.Paso2 = false;
        }
        
        break;
      case 2:
        window.history.back();
        break;
      case 3:
        this.Paso3 = true;
        this.Paso4 = false;
        break;
      case 4:
        this.Paso1 = true;
        this.Paso5 = false;
        if (!this.isArido ) {

          if (!this.isMobile) {
            this.isAccordionOpen=true;
          }
          this.ContinuarPaso2()
        }
        break;
    }
  }

  getCurrentStep() {
    if (this.Paso1) return 0;
    if (this.Paso2) return 1;
    if (this.Paso3) return 2;
    if (this.Paso4) return 3;
    if (this.Paso5) return 4;
  }

  orderStatusToIndex(status: number): number {
    switch (status) {
      case 0:
        return -1;
      case 1:
        return -1;
      case 2:
        return 0;
      case 3:
        return 1;
      case 4:
        return 2;
      case 5:
        return 3;
      default:
        return 4;
    }
  }

  indexToOrderStatus(index: number): number {
    switch (index) {
      case 0:
        return 2;
      case 1:
        return 3;
      case 2:
        return 4;
      case 3:
        return 5;
      case 4:
        return 6;
      default:
        return 0;
    }
  }

  setStatus(index: number): void {
    if (index == this.currentStatusIndex + 1) {
      this.currentStatusIndex = index;
      this.order.status = this.indexToOrderStatus(index);
      this.orderService
        .updateStatus(
          this.order.tkt.toString(),
          this.order.orderNumber.toString(),
          this.order.status
        )
        .subscribe(
          (data) => {
            console.log('Se actualizó el estado correctamente.');
          },
          (error) => {
            console.error('Error al actualizar el estado.');
          }
        );
    }
  }

  onMapClick(event: google.maps.MouseEvent) {
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();
    this.lat = lat;
    this.lng = lng;

    if (lat !== undefined && lng !== undefined) {
      this.getPlaceAddress(lat, lng);
      this.clickedLocation = { lat, lng };
    }
  }

  getPlaceAddress(lat: number, lng: number) {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        this.clickedPoint = results[0].formatted_address;
        // Puedes utilizar results[0].geometry.location para obtener más información sobre la ubicación
      } else {
        console.error('Error al obtener la dirección.');
      }
    });
  }



  ContinuarPaso2() {
    this.Paso1 = false;
    this.Paso2 = true;

    if (this.pasoParamt=="3") {
      this.regenerateQr();
    }
  }

  toStep3() {
    this.Paso2 = false;
    this.Paso3 = true;
  }

  toStep4() {
    this.Paso3 = false;
    this.Paso4 = true;
  }

  toRejection() {
    this.Paso1 = false;
    this.Paso2=false;
    this.isAccordionOpen=false;
    this.Paso5 = true;
  }

  backToStep1(event: any) {
    this.Paso1 = true;
    this.Paso2 = false;
    this.Paso3 = false;
    this.Paso4 = false;
    this.Paso5 = false;

    if (!this.isArido ) {

      if (!this.isMobile) {
        this.isAccordionOpen=true;
      }
      this.ContinuarPaso2()
    }
  }

  onOpenModal() {
    this.dialogRef = this.dialog.open(this.contenidoModal, {
      maxWidth: '90%', // Puedes ajustar el ancho según tus necesidades
    });
  }

  onCierrenModal() {
    this.dialogRef.close();
  }

  guardarObservacion() {
    this.onOpenModal();
  }

  confirmarObservacion() {
    this.alertService.success('Guía de despacho enviada al cliente.');
  }

  toHourAndMinutes(time: string) {
    const timeArray = time.split(' ');
    const hour = timeArray[1];
    const hourArray = hour.split(':');
    const minutes = hourArray[1];
    return `${hourArray[0]}:${minutes}`;
  }

  getDate(): string {
    const now = new Date();

    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // getMonth() devuelve un índice basado en 0
    const year = now.getFullYear();

    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }


  getGeolocation() {
    this.geolocationService.getCurrentPosition().subscribe((position) => {
      if (position === null) return;
      if (position.coords)
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
    });
  }

  isSelectLocation() {
    return this.clickedLocation !== null;
  }




  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.getMessagesOnInterval = clearInterval(this.getMessagesOnInterval);
  }
}
