<div class="container smaller-container px-0">
  <form>
    <div class="form-group">
      <div class="box">
        <div class="content">
          <div class="text-color-label-title d-flex align-items-center">
            <label class="label-title-guia">Comentarios guía</label>
            <div class="icon-container ml-auto">
              <a (click)="toggleAccordion()">
                <mat-icon *ngIf="isAccordionOpen">expand_less</mat-icon>
                <mat-icon *ngIf="!isAccordionOpen">expand_more</mat-icon>
              </a>
            </div>
          </div>
          <div class="Group_Observacion_Mixer row mt-3" *ngIf="isAccordionOpen">
            <div class="col-6">
              <input
                class="form-input mat-input form-control"
                [placeholder]="placeholderArrivalTime"
                [(ngModel)]="pedido.MX_HORA_CUATRO_CLI"
                [type]="'text'"
                name="LlegadaObra"
              />
              <label class="mat-label" for="Obs">Llegada obra</label>
              <div class="toggle-editar"></div>
            </div>
            <div class="col-6">
              <input
                class="form-input mat-input form-control"
                [type]="'text'"
                [placeholder]="placeholderStartUnloadingTime"
                [(ngModel)]="pedido.MX_HORA_CINCO_CLI"
                name="InicioDescarga"
              />
              <label class="mat-label" for="Obs">Inicio descarga</label>
              <div class="toggle-editar"></div>
            </div>
            <div class="col-12"><br /></div>
            <div class="col-sm-6 col-xs-12">
              <input
                class="form-input mat-input form-control"
                [type]="'text'"
                [placeholder]="placeholderEndUnloadingTime"
                [(ngModel)]="pedido.MX_HORA_SEIS_CLI"
                name="FinDescarga"
              />
              <label class="mat-label" for="Obs">Fin descarga</label>
              <div class="toggle-editar"></div>
            </div>
            <div class="col-12"><br /></div>
            <div class="col-12 check-adicion">
              <input
                id="switch-1"
                type="checkbox"
                [(ngModel)]="pedido.MX_ADICIONAL_UNO_CLI"
                name="Adicion"
              />&nbsp; Adición de agua
              <input
                id="switch-2"
                type="checkbox"
                [(ngModel)]="pedido.MX_ADICIONAL_DOS_CLI"
                name="OtraAdicion"
              />&nbsp; Otra adición
            </div>

            <div class="col-12"><br /></div>

            <div class="col-sm-6 col-xs-12">
              <input
                id="Retorno"
                class="form-input mat-input form-control"
                [type]="'text'"
                name="Retorno"
                [placeholder]="pedido.MX_M3_RETORNO"
                [(ngModel)]="pedido.MX_M3_RETORNO_CLI"
              />
              <label class="mat-label" for="Retorno">Retorno</label>
              <div class="toggle-editar"></div>
            </div>
            <div class="d-sm-none d-block col-12">
              <br />
            </div>
            <div class="col-sm-6 col-xs-12">
              <select
                class="form-input mat-input form-control col-12"
                [(ngModel)]="pedido.MX_MOTIVO_RETORNO_CLI"
                name="MotivoRetorno"
                id="MotivoRetorno"
              >
                <option value="" disabled selected>
                  Selecciona una de las opciones
                </option>
                <option
                  [value]="returnReason.id"
                  *ngFor="let returnReason of returnReasons"
                >
                  {{ returnReason.description }}
                </option>
              </select>
              <label class="mat-label" for="MotivoRetorno"
                >Motivo Retorno</label            
              >
            </div>

            <div class="col-12"><br /></div>
            <div class="col-12">
              <input
                class="form-input mat-input form-control"
                [type]="'text'"
                name="Obs"
                [placeholder]="pedido.MX_ADICIONAL_TRES_H"
                [(ngModel)]="pedido.MX_ADICIONAL_TRES_CLI"
              />
              <label class="mat-label" for="Obs">Observaciones guía</label>
              <div class="toggle-editar"></div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-12">
          <label class="text-color-label-title">Comentarios Receptor</label>
        </div>
        <div class="col-12"><br /></div>
        <div class="col-12">
          <input
            class="form-input mat-input form-control"
            [type]="'text'"
            name="ComentariosHormi"
            [(ngModel)]="pedido.MX_COMENTARIOS_HORMI"
          />
          <label class="mat-label" for="Obs">{{isHormigon ? 'Hormigón' : 'Observación' }}</label>
          <div class="toggle-editar"></div>
        </div>
        <div class="col-12"><br /></div>
        <div class="col-sm-6 col-xs-12" *ngIf="isHormigon">
          <input
            class="form-input mat-input form-control"
            [type]="'text'"
            name="struct"
            [(ngModel)]="pedido.MX_COMENTARIOS_ESTRUCT"
          />
          <label class="mat-label" for="Obs">Tipo de estructura</label>
          <div class="toggle-editar"></div>
        </div>
        <div class="d-sm-none d-block col-12">
          <br />
        </div>
        <div class="col-sm-6 col-xs-12" *ngIf="isHormigon">
          <input
            class="form-input mat-input form-control"
            [type]="'text'"
            name="Ciclos"
            [(ngModel)]="pedido.MX_COMENTARIOS_CICLOS"
          />
          <label class="mat-label" for="Obs">Ciclos</label>
          <div class="toggle-editar"></div>
        </div>
      </div>
    </div>
    <div class="OptionButtons d-flex justify-content-end col-xs-12">
      <button
        type="button"
        class="col-xs-12 btn btn-primary-volver"
        (click)="Volver()"
      >
        Volver
      </button>
      <button
        type="button"
        class="col-xs-12 btn btn-primary"
        (click)="onOpenModalEditar()"
      >
        Enviar
      </button>
    </div>
  </form>
</div>
<br />

<ng-template #contenidoModal>
  <div>
    <div class="header-container-modal">
      <label></label>
      <button mat-icon-button class="close-icon" (click)="onCierrenModal()">
        <mat-icon aria-label="Cerrar">close</mat-icon>
      </button>
    </div>

    <div style="text-align: center">
      <img
        src="assets/images/icons/warningmodal.svg"
        class="img-fluid style-warning"
        alt=""
      />
      <h6 class="text-title-modal">Retorno de hormigón</h6>
      <p class="text-descripcion-modal">
        Registramos una devolución de 3 m3 de volumen de hormigón. <br />
        Esto podría generar sobrecostos.
      </p>
    </div>
    <div class="text-center mt-3">
      <button type="button" class="btn btn-primary col-3 mx-auto">
        Confirmar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #contenidoModalEditar>
  <div class="dashboard container p-0">
    <button mat-icon-button class="close-icon close-button">
      <mat-icon aria-label="Cerrar" (click)="onCierrenModal()">close</mat-icon>
    </button>

    <div style="text-align: center">
      <img
        src="assets/images/icons/warningmodal.svg"
        class="img-fluid style-warning mt-3"
        alt=""
      />
      <h6 class="text-title-modal my-2">¿Deseas confirmar los datos?</h6>
      <p class="text-descripcion-modal">
        Una vez confirmados no será posible cambiar la información.
      </p>
    </div>
    <div class="text-center mt-3 d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-primary-volver col-xs-12 mx-2 mb-0"
        (click)="onCierrenModal()"
      >
        Seguir editando
      </button>
      <button
        type="button"
        class="btn btn-primary col-xs-12 mx-2"
        (click)="guardar()"
      >
        Confirmar
      </button>
    </div>
  </div>
</ng-template>
