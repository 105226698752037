export class CertificadosFilterModel {
  numeroFiltar: string;
  obra: string;
  fechaDesde: Date;
  fechaHasta: Date;
  status: StatusCertificado;
  hasFilter: boolean;
}

export enum StatusCertificado {
  Delivered = 'Entregado',
  Rejected = 'Rechazado',
  All = 'Todos',
  Pending = 'Pendiente',
}
