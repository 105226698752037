<div class="form-container">
    <div>
        <label class="title">Modificar pedido en estado “confirmado”</label><br>
        <label class="text-desc"> Las modificaciones de los pedidos se pueden hacer <span class="text-azul-desc">2 hrs.
                antes de la entrega en la Región Metropolitana, en otras regiones, 24 hrs. antes.</span></label>
    </div>


    <form [formGroup]="DetalleForm">
        <div class="row">
            <div class="col-xl-6">
                <div class="form-group">
                    <input type="text" class="form-input mat-input form-control" name="fecha" formControlName="fecha"
                        id="fecha" readonly>
                    <label for="fecha" class="mat-label">Fecha</label>

                </div>

                <div class="form-group">
                    <input type="text" class="form-input mat-input form-control" name="obra" formControlName="obra"
                        id="obra" readonly>
                    <label for="obra" class="mat-label">Obra</label>


                </div>
                <div class="form-group">
                    <input type="text" class="form-input mat-input form-control" name="cantidad"
                        formControlName="cantidad" (change)="validarCantidad()" (keypress)="numberOnly($event)" [ngClass]="{ 'is-invalid': f.cantidad.errors }" id="cantidad">
                    <label for="cantidad" class="mat-label">Cantidad

                        <div class="col-icon-img icon_advertencia" style="width: 12px; height: 12px;"
                            title="Solo se puede cambiar por más o menos de 3 m³. ">
                        </div>

                    </label>
                    <div class="toggle-editar"></div>
                    
                    <div *ngIf=" f.cantidad.errors" class="invalid-feedback pull-left">
                        <div *ngIf="f.cantidad.errors.required" class="pull-left">
                            El cantidad es requerido
                        </div>
                        <div *ngIf="f.cantidad.hasError('min')" class="pull-left">
                            La cantidad debe ser mayor o igual a {{ minCantidad }}.
                        </div>
                        <div *ngIf="f.cantidad.hasError('max')" class="pull-left">
                            La cantidad debe ser menor o igual a {{ maxCantidad }}.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <input id="frecuencia" class="form-input mat-input form-frecuencia form-control" [type]="'text'"
                        name="frecuencia" formControlName="frecuencia" readonly>
                    <label class="mat-label" for="frecuencia">Frecuencia</label>

                </div>
            </div>

            <div class="col-xl-6">
                <div class="form-group">
                    <input type="text" class="form-input mat-input form-control" name="hora" formControlName="hora"
                        [ngClass]="{ 'is-invalid': f.hora.errors }" id="hora" (keypress)="numberOnly($event)">
                    <label for="hora" class="mat-label">Hora</label>
                    <i class="toggle-editar"></i>
                    <div *ngIf=" f.hora.errors" class="invalid-feedback pull-left">
                        <div *ngIf="f.hora.errors.required" class="pull-left">
                            El hora es requerido
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <input id="producto" class="form-input mat-input form-producto form-control" [type]="'text'"
                        name="producto" formControlName="producto" readonly>
                    <label class="mat-label" for="producto">Producto</label>

                </div>

                <div class="form-group">
                    <input id="tamano" class="form-input mat-input form-tamano form-control" [type]="'text'"
                        name="tamano" formControlName="tamano" readonly>
                    <label class="mat-label" for="tamano">Tamano</label>

                </div>
                <div class="form-group">
                    <input type="text" class="form-input mat-input form-control" name="muestra"
                        formControlName="muestra" id="muestra" readonly>
                    <label for="muestra" class="mat-label">Muestra</label>

                </div>
            </div>
            <div class="form-group col-xl-12">
                <input type="text" class="form-input mat-input form-control" name="especificacion"
                    formControlName="especificacion" readonly id="especificacion">
                <label for="especificacion" class="mat-label">Especificacion</label>

            </div>

        </div>




        <div class="buttons-container buttons-container-edit">
            <button class="btn btn-primary-volver" id="formButton" (click)="CerrarModal()">Volver</button>
            <button class="btn btn-primary" id="formButton" type="submit" [disabled]="!DetalleForm.valid"
                (click)="editarPedido()">Aceptar</button>
        </div>
    </form>




</div>