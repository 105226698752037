import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import { Constantes } from 'src/app/shared/Constantes/constantes';

@Component({
  selector: 'app-modal-compartir',
  templateUrl: './modal-compartir.component.html',
  styleUrls: ["./modal-compartir.component.css"]
})
export class ModalCompartirComponent implements OnInit {
  private _classModal: string;
  etapa={
    etapaDescargar:true,
    etapaOk:false
  }
  @Input() set classModal(value: string) {
    this._classModal = value;
    if (value == Constantes.classAperturaModal) {
      this.crearImagen();
    }
  };
  get classModal(): string {
    return this._classModal;
  }
  @Input() mensaje="";
  @Input() mensajePregunta="";
  @Output() enventoCerrarModalPadre = new EventEmitter<void>();
  constructor() { }
  urlImagen: string;
  ngOnInit(): void {

  }

  eventoCerrarModal() {
    this.enventoCerrarModalPadre.emit();
    this.etapa.etapaDescargar=true;
    this.etapa.etapaOk=false;
  }
  crearImagen() {
    let elemento = document.querySelector(".contenido-impresion");
    if (elemento.innerHTML.length > 10) {
      html2canvas(document.querySelector(".contenido-impresion")).then(canvas => {
        this.urlImagen = canvas.toDataURL();

      });
    }
  }
  saveToClipboard() {
    var node = document.getElementById('div2');
    var selection = window.getSelection();
    var range2 = document.createRange();
    range2.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range2);
    document.execCommand('copy');
    this.etapa.etapaDescargar=false;
    this.etapa.etapaOk=true;
  }
}

