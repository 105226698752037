export class PerfilModel {
  public Key: string;
  public RUT: string;
  public NOMBRE_USUARIO: string;
  public MAIL_USUARIO: string;
  public FONO_USUARIO: string;
  public opemail1: boolean;
  public opemail2: boolean;
  public opemail3: boolean;
  public opemail4: boolean;
  public opsms: boolean;
  public Clave: string = '';
  public firma: string;
}
