import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { CambiarPasswordComponent } from './components/cambiar-password/cambiar-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardlayoutComponent } from './components/dashboardlayout/dashboardlayout.component';
import { HistorialProgramacionDetalleComponent } from './components/historial-programacion/historial-programacion-detalle/historial-programacion-detalle.component';
import { HistorialProgramacionComponent } from './components/historial-programacion/historial-programacion.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { NotificacionesComponent } from './components/notificaciones/notificaciones.component';
import { P404Component } from './components/p404/p404.component';
import { PerfilUsuarioComponent } from './components/perfil-usuario/perfil-usuario.component';
import { RecoverpasswordComponent } from './components/recoverpassword/recoverpassword.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthGuardService } from './services/auth/helpers/auth-guard.service';
import { CanDeactivateGuard } from './services/auth/helpers/can-deactivate.guard';
import { SolicitarClaveComponent } from './components/solicitar-clave/solicitar-clave.component';
import { AppComponent } from './app.component';
import { PedidoPendienteComponent } from './components/pedido-pendiente/pedido-pendiente.component';
import { PedidoEntregadosComponent } from './components/pedido-entregados/pedido-entregados.component';
import { PedidoPendienteDetalleComponent } from './components/pedido-pendiente/pedido-pendiente-detalle/pedido-pendiente-detalle.component';
import { HistorialProgramacionSeguimientoComponent } from './components/historial-programacion-seguimiento/historial-programacion-seguimiento.component';


const routes: Routes = [
  {

    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: LoginComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent, pathMatch: 'full' },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: 'recoverpassword', component: RecoverpasswordComponent },

    ]
  },
  {
    path: '',
    component: AppComponent,
    children: [
      { path: 'solicitarclave', component: SolicitarClaveComponent },
      { path: 'SeguimientoAnonimo/:paso', component: HistorialProgramacionSeguimientoComponent }
    ]
  },
  {
    path: '',
    component: DashboardlayoutComponent,
    children: [
      { path: '', component: LoginComponent, pathMatch: 'full', canActivate: [AuthGuardService] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
      { path: 'editarperfil', component: PerfilUsuarioComponent, canActivate: [AuthGuardService] },
      { path: 'cambiarpassword', component: CambiarPasswordComponent, canActivate: [AuthGuardService] },
      { path: 'notificaciones', component: NotificacionesComponent, canActivate: [AuthGuardService] },
      { path: 'historialprogramacion', component: HistorialProgramacionComponent, canActivate: [AuthGuardService] },
      { path: 'historialprogramacion/:id', component: HistorialProgramacionDetalleComponent, canActivate: [AuthGuardService] },
      { path: 'Seguimiento/:paso', component: HistorialProgramacionSeguimientoComponent, canActivate: [AuthGuardService] },
      { path: 'pedidospendiente', component: PedidoPendienteComponent, canActivate: [AuthGuardService] },
      { path: 'PedidosPendientes/Detalle', component: PedidoPendienteDetalleComponent, canActivate: [AuthGuardService] },
      { path: 'pedidosEntregados', component: PedidoEntregadosComponent, canActivate: [AuthGuardService] },
     
    ]
  },
  { path: '**', component: P404Component },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
