import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountService } from '../auth';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ViajesService {
  endpointViajes: string;
  endPointValues: string;
  endPointRunPdf: string;
  key: string;

  constructor(
    private http: HttpClient,
    PerfilUsuarioComponent: AccountService,
    private route: ActivatedRoute
  ) {
    this.endpointViajes = environment.ResourcePolpaico + environment.pedidos;
    this.endPointValues =
      environment.ResourcePolpaico + environment.setValuesCli;

    if (PerfilUsuarioComponent.userValue) {
      this.key = PerfilUsuarioComponent.userValue.KEY;
    } else{//cuanod se quiere firmar anonimamente
      this.route.params.subscribe((params) => {
        this.key =  this.route.snapshot.queryParams.key;
      });
      this.endPointValues = environment.ResourcePolpaico + environment.setValues;
      
    }
    
    this.endPointRunPdf = environment.ApiRunPdf;
  }

  GetGuiasCliente(data): Observable<any> {
    let formattedFields: any = {
      NUMERO_PEDID_H: data.NUMERO_PEDID_H,
      FECHA_PED_DATE_H: data.FECHA_PED_DATE_H,
    };

    let headersData: {} = { ...formattedFields };

    return this.http
      .get<any>(this.endpointViajes, { headers: headersData })
      .pipe(catchError((error) => of({ unknownError: error })));
  }

  GetDataOrderByQr(data): Observable<any> {
    let formattedFields: any = {
      key: data.key,
      id1: data.id1,
    };

    let headersData: {} = { ...formattedFields };

    return this.http
      .get<any>(this.endpointViajes, { headers: headersData })
      .pipe(catchError((error) => of({ unknownError: error })));
  }

  viajesGuiasCliente(data): Observable<any> {
    let formattedFields: any = {
      FOLIO_H: data.FOLIO_H,
      NUMERO_PEDID_H: data.NUMERO_PEDID_H,
      FECHA_PED_DATE_H: data.FECHA_PED_DATE_H,
    };

    let headersData: {} = { ...formattedFields };

    return this.http
      .get<any>(this.endpointViajes, { headers: headersData })
      .pipe(catchError((error) => of({ unknownError: error })));
  }

  UpdateComentariosGuiasCliente(data): Observable<any> {
    let formattedFields: any = {
      key: this.key,
      MX_TKT_H: data.TKT_H,
      MX_NUMERO_PEDID_H: data.NUMERO_PEDID_H,
      MX_COMENTARIOS_HORMI: data.MX_COMENTARIOS_HORMI,
      MX_COMENTARIOS_ESTRUCT: data.MX_COMENTARIOS_ESTRUCT,
      MX_COMENTARIOS_CICLOS: data.MX_COMENTARIOS_CICLOS,
      MX_ADICIONAL_UNO_CLI: (data.MX_ADICIONAL_UNO_CLI as boolean).toString(),
      MX_ADICIONAL_DOS_CLI: (data.MX_ADICIONAL_DOS_CLI as boolean).toString(),
      MX_HORA_CUATRO_CLI: data.MX_HORA_CUATRO_CLI,
      MX_HORA_CINCO_CLI: data.MX_HORA_CINCO_CLI,
      MX_HORA_SEIS_CLI: data.MX_HORA_SEIS_CLI,
      MX_ADICIONAL_TRES_CLI: data.MX_ADICIONAL_TRES_CLI,
      MX_MOTIVO_RETORNO_CLI: data.MX_MOTIVO_RETORNO_CLI,
      MX_M3_RETORNO_CLI: data.MX_M3_RETORNO_CLI,
    };

    let headersData: {} = { ...formattedFields };

    return this.http
      .get<any>(this.endPointValues, { headers: headersData })
      .pipe(catchError((error) => of({ unknownError: error })));
  }

  UpdateFirmaGuiasCliente(data: any): Observable<any> {
    let formattedFields: any = {
      key: this.key,
      MX_TKT_H: data.TKT_H,
      MX_NUMERO_PEDID_H: data.NUMERO_PEDID_H,
      MX_CLI_FIRMA_RUT: data.MX_CLI_FIRMA_RUT,
      MX_CLI_FIRMA_NOMBRE: data.MX_CLI_FIRMA_NOMBRE,
      MX_CLI_FIRMA: data.MX_CLI_FIRMA,
      MX_CLIENTE_FIRMA_H: '1',
    };

    let headersData: {} = { ...formattedFields };

    return this.http
      .get<any>(this.endPointValues, { headers: headersData })
      .pipe(catchError((error) => of({ unknownError: error })));
  }

  UpdateFirmaGuiasClienteWithImage(data: any): Observable<any> {
    let formattedFields: any = {
      key: this.key,
      MX_TKT_H: data.TKT_H,
      MX_NUMERO_PEDID_H: data.NUMERO_PEDID_H,
      MX_CLI_FIRMA_RUT: data.MX_CLI_FIRMA_RUT,
      MX_CLI_FIRMA_NOMBRE: data.MX_CLI_FIRMA_NOMBRE,
      MX_CLIENTE_FIRMA_H: '1',
    };
    const body = {
      MX_CLI_FIRMA: data.MX_CLI_FIRMA,
    };

    let headersData: {} = { ...formattedFields };

    return this.http
      .post<any>(this.endPointValues, body, { headers: headersData })
      .pipe(catchError((error) => of({ unknownError: error })));
  }

  RunPdfGuia(): Observable<any> {
    return this.http.get(this.endPointRunPdf, { responseType: 'text' });
  }
  
}
