<app-encuesta-satisfacion
  Origen_Apartado="RUTAS"
  [ObraFiltrada]="codigoObraSelecionada"
  [NombreObraFiltrada]="obraSelecionada"
  Pregunta="De acuerdo al último despacho, con que nota calificaría nuestro servicio de entrega"
>
</app-encuesta-satisfacion>
<div class="">
  <app-historial-programacion-filtros
    [lstObras]="lstObras"
    (eventofiltrar)="eventoFiltrar($event)"
    [isWeekFilter]="!IsDia"
    [Opcion]="true"
    (downloadAsPDF)="eventoAbrirModalDescargar()"
    [selectObrasText]="obraSelecionada"
    [ClaseModal]="classFiltro"
    [selectObras]="codigoObraSelecionada"
  >
  </app-historial-programacion-filtros>

  <div class="Despacho">
    <div class="dashboard select-dashboard">
      <label>Selecciona la manera de ver los pedidos.</label><br />
      <mat-form-field
        class="mat-select col-3 row type-select"
        appearance="#ffffff"
      >
        <mat-select
          [(ngModel)]="selectTipoFiltro"
          (selectionChange)="onChangeTipoFiltro($event)"
          style="padding-left: 10px; padding-right: 10px"
        >
          <mat-option *ngFor="let Tip of ArrayTipoFiltro" [value]="Tip.Name">
            {{ Tip.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style="margin-left: 100px; margin-top: -100px" class="information">
      <div id="pdfPage" #pdfPage>
        <div class="semana">
          <div class="header-carousel-title mb-3 header-carousel">
            <div
              class="contenedor-flex status-filters text-size-xs"
              *ngIf="!isMobile"
            >
              <div class="item">
                <div
                  style="cursor: pointer"
                  [ngClass]="{ filtros: true, active: false }"
                >
                  <span class="circulo confirmado"></span> Confirmado
                </div>
              </div>
              <div class="item">
                <div
                  style="cursor: pointer"
                  [ngClass]="{ filtros: true, active: false }"
                >
                  <span class="circulo entregado"></span> Entregado
                </div>
              </div>
              <!--end col-->
              <div class="item">
                <div
                  style="cursor: pointer"
                  [ngClass]="{ filtros: true, active: false }"
                >
                  <span class="circulo anulado"></span> Anulado
                </div>
              </div>
              <!--end col-->
              <div class="item">
                <div
                  style="cursor: pointer"
                  [ngClass]="{ filtros: true, active: false }"
                >
                  <span class="circulo sinfirmar"></span> Guía sin firmar
                </div>
              </div>
              <!--end col-->
            </div>

            <button
              style="transform: rotate(360deg)"
              (click)="prevDay()"
              class="btn-right btn-carousel"
              *ngIf="IsDia"
            ></button>
            <div
              class="month d-inline-block text-size-md mx-1 mx-sm-3 text-color-blue-primary text-dias header-carousel"
              (click)="toggleDatePicker($event)"
            >
              {{ mesInicio }} &nbsp;
              <button
                style="margin-left: auto; padding: 0px;"
                class="btn-abajo btn-carousel"
              ></button>

              <div class="row">
                <div class="col-12 altura-cheveron">
                  <div class="form-group" (click)="toggleDatePicker($event)">
                    <mat-form-field
                      appearance="fill"
                      style="
                        visibility: hidden;
                        position: absolute;
                        margin-top: -20%;
                      "
                    >
                      <input
                        matInput
                        [matDatepicker]="picker"
                        (dateChange)="onDateChange($event)"
                        id="start"
                        placeholder="Fecha"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker
                        #picker
                        [opened]="showDatePicker"
                      ></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <button
              (click)="nextDay()"
              class="btn-right btn-carousel"
              *ngIf="IsDia"
            ></button>
          </div>
        </div>

        <ngx-slick-carousel
          class="header-carousel"
          #slickModal="slick-carousel"
          [config]="slideConfig"
          *ngIf="IsDia"
        >
          <div
            id="CardItem"
            class="slide slide-item card-item"
            *ngFor="
              let itemDate of dataByDate | keyvalue : keyAscOrder;
              let i = index
            "
          >
            <div class="programacion-de-despacho content-desktop-card-item">
              <div *ngIf="itemDate.value != null">
                <div
                  app-historial-programacion-item
                  *ngFor="let item of itemDate.value"
                  [ItemHistoria]="item"
                ></div>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>

        <div class="header-items" *ngIf="IsSemana">
          <div
            id="CardItem"
            class="slide slide-item card-item"
            [ngClass]="{ 'card-item-small-version': isMobile }"
            *ngFor="
              let itemDate of dataByDate | keyvalue : keyAscOrder;
              let i = index
            "
          >
            <div class="programacion-de-despacho content-desktop-card-item">
              <div class="header-page pl-0 pr-0 header-week">
                <button
                  class="btn-left btn-carousel"
                  *ngIf="i == 0"
                  (click)="prevWeek()"
                ></button>
                <div class="week-text">
                  <strong class="text-dias">
                    {{ getFormattedDate(itemDate.key) }}
                  </strong>
                </div>
                <button
                  class="btn-right btn-carousel"
                  *ngIf="i == 5"
                  (click)="nextWeek()"
                ></button>
              </div>

              <div *ngIf="itemDate.value != null">
                <div
                  app-historial-programacion-item
                  *ngFor="let item of itemDate.value"
                  [ItemHistoria]="item"
                  [isSmallVersion]="isMobile"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <app-modal-descargar-items
          [classModal]="ClaseModalDescargar"
          (enventoCerrarModalPadre)="eventoCerrarModalDescargar()"
          (descargarPdf)="downloadAsPDF()"
        >
        </app-modal-descargar-items>

        <div
          class="mensajes mensaje-warning content-desktop-card"
          *ngIf="boolvalidarVacio"
        >
          <div class="mensajes-text">
            <strong>Tu búsqueda no ha arrojado resultados.</strong>
            <br />Modifica tus filtros para realizar una nueva búsqueda.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="Container" style="display: none">
    <div id="pdfTable" #pdfTable>
      <h2 style="font-size: 2em; text-align: center">Despachos programados</h2>
      <hr />
      <div class="dashboard row col-xl-12">
        <div
          *ngFor="let itemDate of dataByDatePDF | keyvalue : keyDescOrder"
          class="col-xl-4"
        >
          <div class="programacion-de-despacho content-desktop-card">
            <div class="header-page pl-0">
              <strong class="text-capitalize" style="font-size: 1.5em">{{
                getFormattedDateDiaCorto(itemDate.key)
              }}</strong>
            </div>

            <div *ngFor="let item of itemDate.value">
              <div
                style="
                  box-shadow: 0px 0px 8px white;
                  text-align: left;
                  padding: 1rem 0.8rem;
                  margin-bottom: 1rem;
                  border-radius: 8px;
                  border: 1px solid rgba(0, 0, 0, 0.15);
                "
              >
                <div class="numero-pedido border-0">
                  <h2 class="title" style="color: #0033a1 !important">
                    N° de pedido: {{ item.ID_CMD }}
                  </h2>
                  <h3 class="title" style="color: #0033a1 !important">
                    <strong>{{ truncarTextDemanda(item.PRODUCTO, 26) }}</strong>
                  </h3>
                  <div style="font-size: 1.5em">
                    <strong>Cant: </strong>{{ item.CANT }} M<sup>3</sup>
                  </div>
                  <div style="font-size: 1.5em">
                    <strong>Hora:</strong> {{ item.HORA_SOL }}
                  </div>
                  <div style="font-size: 1.5em">
                    <strong>Hora llegada:</strong> {{ item.LLEGAPCAM }}
                  </div>
                  <div
                    style="font-size: 1.5em"
                    *ngIf="item.FRECUENCIA.length > 0"
                  >
                    <strong>Frecuencia:</strong> {{ item.FRECUENCIA }} Minutos
                  </div>
                </div>
                <div class="estado-despacho text-right" style="cursor: pointer">
                  <div
                    [class]="
                      'mt-3 mb-0 p-1 text-size-xs d-inline-block alert-' +
                      getBarColor(item)
                    "
                  >
                    <a style="font-size: 1.5em">{{ getEstado(item) }} </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
