<div class="container box">
  <form>
    <div class="form-group row">
      <div class="col-12"><br /></div>
      <div *ngIf="isHormigon"
        class="col-12 check-adicion d-flex justify-content-sm-around justify-content-md-start"
      >
        <div>
          <input
            id="switch-1"
            type="checkbox"
            [formControl]="form.controls.waterAddition"
          />
          Adición de agua
        </div>
        <div>
          <input
            id="switch-2"
            type="checkbox"
            style="margin-left: 80px"
            [formControl]="form.controls.anotherAddition"
          />
          Otra adición
        </div>
      </div>

      <div class="col-12"><br /></div>
      <div class="col-12 mb-4">
        <input
          id="Obs"
          class="form-input mat-input form-control"
          [type]="'text'"
          name="Obs"
          value=""
          [formControl]="form.controls.guidingRemarks"
        />
        <label class="mat-label" style="left: 25px; font-weight: 700" for="Obs"
          >Observaciones guía</label
        >
      </div>
      <div class="col-12 mb-4 mb-md-0 col-md-6" *ngIf="order.neg!='3400'">
        <input
          id="Retorno"
          class="form-input mat-input form-control"
          [type]="'number'"
          name="Retorno"
          value=""
          (input)="onInputChange($event)"
          (keypress)="numberOnly($event)"
          [formControl]="form.controls.m3Returned"
        />
        
        <label
          class="mat-label"
          style="left: 25px; font-weight: 700"
          for="Retorno"
          >{{ order.neg=='3100' ? 'Devolución de cemento:' : 'Retorno:'}}</label
        >
        <div class="text-overlay" *ngIf="order.neg=='3100'" >{{order.um}}</div>
      </div>

      <div class="col-12 col-md-6">
        <select
          minlength="8"
          maxlength="15"
          class="form-input mat-input form-control col-12"
          name="MotivoRetorno"
          id="MotivoRetorno"
          placeholder="Selecciona una de las opciones"
          [formControl]="form.controls.reasonToReturn"
        >
          <option value="" disabled selected>
            Selecciona una de las opciones
          </option>
          <option
            [value]="returnReason"
            *ngFor="let returnReason of returnReasons"
          >
            {{ returnReason }}
          </option>
        </select>
        <label
          class="mat-label"
          style="left: 25px; font-weight: 700"
          for="MotivoRetorno"
          >{{ order.neg=='3100' ? 'Motivo de devolución:' : 'Motivo retorno:'}}</label
        >
      </div>

      

      <div class="col-12 mb-4 mb-md-0 col-md-6 mt-3" *ngIf="order.neg=='3100'">
        <input
          id="RetornoPallet"
          class="form-input mat-input form-control"
          [type]="'number'"
          name="RetornoPallet"
          value=""
          (input)="onInpuMajorCero($event)"
          (keypress)="numberOnly($event)"
          [formControl]="form.controls.m3ReturnedPallet"
        />
        <label
          class="mat-label"
          style="left: 25px; font-weight: 700"
          for="RetornoPallet"
          >Devolución de pallets:</label
        >
      </div>

      

      <div>
        <div class="col-icon-img col-xl-12">
          <div>
            <input
              type="file"
              #fileInput
              (change)="onFileSelected($event)"
              multiple
              accept=".jpg,.jpeg,.png"
              style="display: none"
            />
            <br />
            <div class="image-list" style="margin-left: 30px">
              <div *ngFor="let image of images" class="image-item">
                <img
                  [src]="image.preview"
                  alt="{{ image.file.name }}"
                  width="50"
                  height="50"
                />
              </div>
            </div>
          </div>
          <div class="col-12"></div>
          <div class="d-md-flex flex-md-row-reverse">
            <div class="mb-2 mb-md-0 d-flex align-items-md-center ml-md-4">
              <img
                src="assets/images/icons/Advertencia-Imagen.svg"
                class="img-fluid"
                alt=""
              />
              <div class="col-10 text-imagen-obs">
                <span
                  >Recuerda que solo puedes subir imágenes.</span
                >
              </div>
            </div>
            <div class="attach-btn">
              <button
                type="button"
                class="btn btn-primary-foto"
                (click)="openFileSelector()"
              >
                Adjuntar foto
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<br />
<div class="button-container">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="rejectTruckLocal()"
  >
    Camión rechazado
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="confirm()"
  >
    Finalizar
  </button>
</div>


<ng-template #modalContent>
  <div
    class="dashboard container"
    style="padding: 1rem; padding-top: 0px; padding-bottom: 0px"
  >
    <button mat-icon-button class="close-icon close-button">
      <mat-icon aria-label="Cerrar" (click)="closeModal()">close</mat-icon>
    </button>
    <div>
      <div class="header-container title-container">
        <mat-icon [ngStyle]="{ color: '#f1ae2f' }" style="transform: scale(1.4)"
          >warning</mat-icon
        >
        <h6 class="text-title title">¿Deseas confirmar los datos?</h6>
      </div>
      <div>
        <p style="text-align: center">
          Una vez confirmada no será posible cambiar la información.
        </p>
      </div>
      <div style="display: flex">
        <div class="buttons" style="width: 47.5%; margin-right: auto">
          <a
            href="javascript:void(0)"
            class="btn back-button"
            (click)="closeModal()"
            >Seguir editando</a
          >
        </div>

        <div class="buttons" style="width: 47.5%">
          <a
            href="javascript:void(0)"
            class="btn btn-primary btn-back btn-l-md"
            (click)="submit()"
            >Confirmar</a
          >
        </div>
      </div>
    </div>
  </div>
</ng-template>
