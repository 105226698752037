<div [class]= "'lightbox-contact lightbox-content '+ classModal">
    <div class="content lightbox-content-show">
        <div class="content-header"><div href="" class="icon-hide close-call icon-close"  style="cursor:pointer;" (click)="eventoCerrarModal()"  >Cerrar</div></div>
        <div class="content-contact">
            <h2 class="text-center">¿Deseas contactarnos?</h2>
            <p class="text-center">Si necesitas ayuda, siempre puedes conversar con nuestro equipo de Servicio al Cliente al 600 620 6200.</p>
            <div class="buttons">
                <a class="btn btn-primary" href="tel:6006206200"><i class="icon-hide icon-call"></i>Llamar</a>
            </div>
        </div>
    </div>
</div>