export class UserLoginModel {
    public user: string;
    public pass: string;
}

export class DatosModel {
    public user: Array<any>;
}

export class UserLoggedModel {


    public RUT_EMPRESA: string;
    public NOMBRE_EMPRESA: string;
    public NOMBRE_USUARIO: string;
    public RUT_USUARIO: string;
    public FONO_USUARIO: string;
    public MAIL_USUARIO: string;
    public ADM: number;
    public opemail1: boolean;
    public opemail2: boolean;
    public opemail3: boolean;
    public opemail4: boolean;
    public opsms: boolean;
    public pedidos: number;
    public KEY: string;
    public OBRAS: Array<ObraModel>;
    public OBRASNOTIFICACIONES: ListOf<ObraMotificacionesModel>;
    public CARGO:String;

    public ROL:String;
    public INTERNO:String;
    public VIAJES_M: ListOf<VIAJES_H>;
    public firma: string;

    public ESTADO: string;
    

}

export class ObraModel {
    public OB_CODIGO: string;
    public OB_NOMBRE: string;
}

export class ObraMotificacionesModel {

}

export class RecoverPasswordResultModel {
    public estado: string;
    public mail :string;
}

export class SaldoProductosModel {
    public COD_OBRA: string;
    public OBRA: string;
    public DIRECCION: string;
    public COD_CLIENTE: string;
    public PLANTA: string;
    public TIPO_PRODUCTO: string;
    public COD_PROD: string;
    public DESCRIPCION_PROD: string;
    public FECHA_VENCE: string;
    public CANT_TOTAL: string;
    public SALDO: string;
    public UM: string;
    public OC: string;
}

export class ListOf<T> {
    public collection: Array<T>;
    public length: number;
    /**
     *
     */
    constructor() {
        this.length = this.collection.length;

    }

}

export class VIAJES_H {
    TKT_H: string;
    FECHA_PED_H: string;
    PLANTA_H: string;
    NUMERO_PEDID_H: string;
    CODIGO_CLI_H: string;
    NOMBRE_CLIENTE_H: string;
    CODIGO_PROYE_H: string;
    NOMBRE_PROYECTO_H: string;
    DIRECCION_ENTREGA_H: string;
    CODIGO_PRODU_H: string;
    DESCRIPCION_PRODUCTO_H: string;
    CANTIDAD_PROGRAMADA_H: string;
    CARGAS_H: string;
    FRECUENCIA_H: string;
    IMPRESO_H: string;
    HORA_INI_H: string;
    TIEMPO_DE_VIAJE_H: string;
    CAMIONES_REQUERIDOS_H: string;
    TAMANO_CARGA_H: string;
    CANTIDAD_DESPACHADA_H: string;
    CANTIDAD_PENDIENTE_H: string;
    TELEFONO_H: string;
    CONTACTO_H: string;
    DESIGNATION_H: string;
    CONDUCTOR_H: string;
    PATENTE_H: string;
    NROCARGA_H: string;
    STATUS_H: string;
    ORDL_H: string;
    HORA_UNO_H: string;
    HORA_DOS_H: string;
    HORA_TRES_H: string;
    HORA_CUATRO_H: string;
    HORA_CINCO_H: string;
    HORA_SEIS_H: string;
    HORA_SIETE_H: string;
    HORA_OCHO_H: string;
    FOLIO_H: string;
    COD_ANUL_H: string;
    CANT_DESP_REAL_H: string;
    PDF_GUIA_H: string;
    FECHA_PED_DATE_H: string;
  }
  