import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { env } from 'process';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DatosModel,
  ObraModel,
  UserLoggedModel,
  UserLoginModel,
} from 'src/app/shared/models/UserLoginModel.model';
import { environment } from 'src/environments/environment';
import { GetDataService } from './get-data.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  @Output() fireIsLoggedIn: EventEmitter<any> = new EventEmitter<any>();
  private loggedIn = new BehaviorSubject<boolean>(false);

  private userSubject: BehaviorSubject<UserLoggedModel>;
  public user: Observable<UserLoggedModel>;
  private userPassword: string;
  endpoint: string;
  endpointRecoveryPassword: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private GetDataSrvice: GetDataService
  ) {
    this.endpoint = environment.ResourceLogin;
    this.endpointRecoveryPassword = environment.ResourcePassWordRecovery;
    this.userSubject = new BehaviorSubject<UserLoggedModel>(
      JSON.parse(localStorage.getItem('user'))
    );
    this.userPassword = localStorage.getItem('password');
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): UserLoggedModel {
    return this.userSubject.value;
  }
  public get userClave(): string {
    return this.userPassword;
  }

  loginPolpaico(username, password) {
   
    this.saveClave(password);
    return this.http
      .get<UserLoggedModel>(this.endpoint, {
        headers: { User: username, Pass: password },
      })
      .pipe(
        map((user) => {
          if (user['length'] === 0 || user['datos'] == undefined) {
            return user;
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (user['datos'].length === 0) {
            return user['datos'];
          }
          let data: any;
          let obj = {
            RUT_EMPRESA: user['datos'][0]['RUT_M'],
            NOMBRE_EMPRESA: user['datos'][0]['NOMBRE_M'],
            NOMBRE_USUARIO: user['datos'][0]['NOMBRE_M'],
            RUT_USUARIO: user['datos'][0]['RUT_M'],
            MAIL_USUARIO: user['datos'][0]['EMAIL_M'],
            ADM: '0',
            FONO_USUARIO: user['datos'][0]['CELULAR_M'],
            KEY: user['datos'][0]['KEY'],
            OBRASNOTIFICACIONES: user['datos'][0]['OBRAS'],
            CARGO: 'MIXER',
            ROL: user['datos'][0]['ROL'],
            INTERNO: user['datos'][0]['INTERNO'],
            VIAJES_M: user['datos'][0]['VIAJESH'],
            OBRAS: [],
            ESTADO: user['datos'][0]['ESTADO'],
            firma: '',
          };

          data = obj;
          this.saveDatos(data);
          this.loggedIn.next(true);
          
          this.fireIsLoggedIn.emit('loggedIn');
          return user;
        })
      );
  }
  loginConRutEmpresaPolpaico(username, password, RutEmp) {
    this.saveClave(password);
    return this.http
      .get<UserLoggedModel>(this.endpoint, {
        headers: { User: username, Pass: password, RutEmp: RutEmp },
      })
      .pipe(
        map((user) => {
          if (user['length'] === 0 || user['datos'] === undefined) {
            return user;
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (user['datos'].length === 0) {
            return user['datos'];
          }
          let data: any = user['datos'][0];
          data['OBRAS'] = this.limpiarDataObra(data['OBRAS']);
          this.saveDatos(data);
          this.fireIsLoggedIn.emit('loggedIn');
          return user;
        })
      );
  }
  saveDatos(datos: UserLoggedModel) {
    localStorage.setItem('user', JSON.stringify(datos));

    this.RecardarDataService();
    let today = moment(); //se agrega la expiracion del token
    let tomorrow = moment(today).add(3, 'days');
    localStorage.setItem('TimeExpire', JSON.stringify(tomorrow));
    this.setCookie(datos.RUT_USUARIO);

    
    this.userSubject.next(datos);
  }
  saveClave(clave: string) {
    localStorage.setItem('password', clave);
    this.userPassword = localStorage.getItem('password');
  }
  recoverPassword(rutUser, rutEmp) {
    return this.http.get<string>(this.endpointRecoveryPassword, {
      headers: { RutUser: rutUser, RutEmp: rutEmp },
    });
  }
  private limpiarDataObra(listObras) {
    let obraLimpias: ObraModel[] = listObras.reduce(
      (acc: ObraModel[], item) => {
        let element: ObraModel[] = acc.filter(
          (x) => x.OB_CODIGO == item.OB_CODIGO
        );
        if (element.length == 0) {
          acc.push(item);
        }
        return acc;
      },
      []
    );
    return obraLimpias.sort(function (a, b) {
      return a.OB_NOMBRE > b.OB_NOMBRE ? 1 : a.OB_NOMBRE < b.OB_NOMBRE ? -1 : 0;
    });
  }
  getEmitter() {
    return this.fireIsLoggedIn;
  }

  logout() {
    // remove user from local storage and set current user to null
    this.RemoveDataStore();
    localStorage.removeItem('user');
    localStorage.removeItem('TimeExpire');
    this.deleteCookie('Rut');
    this.userSubject.next(null);
    this.loggedIn.next(false); 
    this.router.navigate(['/login']);
  }

  isVigenteKey() {
    let TimeCreacion = new BehaviorSubject<moment.Moment>(
      JSON.parse(localStorage.getItem('TimeExpire'))
    );
    let today = moment();
    if (today > moment(TimeCreacion.value)) {
      return false;
    } else {
      return true;
    }
  }

  async SaveDataServices(dataUser: UserLoggedModel) {
    this.GetDataSrvice.getHistorialPedidos(dataUser.KEY).subscribe((data) => {
      let DataTemp = data['datos'];
      localStorage.setItem('HistorialPedido', JSON.stringify(DataTemp));
    });
  }

  RemoveDataStore() {
    localStorage.removeItem('HistorialPedido');
    localStorage.removeItem('password');
    localStorage.removeItem('TimeExpireSaldos');
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async RecardarDataService() {
    await this.sleep(10800000);

    this.RemoveDataStore();
    let UserStora = new BehaviorSubject<UserLoggedModel>(
      JSON.parse(localStorage.getItem('user'))
    );
    this.SaveDataServices(UserStora.value);
  }
  setCookie(rut) {
    const cookieName = 'Rut';
    const cookieValue = rut;
    const expirationDays = 3;

    const date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();

    document.cookie =
      cookieName + '=' + cookieValue + ';' + expires + ';path=/';
  }

  deleteCookie(nameCookie) {
    const expirationDate = new Date('2000-01-01');
    document.cookie =
      nameCookie + '=; expires=' + expirationDate.toUTCString() + '; path=/';
  }

  updateStatus(status: string) {
    if (this.userSubject.value) {
      let user = this.userSubject.value;
      user.ESTADO = status;
      this.userSubject.next(user);
      this.saveDatos(user);
    }
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  getChatPolpaico() {
    if (this.userSubject.value) {
      return this.http
      .get<UserLoggedModel>(this.endpoint, {
        headers: { User: this.userSubject.value.RUT_USUARIO, Pass: this.userPassword, SOLO_CHAT:'1' },
      });
    }
    
  }
}
