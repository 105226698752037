import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Order } from 'src/app/shared/models/orders';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { RejectReason } from 'src/app/shared/models/RejectReason';
import { ReturnReason } from 'src/app/shared/models/returnReason';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private endpoint: string;
  private loginEndpoint: string;
  private rejectReasonsEndpoint: string;
  private returnReasonsEndpoint: string;
  private setValuesEndpoint: string;
  private setStatusEndpoint: string;
  private key: string;

  constructor(private http: HttpClient) {
    this.endpoint = environment.ResourcePolpaico + environment.pedidos;
    this.loginEndpoint = environment.ResourceLogin;
    this.rejectReasonsEndpoint =
      environment.ResourcePolpaico + environment.rejectReasons;
    this.setValuesEndpoint =
      environment.ResourcePolpaico + environment.setValues;
    this.returnReasonsEndpoint =
      environment.ResourcePolpaico + environment.returnReasons;
    this.setStatusEndpoint =
      environment.ResourcePolpaico + environment.setStatus;
  }

  private transformDate(inputDate: string): string {
    const parsedDate = moment(inputDate, 'DD-MMM-YY');
    const formattedDate = parsedDate.format('DD/MM/YYYY');

    return formattedDate;
  }

  setValues(key: string) {
    this.key = key;
  }

  setStatus(status: string, rut: string) {
    const headers = {
      Key: this.key,
      M_RUT_ESTADO: rut,
      M_ESTADO_ID: status.toString(),
    };
    return this.http.get<any>(this.setStatusEndpoint, { headers }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getMockOrders() {
    return this.http.get<any>('./assets/mock/orders.json').pipe(
      map((data: any) => {
        return data.datos[0].VIAJESH.map((item) => {
          return <Order>{
            tkt: item.TKT_H,
            orderDate: this.transformDate(item.FECHA_PED_H),
            plant: item.PLANTA_H,
            orderNumber: item.NUMERO_PEDID_H,
            clientCode: item.CODIGO_CLI_H,
            clientName: item.NOMBRE_CLIENTE_H,
            workCode: item.CODIGO_PROYE_H,
            workName: item.NOMBRE_PROYECTO_H,
            deliveryAddress: item.DIRECCION_ENTREGA_H,
            productCode: item.CODIGO_PRODU_H,
            productDescription: item.DESCRIPCION_PRODUCTO_H,
            scheduledAmount: item.CANTIDAD_PROGRAMADA_H,
            loadAmount: item.CARGAS_H,
            frequency: item.FRECUENCIA_H,
            ticketPrintingTime: item.IMPRESO_H,
            startTime: item.HORA_INI_H,
            estimatedTravelTime: item.TIEMPO_DE_VIAJE_H,
            requiredTrucks: item.CAMIONES_REQUERIDOS_H,
            loadSize: item.TAMANO_CARGA_H,
            quantityShipped: item.CANTIDAD_DESPACHADA_H,
            outstandingAmount: item.CANTIDAD_PENDIENTE_H,
            OnsiteContactPhoneNumber: item.TELEFONO_H,
            OnsiteContactName: item.CONTACTO_H,
            truckNumber: item.DESIGNATION_H,
            driverName: item.CONDUCTOR_H,
            truckPatent: item.PATENTE_H,
            currentTripNumber: item.NROCARGA_H,
            status: +item.STATUS_H,
            webOrderNumber: item.ORDL_H,
            hourOne: item.HORA_UNO_H,
            hourTwo: item.HORA_DOS_H,
            hourThree: item.HORA_TRES_H,
            hourFour: item.HORA_CUATRO_H,
            hourFive: item.HORA_CINCO_H,
            hourSix: item.HORA_SEIS_H,
            hourSeven: item.HORA_SIETE_H,
            hourEight: item.HORA_OCHO_H,
            folioNumberGD: item.FOLIO_H,
            stateDescription: item.ESTADO_H,
            rejectionFlag: item.MX_MOTIVO_RECHAZO,
            isSignature: item.MX_CLI_FIRMA != '',
            concreteReturn: item.MX_COMENTARIOS_HORMI,
            waterAddition: item.MX_ADICIONAL_UNO_H != '',
            anotherAddition: item.MX_ADICIONAL_DOS_H != '',
            routePdfGuide: item.PDF_GUIA_H,
            instructions: item.INSTRUCCIONES,
            additiveRemarks: item.OBSERVACIONES_ADIT,
            sealNumber: item.SELLO,
            cone: item.CONO,
            reasonToReturn: item.MX_MOTIVO_RETORNO,
            m3Returned: +item.MX_M3_RETORNO,
            samples: item.MUESTRAS,
            loadingMouth: item.BOCA_CARGA,
            urlMap: item.URLMAP,
            files: item.ARCHIVOS.map((file) => {
              return {
                id: file.MX_FILES_ID,
                fileName: file.MX_NOMBRE_ARCHIVO,
                label: file.MX_ETIQUETA_ARCHIVO,
                url: file.URL,
              };
            }),
            operatorComments: item.COMENTARIOS_OPERADORES.map((comment) => {
              return {
                id: comment.MX_COMENTARIO_ID,
                tkt: comment.MX_TKT_H_COM,
                rut: comment.MX_RUT_CONDUCTOR,
                driverName: comment.MX_NOMBRE_CONDUCTOR,
                workCode: comment.MX_CODIGO_PROYE_H,
                date: comment.MX_FECHA_COMENTARIO,
                comment: comment.MX_COMENTARIO,
              };
            }),
          };
        });
      })
    );
  }

  getAllOrders() {
    return this.http
      .get<any>(this.loginEndpoint, {
        headers: {
          Key: this.key,
        },
      })
      .pipe(
        map((data: any) => {
          return data.datos[0].VIAJESH.map((item) => {
            return <Order>{
              tkt: item.TKT_H,
              orderDate: this.transformDate(item.FECHA_PED_H),
              plant: item.PLANTA_H,
              orderNumber: item.NUMERO_PEDID_H,
              clientCode: item.CODIGO_CLI_H,
              clientName: item.NOMBRE_CLIENTE_H,
              workCode: item.CODIGO_PROYE_H,
              workName: item.NOMBRE_PROYECTO_H,
              deliveryAddress: item.DIRECCION_ENTREGA_H,
              productCode: item.CODIGO_PRODU_H,
              productDescription: item.DESCRIPCION_PRODUCTO_H,
              scheduledAmount: item.CANTIDAD_PROGRAMADA_H,
              loadAmount: item.CARGAS_H,
              frequency: item.FRECUENCIA_H,
              ticketPrintingTime: item.IMPRESO_H,
              startTime: item.HORA_INI_H,
              estimatedTravelTime: item.TIEMPO_DE_VIAJE_H,
              requiredTrucks: item.CAMIONES_REQUERIDOS_H,
              loadSize: item.TAMANO_CARGA_H,
              quantityShipped: item.CANTIDAD_DESPACHADA_H,
              outstandingAmount: item.CANTIDAD_PENDIENTE_H,
              OnsiteContactPhoneNumber: item.TELEFONO_H,
              OnsiteContactName: item.CONTACTO_H,
              truckNumber: item.DESIGNATION_H_ANT,
              driverName: item.CONDUCTOR_H,
              truckPatent: item.PATENTE_H,
              currentTripNumber: item.NROCARGA_H,
              status: +item.STATUS_H,
              webOrderNumber: item.ORDL_H,
              hourOne: item.HORA_UNO_H,
              hourTwo: item.HORA_DOS_H,
              hourThree: item.HORA_TRES_H,
              hourFour: item.HORA_CUATRO_H,
              hourFive: item.HORA_CINCO_H,
              hourSix: item.HORA_SEIS_H,
              hourSeven: item.HORA_SIETE_H,
              hourEight: item.HORA_OCHO_H,
              folioNumberGD: item.FOLIO_H,
              stateDescription: item.ESTADO_H,
              rejectionFlag: item.MX_MOTIVO_RECHAZO ?? '',
              isSignature: (item.MX_CLI_FIRMA != '' && item.MX_CLI_FIRMA != "0"),
              concreteReturn: item.MX_COMENTARIOS_HORMI,
              waterAddition: item.MX_ADICIONAL_UNO_H != '',
              anotherAddition: item.MX_ADICIONAL_DOS_H != '',
              guidingRemarks: item.MX_ADICIONAL_TRES_H,
              routePdfGuide: item.PDF_GUIA_H,
              instructions: item.INSTRUCCIONES,
              additiveRemarks: item.OBSERVACIONES_ADIT,
              sealNumber: item.SELLO,
              cone: item.CONO,
              reasonToReturn: item.MX_MOTIVO_RETORNO,
              m3Returned: +this.getOnlyNumbers(item.MX_M3_RETORNO),
              samples: item.MUESTRAS,
              loadingMouth: item.BOCA_CARGA,
              urlMap: item.URLMAP,
              actualQuantityShipped: +item.CANT_DESP_REAL_H,
              isDigitalSignature: item.ES_GUIA_DIGITAL == '1',
              isNotSigned: item.MX_CLIENTE_FIRMA_H == '0',
              neg:item.NEG,
              um:item.UM_H,
              pallet:item.MX_PALLET_RETORNO,
              flag_pending:item.FLAG_PEDIDO_PEN
            };
          });
        })
      );
  }

  getOrder(tkt: string) {
    return this.http
      .get<any>(this.endpoint, {
        headers: {
          Key: this.key,
          TKT_H: tkt,
        },
      })
      .pipe(
        map((data: any) => {
          return data.datos[0].VIAJESH.map((item) => {
            return <Order>{
              tkt: item.TKT_H,
              orderDate: this.transformDate(item.FECHA_PED_H),
              plant: item.PLANTA_H,
              orderNumber: item.NUMERO_PEDID_H,
              clientCode: item.CODIGO_CLI_H,
              clientName: item.NOMBRE_CLIENTE_H,
              workCode: item.CODIGO_PROYE_H,
              workName: item.NOMBRE_PROYECTO_H,
              deliveryAddress: item.DIRECCION_ENTREGA_H,
              productCode: item.CODIGO_PRODU_H,
              productDescription: item.DESCRIPCION_PRODUCTO_H,
              scheduledAmount: item.CANTIDAD_PROGRAMADA_H,
              loadAmount: item.CARGAS_H,
              frequency: item.FRECUENCIA_H,
              ticketPrintingTime: item.IMPRESO_H,
              startTime: item.HORA_INI_H,
              estimatedTravelTime: item.TIEMPO_DE_VIAJE_H,
              requiredTrucks: item.CAMIONES_REQUERIDOS_H,
              loadSize: item.TAMANO_CARGA_H,
              quantityShipped: item.CANTIDAD_DESPACHADA_H,
              outstandingAmount: item.CANTIDAD_PENDIENTE_H,
              OnsiteContactPhoneNumber: item.TELEFONO_H,
              OnsiteContactName: item.CONTACTO_H,
              truckNumber: item.DESIGNATION_H_ANT,
              driverName: item.CONDUCTOR_H,
              truckPatent: item.PATENTE_H,
              currentTripNumber: item.NROCARGA_H,
              status: +item.STATUS_H,
              manualStatus: +item.MX_ULTIMO_ESTADO_MANUAL_H,
              webOrderNumber: item.ORDL_H,
              hourOne: item.HORA_UNO_H,
              hourTwo: item.HORA_DOS_H,
              hourThree: item.HORA_TRES_H,
              hourFour: item.HORA_CUATRO_H,
              hourFive: item.HORA_CINCO_H,
              hourSix: item.HORA_SEIS_H,
              hourSeven: item.HORA_SIETE_H,
              hourEight: item.HORA_OCHO_H,
              folioNumberGD: item.FOLIO_H,
              stateDescription: item.ESTADO_H,
              rejectionFlag: item.MX_MOTIVO_RECHAZO ?? '',
              isSignature: item.MX_CLI_FIRMA != '',
              concreteReturn: item.MX_COMENTARIOS_HORMI,
              waterAddition: item.MX_ADICIONAL_UNO_H != '',
              anotherAddition: item.MX_ADICIONAL_DOS_H != '',
              routePdfGuide: item.PDF_GUIA_H,
              instructions: item.INSTRUCCIONES,
              additiveRemarks: item.OBSERVACIONES_ADIT,
              sealNumber: item.SELLO,
              cone: item.CONO,
              reasonToReturn: item.MX_MOTIVO_RETORNO,
              m3Returned: +item.MX_M3_RETORNO,
              samples: item.MUESTRAS,
              loadingMouth: item.BOCA_CARGA,
              urlMap: item.URLMAP,
              neg:item.NEG,
              um:item.UM_H,
              actualQuantityShipped: +item.CANT_DESP_REAL_H,
              files: item.ARCHIVOS.map((file) => {
                return {
                  id: file.MX_FILES_ID,
                  fileName: file.MX_NOMBRE_ARCHIVO,
                  label: file.MX_ETIQUETA_ARCHIVO,
                  url: file.URL,
                };
              }),
              operatorComments: item.COMENTARIOS_OPERADORES.map((comment) => {
                return {
                  id: comment.MX_COMENTARIO_ID,
                  tkt: comment.MX_TKT_H_COM,
                  rut: comment.MX_RUT_CONDUCTOR,
                  name: comment.MX_NOMBRE_CONDUCTOR,
                  workCode: comment.MX_CODIGO_PROYE_H,
                  date: comment.MX_FECHA_COMENTARIO,
                  comment: comment.MX_COMENTARIO,
                };
              }),
            };
          });
        })
      );
  }

  setRejectValues(
    tkt: string,
    orderNumber: string,
    rejectReason: string,
    rejectDescription: string,
    files: File[],
    isMixer: boolean = true,
    rutClient: string = ''
  ) {
    const headers = {
      Key: this.key,
      MX_TKT_H: tkt,
      MX_NUMERO_PEDID_H: orderNumber,
      MX_RECHAZO_FLAG: '1',
      MX_MOTIVO_RECHAZO: rejectReason,
      MX_DESCRIPCION_RECHAZO: rejectDescription,
      MX_RECHAZO_RUT_CLI: rutClient,
      FLAG_PEDIDO_PEN:'0'
    };
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(file.name, file);
    });
    const endpoint = isMixer
      ? this.setValuesEndpoint
      : environment.ResourcePolpaico + environment.setValuesCli;
    return this.http.post<any>(endpoint, formData, { headers }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  changeDeliveryPoint(
    tkt: string,
    orderNumber: string,
    lat: number,
    lng: number
  ) {
    const headers = {
      Key: this.key,
      MX_TKT_H: tkt,
      MX_NUMERO_PEDID_H: orderNumber,
      MX_LATITUD_H: lat.toString(),
      MX_LONGITUD_H: lng.toString(),
    };
    return this.http.get<any>(this.setValuesEndpoint, { headers }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  setExtraValues(
    tkt: string,
    orderNumber: string,
    waterAddition: boolean,
    anotherAddition: boolean,
    guidingRemarks: string,
    m3Returned: number,
    reasonToReturn: string,
    m3ReturnedPallet: number,
    files: File[]
  ) {
    const headers = {
      Key: this.key,
      MX_TKT_H: tkt,
      MX_NUMERO_PEDID_H: orderNumber,
      MX_ADICIONAL_UNO_H: waterAddition.toString(),
      MX_ADICIONAL_DOS_H: anotherAddition.toString(),
      MX_ADICIONAL_TRES_H: guidingRemarks,
      MX_M3_RETORNO: m3Returned.toString(),
      MX_MOTIVO_RETORNO: reasonToReturn,
      MX_PALLET_RETORNO:m3ReturnedPallet.toString(),
      ES_GUIA_DIGITAL: '1',
      FLAG_PEDIDO_PEN:'0'
      
    };
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(file.name, file);
    });
    return this.http
      .post<any>(this.setValuesEndpoint, formData, { headers })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  addOperatorComment(tkt: string, orderNumber: string, comment: string) {
    const headers = {
      Key: this.key,
      MX_TKT_H: tkt,
      MX_NUMERO_PEDID_H: orderNumber,
      MX_COMENTARIO: comment,
    };

    return this.http
      .get<any>(this.setValuesEndpoint, { headers: headers })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateStatus(tkt: string, orderNumber: string, status: number) {
    let headers;
    const currentHour = moment().format('HH:mm:ss');
    if (status === 2)
      headers = {
        Key: this.key,
        MX_TKT_H: tkt,
        MX_NUMERO_PEDID_H: orderNumber,
        MX_ULTIMO_ESTADO_MANUAL_H: status.toString(),
        MX_HORA_DOS_H: currentHour,
      };
    if (status === 3)
      headers = {
        Key: this.key,
        MX_TKT_H: tkt,
        MX_NUMERO_PEDID_H: orderNumber,
        MX_ULTIMO_ESTADO_MANUAL_H: status.toString(),
        MX_HORA_TRES_H: currentHour,
      };
    if (status === 4)
      headers = {
        Key: this.key,
        MX_TKT_H: tkt,
        MX_NUMERO_PEDID_H: orderNumber,
        MX_ULTIMO_ESTADO_MANUAL_H: status.toString(),
        MX_HORA_CUATRO_H: currentHour,
      };
    if (status === 5)
      headers = {
        Key: this.key,
        MX_TKT_H: tkt,
        MX_NUMERO_PEDID_H: orderNumber,
        MX_ULTIMO_ESTADO_MANUAL_H: status.toString(),
        MX_HORA_CINCO_H: currentHour,
      };
    if (status === 6)
      headers = {
        Key: this.key,
        MX_TKT_H: tkt,
        MX_NUMERO_PEDID_H: orderNumber,
        MX_ULTIMO_ESTADO_MANUAL_H: status.toString(),
        MX_HORA_SEIS_H: currentHour,
      };

    return this.http.get<any>(this.setValuesEndpoint, { headers }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getRejectReasons(): Observable<RejectReason[]> {
    return this.http.get<any>(this.rejectReasonsEndpoint);
  }

  getReturnReasons() {
    return this.http.get<ReturnReason[]>(this.returnReasonsEndpoint).pipe(
      map((data) => {
        return data.map((item) => {
          return <RejectReason>{
            id: +Object.keys(item)[0],
            description: Object.values(item)[0],
          };
        });
      })
    );
  }

  setUnsignedGuide(tkt: string, orderNumber: string) {
    const headers = {
      Key: this.key,
      MX_TKT_H: tkt,
      MX_NUMERO_PEDID_H: orderNumber,
      MX_CLIENTE_FIRMA_H: '0',
    };
    return this.http.get<any>(this.setValuesEndpoint, { headers }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  setSignedGuide(
    tkt: string,
    orderNumber: string,
    rut: string,
    name: string,
    signature: string
  ) {
    const headers = {
      Key: this.key,
      MX_TKT_H: tkt,
      MX_NUMERO_PEDID_H: orderNumber,
      MX_CLI_FIRMA_RUT: rut,
      MX_CLI_FIRMA_NOMBRE: name,
      MX_CLI_FIRMA: signature,
    };
    return this.http.get<any>(this.setValuesEndpoint, { headers }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getOnlyNumbers(text: string) {
    return text.replace(/\D/g, '');
  }

  setMessageSos(
    message: string
  ) {
    const headers = {
      Key: this.key,
      MX_COMENTARIO_SOS: message,
    };
    return this.http.get<any>(this.setValuesEndpoint, { headers });
  }

  setChangeFlagMessage(
  ) {
    const headers = {
      Key: this.key,
      MX_COMENTARIO_SOS_NO_LEIDO: "0",
    };
    return this.http.get<any>(this.setValuesEndpoint, { headers });
  }

  setPendingOrder(
    tkt: string,
    orderNumber: string,
  ) {
    const headers = {
      Key: this.key,
      MX_TKT_H: tkt,
      FLAG_PEDIDO_PEN:"1",
      MX_NUMERO_PEDID_H: orderNumber,
    };
    return this.http.get<any>(this.setValuesEndpoint, { headers }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  setRegenerateQr(
    tkt: string,
    orderNumber: string,
  ) {
    const headers = {
      Key: this.key,
      MX_TKT_H: tkt,
      ES_GUIA_DIGITAL: '1',
      MX_NUMERO_PEDID_H: orderNumber,
    };
    return this.http.get<any>(this.setValuesEndpoint, { headers }).pipe(
      map((data) => {
        return data;
      })
    );
  }




}
