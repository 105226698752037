import { Injectable, Input } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AccountService } from '../account.service';
import { RolesUserService } from '../roles-user.service';
import { OrderService } from '../../pedidos/pedidos.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    public authService: AccountService,
    public router: Router,
    private serviceRoles: RolesUserService,
    private orderService: OrderService
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.authService.isVigenteKey()) {
      this.authService.logout();
      return false;
    }

    if (this.authService.userValue !== null) {
      this.orderService.setValues(this.authService.userValue.KEY);
      if (
        this.serviceRoles.onValidarMenu(
          state.url.toString().replace('/', '')
        ) == false
      ) {
        this.router.navigate(['unauthorized']);
        return false;
      } else {
        return true;
      }
    } else {
      this.validateAnonimo(state.url,route);
    }

  }

  validateRutaPeril(Ruta: string) {
    return true;
  }

  validateAnonimo(url: any, route:any) {
    debugger
    if (url.includes('/Seguimiento/') && url.includes('tkt=') && url.includes('key=')) {
      window.location.href= url.replace('Seguimiento','SeguimientoAnonimo');
      return false;
    }else{
      this.router.navigate(['login']);
      return false;
    }
  }
}
