import { DataHistorialPedidosModel, HistorialPedidosModel } from 'src/app/shared/models/HistorialPedidosModel';
import { HistorialPedidosService } from 'src/app/services/historial-pedidos/historial-pedidos.service';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Utilidades } from "src/app/shared/FuntionsGlobales/Utilidades";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-modal-editar-pedido",
  templateUrl: "./modal-editar-pedido.component.html",
  styleUrls: ["./modal-editar-pedido.component.css"],
})
export class ModalEditarPedidoComponent implements OnInit {
  @Input() classModal;
  @Input() finishedProcess;
  @Input() cantidadSelecionada: any;
  @Input() objetoSeleccionado: any;
  @Input() successfulProcess;
  @Output() cerrarModalEmitter = new EventEmitter<void>();
  @Output() editarPedidoEmitter = new EventEmitter<void>();

  numberEdit: number;
  high: number;
  less: number;
  minValue: number = 3;

  ErrorRango:string = 'La cantidad máxima a aumentar o disminuir es de 3 m3.';
  ErrorMinimo: string = 'La cantidad solicitada no puede ser inferior a 3 m3.';

  form: FormGroup;
  ErrorCantidad = true;
  MsgErrorCantidad: string = this.ErrorRango;
  constructor(public pedidoService: HistorialPedidosService, private router: Router,) {
    this.form = new FormGroup({
      cantidad: new FormControl(),
    });
  }

  ngOnInit() {}

  get cantidad() {
    let value: string = this.form.get("cantidad").value;
    if (value == undefined) value = "";
    return value;
  }

  onkeypressValidarCantidadMinima(dato) {
    //regla < 3 o >3
    //regla nueva cantidad no puede ser inferior a 3 mts3
    //obtengo la cantidad actual del pedido

    this.high = parseInt(this.cantidadSelecionada) + 3;
    this.less = parseInt(this.cantidadSelecionada) - 3;

    //seteo la nueva cantidad del pedido
    this.numberEdit = parseInt(dato.currentTarget.value);

    if(this.numberEdit < this.minValue || !this.numberEdit){
      this.MsgErrorCantidad = this.ErrorMinimo;
      this.ErrorCantidad = true;
    }
    else if (this.numberEdit > this.high || this.numberEdit < this.less) {
      //saltar mensaje de no es posible aumentar o disminuir la cantidad solicitada
      this.ErrorCantidad = true;
      this.MsgErrorCantidad = this.ErrorRango;
    } else {
      this.form.setValue({
        cantidad: this.numberEdit,
      });
      this.ErrorCantidad = false;
      this.MsgErrorCantidad = '';
    }
    return Utilidades.soloNumero(dato);
  }

  onPasteValidarCantidadMinima(dato) {
    var clipboardData, pastedData;
    dato.stopPropagation();
    dato.preventDefault();
    clipboardData = dato.clipboardData;
    pastedData = clipboardData.getData("Text");
    try {
      parseInt(dato.srcElement.value + pastedData);
      return true;
    } catch {
      return false;
    }
  }
  cerrarModal() {
    this.cerrarModalEmitter.emit();
    this.router.navigate(['/historialprogramacion']);
  }
  editarPedido() {
    this.editarPedidoEmitter.emit(this.createObject());
  }
  createObject() {
    this.objetoSeleccionado.CANT = this.numberEdit.toString();
    return this.objetoSeleccionado;
  }
}
