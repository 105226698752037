import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AccountService } from 'src/app/services/auth';
import { ChatService } from 'src/app/services/auth/helpers/chat-service.service';
import { OrderService } from 'src/app/services/pedidos/pedidos.service';

interface Message {
  content: string;
  sender: string;
  timestamp: string;
}
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit {
  showChat: boolean = false;
  newMessage: string = '';
  messages: Message[] = [];
  visibleChat: boolean = false;
  unReadMessagesCount = 0;
  getMessagesOnInterval: any;
  sentinelMessagesOnInterval: any;
  @ViewChild('messagesContainer') messagesContainer: ElementRef;

  isFirsIntent: boolean = true;
  countMessage = 0;

  constructor(
    private UserAccount: AccountService,
    private pedidosService: OrderService,
    private chatService: ChatService
  ) {}

  toggleChat(): void {
    this.showChat = !this.showChat;

    if (this.showChat) {
      this.chatService.sendMessage();
      this.changeFlagNotification();
      this.unReadMessagesCount = 0;
    }
  }

  sendMessage(): void {
    if (this.newMessage.trim() !== '') {
      this.messages.push({
        content: this.newMessage,
        sender: 'Yo',
        timestamp: this.formatDate(new Date()),
      });

      this.pedidosService
        .setMessageSos(this.newMessage)
        .subscribe((datosChat) => {
          this.newMessage = '';

          this.chatService.sendMessage();
        });
    }

    setTimeout(() => {
      this.scrollToBottom();
    });
  }

  changeFlagNotification() {
    this.pedidosService.setChangeFlagMessage().subscribe((datosChat) => {});
  }

  ngOnInit(): void {
    this.UserAccount.isLoggedIn.subscribe((loggedIn) => {
      if (loggedIn) {
        if (this.UserAccount.userValue && this.UserAccount.userValue.ROL !='CEMAR') {
          this.visibleChat = true;
        }
        
      } else if (
        this.UserAccount.userValue 
      ) {
        if (this.UserAccount.userValue.ROL !='CEMAR') {
          this.visibleChat = true;
        }
       
      } else {
        this.visibleChat = false;
      }

      if (this.visibleChat) {
        this.updateChat();
        this.getNewsMessages();
      } else {
        this.ngOnDestroy();
      }
    });

    this.chatService.chatVisible$.subscribe((isVisible) => {
      this.showChat = isVisible;
    });
  }

  scrollToBottom(): void {
    if (this.messagesContainer != undefined) {
      this.messagesContainer.nativeElement.scrollTop =
        this.messagesContainer.nativeElement.scrollHeight;
    }
  }


  async updateChat() {
    this.unReadMessagesCount = 0;
    this.messages = [];
    if (this.UserAccount.userValue) {
      this.UserAccount.getChatPolpaico().subscribe((datosChat) => {
        const dataChat = datosChat['datos'][0].CHAT_SOS;
  
        dataChat.forEach((dataMessage) => {
          let message = {
            content: dataMessage.COMENTARIO,
            sender: dataMessage.MIXER === '1' ? 'Yo' : dataMessage.NOMBRE,
            timestamp: dataMessage.FECHA,
          };
  
          if (dataMessage.MIXER === '0' && dataMessage.ESTADO == '1') {
            this.unReadMessagesCount++;
          }
  
          this.messages.push(message);
        });
        if (this.showChat) {
          this.changeFlagNotification();
        }
  
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
      });
    }
    
  }

  getNewsMessages() {
    this.getMessagesOnInterval = setInterval(() => {
      this.updateChat();
    }, 60000);
  }

  ngOnDestroy() {
    this.getMessagesOnInterval = clearInterval(this.getMessagesOnInterval);
  }

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    return date.toLocaleDateString('es-ES', options);
  }
}
