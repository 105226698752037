import { Component, OnInit } from '@angular/core';
import { ProgramacionMailModel } from 'src/app/shared/models/ProgramacionMailModel';

@Component({
  selector: 'app-p404',
  templateUrl: './p404.component.html'
})
export class P404Component implements OnInit {

  constructor() { }

  ngOnInit(): void { 

    
    // let a:ProgramacionMailModel = new ProgramacionMailModel();
    // let user = JSON.parse(localStorage.getItem('user'));
    // a.email = user.MAIL_USUARIO;
    // a.fullName  = user.NOMBRE_USUARIO;
    // //sólo el código
    // a.link = "123"

    // this.nuevoPedido.EnviarMailProgramacionCLiente(a).subscribe((res) => {

    //   if(res)
    //   {
    //     //enviado
    //   }
    //   else{
    //     //error
    //   }

    // })

  }

}
