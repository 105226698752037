import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/services/pedidos/pedidos.service';
import { Order } from 'src/app/shared/models/orders';
import { ReturnReason } from 'src/app/shared/models/returnReason';

@Component({
  selector: 'app-pedidos-pendiente-observaciones',
  templateUrl: './pedidos-pendiente-observaciones.component.html',
  styleUrls: ['./pedidos-pendiente-observaciones.component.css'],
})
export class PedidosPendienteObservacionesComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  images: { file: File; preview: string }[] = [];

  form = new FormGroup({
    waterAddition: new FormControl(false),
    anotherAddition: new FormControl(false),
    guidingRemarks: new FormControl(''),
    m3Returned: new FormControl(''),
    reasonToReturn: new FormControl(''),
    m3ReturnedPallet: new FormControl(''),
  });

  returnReasons: ReturnReason[] = [];

  @ViewChild('modalContent') modalContent!: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;

  @Output() nextStep = new EventEmitter<any>();
  @Output() rejectTruck=new EventEmitter<any>();
  @Input() order: Order;

  isHormigon=false;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private scrollStrategyOptions: ScrollStrategyOptions
  ) {}

  ngOnInit(): void {


    if (this.order.neg=="3200" || this.order.neg=="5000") {
      this.isHormigon=true;
    }

    this.getReturnReasons();
  }

  openFileSelector() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!this.isValidFile(file)) continue;
      // Leer la imagen como una URL de datos para la previsualización
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.images.push({ file, preview: e.target.result });
      };
      reader.readAsDataURL(file);
    }

    // Limpiar el input file para permitir seleccionar el mismo archivo en futuras cargas
    this.fileInput.nativeElement.value = '';
  }

  isValidFile(file: File): boolean {
    const allowedExtensions = ['.jpg', '.jpeg', '.png'];
    const fileNameParts = file.name.split('.');
    const fileExtension = `.${fileNameParts[fileNameParts.length - 1]}`;
    return allowedExtensions.includes(fileExtension.toLowerCase());
  }

  confirm() {
    this.dialogRef = this.dialog.open(this.modalContent, {
      maxWidth: '90%',
      scrollStrategy: this.scrollStrategyOptions.noop(),
    });
  }

  submit() {
    this.closeModal();
    const files = this.images.map((image) => image.file);
    this.orderService
      .setExtraValues(
        this.order.tkt.toString(),
        this.order.orderNumber.toString(),
        this.form.value.waterAddition,
        this.form.value.anotherAddition,
        this.form.value.guidingRemarks,
        this.form.value.m3Returned,
        this.form.value.reasonToReturn,
        this.form.value.m3ReturnedPallet,
        files
      )
      .subscribe((data) => {
        this.nextStep.emit();
      });
  }

  rejectTruckLocal(){
    this.rejectTruck.emit();
  }

  getReturnReasons() {
    debugger
    this.orderService.getRejectReasons().subscribe((data) => {

        this.returnReasons = data[this.order.neg];
      
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode === 44) {
      return true;
    } else {
      return false;
    }
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = Number(inputElement.value);

    if (inputValue > this.order.actualQuantityShipped) {
      this.form.controls.m3Returned.setValue(this.order.actualQuantityShipped);
    }else
    if (inputValue < 0 ) {
      this.form.controls.m3Returned.setValue(this.order.actualQuantityShipped);
    }
  }

  onInpuMajorCero(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = Number(inputElement.value);

    if (inputValue < 0 ) {
      this.form.controls.m3ReturnedPallet.setValue(0);
    }
  }
}
