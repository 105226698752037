import { EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Constantes } from 'src/app/shared/Constantes/constantes';

import { UserLoggedModel } from 'src/app/shared/models/UserLoginModel.model';

@Component({
  selector: 'app-cambiar-password-modifi',
  templateUrl: './cambiar-password-modifi.component.html'
})
export class CambiarPasswordModifiComponent implements OnInit {
  formEdicion: FormGroup;
  erroModal = {
    letraMinuscula: false,
    letraMayuscula: false,
    simbolo: false,
    longitud: false
  }
  showPasswor:boolean=false;
  @Input() dataUsuario: UserLoggedModel;
  @Output() enventoAcualizarData = new EventEmitter<string>();
  constructor(private router:Router) { }
  get password() { return this.formEdicion.get('password'); }
  ngOnInit(): void {
    this.formEdicion = new FormGroup({
      password: new FormControl("", Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16),
        Validators.pattern(Constantes.regularExpresionClave)]))
    });
  }

  onSubmit() {
    // debugger
     if(this.password.value.length ==  0 ){
       this.formEdicion.get('password').setErrors({'empty': true});
       this.formEdicion.markAllAsTouched();
       this.formEdicion.markAsDirty();
       return;
     }
    this.enventoAcualizarData.emit(this.password.value);
  }
  onValidateErrorClass() {

    this.formEdicion.clearValidators();


    if (this.password.invalid
      && (this.password.dirty
        || this.password.touched)) {
      let data: string = this.password.value;

      if (data.search(/([A-Z])/g) == -1) {
        this.erroModal.letraMayuscula = false;
      }else
      {
        this.erroModal.letraMayuscula=true
      }
      if (data.search(/([a-z])/g) == -1) {
        this.erroModal.letraMinuscula = false;
      }else
      {
        this.erroModal.letraMinuscula=true
      }
      if (data.search(/[!@#$%^&*(),.?":{}|<>\-\+_]/g) == -1) {
        this.erroModal.simbolo = false;
      }else
      {
        this.erroModal.simbolo=true
      }
      if (this.password.hasError('minlength') || this.password.hasError('required') ) {
        this.erroModal.longitud = false;
      }else
      {
        this.erroModal.longitud=true
      }
    }
    return "positive";
  }

  goBack(){
    this.router.navigate(["/dashboard"]);
  }

}
