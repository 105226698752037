import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, AlertService } from 'src/app/services/auth';
import { MenuService } from 'src/app/services/menu/menu-service';
import { PerfilService } from 'src/app/services/perfil/perfil.service';
import { PerfilModel } from 'src/app/shared/models/PerfilModel';
import { UserLoggedModel } from 'src/app/shared/models/UserLoginModel.model';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html'
})
export class NotificacionesComponent implements OnInit {
  stateAcordion:boolean=false;
  notifiCaciones={
    ProgramacionSolicitada:false,
    notificacionText:false,
    PedidosRuta:false,
    ResumenDiario:false}
  EnumNotifi={
    ProgramacionSolicitada:0,
    notificacionText:1,
    PedidosRuta:2,
    ResumenDiario:3
  }
  dataUsuario:UserLoggedModel;
   constructor(menuService:MenuService,
     private usuarioService:AccountService,
     private perfilSevice:PerfilService,
     private alertService: AlertService,
     private router:Router) {
      //  debugger;
     menuService.tilteMenu="Notificaciones";
     this.dataUsuario= usuarioService.userValue;

     this.notifiCaciones.ProgramacionSolicitada= (this.dataUsuario.opemail1.toString() == "1" || this.dataUsuario.opemail1 == true ? true: false);
     this.notifiCaciones.PedidosRuta= (this.dataUsuario.opemail2.toString() == "1" || this.dataUsuario.opemail2== true ? true:false);
     this.notifiCaciones.ResumenDiario=(this.dataUsuario.opemail4.toString() == "1" || this.dataUsuario.opemail4 == true? true:false);
     this.notifiCaciones.notificacionText=(this.dataUsuario.opsms.toString() == "1" || this.dataUsuario.opsms == true? true:false);
   }

  ngOnInit(): void {
  }


  actualizarNotificacionesUsuario()
  {
    // debugger;
    let perfil = new PerfilModel();
      perfil.FONO_USUARIO=this.dataUsuario.FONO_USUARIO;
      perfil.Key=this.dataUsuario.KEY;
      perfil.MAIL_USUARIO=this.dataUsuario.MAIL_USUARIO;
      perfil.NOMBRE_USUARIO=this.dataUsuario.NOMBRE_USUARIO;
      perfil.RUT=this.dataUsuario.RUT_USUARIO;
      perfil.opemail1=this.notifiCaciones.ProgramacionSolicitada;
      perfil.opemail2=this.notifiCaciones.PedidosRuta;
      perfil.opemail3=this.dataUsuario.opemail3;
      perfil.opemail4=this.notifiCaciones.ResumenDiario;
      perfil.opsms=this.notifiCaciones.notificacionText;
    this.perfilSevice.updatePerfil(perfil).subscribe((x)=>
    {
      if (x["Estado"]!=="Modificado"){
        this.alertService.error("Error actualizar al actualizar la notificacion. Reintente");
      }else
      {
        this.dataUsuario.opemail1=this.notifiCaciones.ProgramacionSolicitada;
        this.dataUsuario.opemail2=this.notifiCaciones.PedidosRuta;
        this.dataUsuario.opemail4= this.notifiCaciones.ResumenDiario;
        this.dataUsuario.opsms= this.notifiCaciones.notificacionText;
        this.usuarioService.saveDatos(this.dataUsuario);
      }
    }
    );
  }
  goBack(){
    this.router.navigate(["/dashboard"]);
  }
}
