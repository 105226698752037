import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { HistorialPedidosService } from 'src/app/services/historial-pedidos/historial-pedidos.service';
import { MenuService } from 'src/app/services/menu/menu-service';
import { AccountService } from 'src/app/services/auth';
import { DataHistorialPedidosModel } from 'src/app/shared/models/HistorialPedidosModel';
import { Utilidades } from 'src/app/shared/FuntionsGlobales/Utilidades';
import { Constantes } from 'src/app/shared/Constantes/constantes';
import { FiltroHistorialPedidoModel } from 'src/app/shared/models/FiltroHistorialPedidoModel';
import { ComboType } from 'src/app/shared/models/ComboType';
import * as moment from 'moment';
import { ObraModel } from 'src/app/shared/models/UserLoginModel.model';
import { KeyValue } from '@angular/common';
import { exit } from 'process';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-historial-programacion',
  templateUrl: './historial-programacion.component.html',
  styleUrls: ['./historial-programacion.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HistorialProgramacionComponent implements OnInit {
  enumEstados = {
    Pendiente: '1',
    Programado: '2',
    Rechazado: '3',
    Eliminado: '4',
  };
  @ViewChild('pdfPage', { static: false }) pdfPage: ElementRef;
  @ViewChild('pdfTable') pdfTable: ElementRef;
  dataByDate: DataHistorialPedidosModel[];
  numArray = 0;
  obraSelected = '';
  classFiltro = Constantes.classCierreModal;
  obraSelecionada = '';
  codigoObraSelecionada = '0';
  filtrado = new FiltroHistorialPedidoModel();
  lstObras: ComboType[] = new Array();
  boolvalidarVacio = false;
  historialFiltrado: DataHistorialPedidosModel[];
  mesInicio: string;
  mesFin: string;
  deviceInfo = null;
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;
  slideConfig: {
    slidesToShow: number;
    slidesToScroll: number;
    enabled: boolean;
    autoplay: boolean;
    draggable: boolean;
    autoplaySpeed: number;
    method: {};
    event: {
      beforeChange: (
        event: any,
        slick: any,
        currentSlide: any,
        nextSlide: any
      ) => void;
      afterChange: (
        event: any,
        slick: any,
        currentSlide: any,
        nextSlide: any
      ) => void;
    };
    dots: boolean;
    infinite: boolean;
    responsive: (
      | {
          breakpoint: number;
          settings: {
            slidesToShow: number;
            slidesToScroll: number;
            infinite: boolean;
            dots: boolean;
          };
        }
      | {
          breakpoint: number;
          settings: {
            slidesToShow: number;
            slidesToScroll: number;
            infinite?: undefined;
            dots?: undefined;
          };
        }
    )[];
  };

  ClaseModalDescargar = Constantes.classCierreModal;
  ListadoFilteredGlobal: DataHistorialPedidosModel[] = [];
  dataByDatePDF: DataHistorialPedidosModel[];

  selectTipoFiltro: string = '0';
  ArrayTipoFiltro = [{ Name: 'Semana' }, { Name: 'Dia' }];

  showDatePicker: boolean = false;

  IsSemana = true;
  IsDia = false;

  constructor(
    private accountService: AccountService,
    private historial: HistorialPedidosService,
    private menu: MenuService,
    private deviceService: DeviceDetectorService
  ) {
    this.epicFunction();
    menu.btnPrevius = true;
    menu.tilteMenu = 'Despachos';
    if (menu.productoSelecionado) {
      this.filtrado = new FiltroHistorialPedidoModel();
      this.filtrado.selectedObra = parseInt(
        menu.codigoproductoSelecionado
      ).toString();
      this.obraSelected = menu.productoSelecionado;
      if (this.menu.filterFields != null)
        this.menu.filterFields.selectedObra = this.filtrado.selectedObra;
      else this.menu.filterFields = this.filtrado;
      //TODO: Revisar si viene del padre que se cargue esta data
      if (
        menu.filterFields?.selectedObra ||
        menu.filterFields?.searchText ||
        menu.filterFields?.fechaInicio ||
        menu.filterFields?.fechaFin ||
        menu.filterFields?.selectedEstado
      )
        this.filtrado = menu.filterFields;
    } else {
      this.classFiltro = Constantes.classCierreModal;
    }
  }

  ngOnInit(): void {
    if (this.isMobile) {
      this.selectTipoFiltro = 'Dia';
      this.IsSemana = false;
      this.IsDia = true;
    } else {
      this.selectTipoFiltro = 'Semana';
      this.IsSemana = true;
      this.IsDia = false;
    }
    if (
      this.menu.codigoproductoSelecionado &&
      this.menu.codigoproductoSelecionado.length > 0
    ) {
      this.codigoObraSelecionada = parseInt(
        this.menu.codigoproductoSelecionado
      ).toString();
      this.obraSelecionada = this.menu.productoSelecionado;
      if (this.IsSemana) {
        this.filtrado.fechaInicio = moment().startOf('isoWeek');
        this.filtrado.fechaFin = moment().endOf('isoWeek').subtract(1, 'days');
      } else {
        this.filtrado.fechaInicio = moment();
        this.filtrado.fechaFin = moment();
      }
    } else {
      this.classFiltro = Constantes.classAperturaModal;
    }

    this.loadProducts();
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    let slidesToShow: number;
    let slidesToScroll: number;
    if (this.isDesktopDevice) {
      slidesToShow = 7;
      slidesToScroll = 7;
    } else if (this.isMobile || this.isTablet) {
      slidesToShow = 1;
      slidesToScroll = 1;
    }

    this.slideConfig = {
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToScroll,
      enabled: true,
      autoplay: false,
      draggable: true,
      autoplaySpeed: 0,
      method: {},
      event: {
        beforeChange: function (event, slick, currentSlide, nextSlide) {},
        afterChange: function (event, slick, currentSlide, nextSlide) {},
      },
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    };

    // console.log(this.deviceInfo);
    // console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  eventoFiltrar(data: FiltroHistorialPedidoModel) {
    debugger;
    let tempListadoFiltered: DataHistorialPedidosModel[] = [];
    this.menu.filterFields = data;
    this.codigoObraSelecionada = data.selectedObra;
    this.obraSelecionada = this.lstObras.find(
      (x) => x.id === data.selectedObra
    )?.value;
    if (data?.selectedObra) {
      tempListadoFiltered = this.historialFiltrado.filter(
        (pedido) => pedido.COD_OBRA === data.selectedObra
      );
      this.ListadoFilteredGlobal = tempListadoFiltered;
      if (data.searchText) {
        let textoBusqueda: string = data.searchText.trim().toLowerCase();
        tempListadoFiltered = tempListadoFiltered.filter(
          (pedido) =>
            pedido.ID_CMD?.toLowerCase().includes(textoBusqueda) ||
            pedido.PRODUCTO?.toLowerCase().includes(textoBusqueda) ||
            pedido.PROD_COD?.toLowerCase().includes(textoBusqueda)
        );
      }

      let fechaFormatted;
      //by fechaInicio
      if (data.fechaInicio) {
        if (this.IsSemana)
          fechaFormatted = data.fechaInicio['_isValid']
            ? data.fechaInicio
            : moment().startOf('isoWeek');
        else
          fechaFormatted = data.fechaInicio['_isValid']
            ? data.fechaInicio
            : moment();
      } else if (this.IsSemana) fechaFormatted = moment().startOf('isoWeek');
      else fechaFormatted = moment();
      data.fechaInicio = fechaFormatted;

      tempListadoFiltered = tempListadoFiltered.filter(
        (pedido) =>
          pedido.FECHA_SOL >= Utilidades.getMomentToISO_8601(fechaFormatted)
      );

      //by FechaFin
      if (data.fechaFin) {
        if (this.IsSemana)
          fechaFormatted = data.fechaFin['_isValid']
            ? data.fechaFin
            : moment().endOf('isoWeek').subtract(1, 'days');
        else
          fechaFormatted = data.fechaFin['_isValid'] ? data.fechaFin : moment();
      } else if (this.IsSemana)
        fechaFormatted = moment().endOf('isoWeek').subtract(1, 'days');
      else fechaFormatted = moment();
      data.fechaFin = fechaFormatted;

      tempListadoFiltered = tempListadoFiltered.filter(
        (pedido) =>
          pedido.FECHA_SOL <= Utilidades.getMomentToISO_8601(fechaFormatted)
      );

      if (tempListadoFiltered.length > 0) {
        if (this.IsDia) {
          let fechaFormatted = Utilidades.getMomentToISO_8601(moment());
          if (data.fechaInicio) {
            fechaFormatted = tempListadoFiltered[0].FECHA_SOL;
          }

          this.mesInicio = this.getformatearFecha(fechaFormatted);
          tempListadoFiltered = tempListadoFiltered.filter(
            (pedido) => pedido.FECHA_SOL >= fechaFormatted
          );
        } else {
          this.mesInicio = this.getFormattedDateMonthYear(
            tempListadoFiltered[0].FECHA_SOL
          );
        }
      } else {
        let fechaFormatted = data.fechaInicio['_isValid']
          ? Utilidades.getMomentToISO_8601(data.fechaInicio)
          : Utilidades.getMomentToISO_8601(moment().startOf('isoWeek'));
        if (this.IsDia) {
          this.mesInicio = this.getformatearFecha(fechaFormatted);
        } else {
          this.mesInicio = this.getFormattedDateMonthYear(fechaFormatted);
        }
      }

      //by Estado
      if (data.selectedEstado) {
        let estadoString = data.selectedEstado.toString();
        if (estadoString != '-1' && estadoString != '5') {
          tempListadoFiltered = tempListadoFiltered.filter((pedido) => {
            if (pedido.ESTADO === estadoString && pedido.CONFIR != '1') {
              return true;
            } else {
              return false;
            }
          });
        } else if (estadoString == '5') {
          tempListadoFiltered = tempListadoFiltered.filter(
            (pedido) => pedido.CONFIR === '1'
          );
        }
      }
      this.dataByDate = Utilidades.groupBy(tempListadoFiltered, 'FECHA_SOL');
      if (this.dataByDate.length > 0) {
        this.dataByDate = this.rellenarFechasFaltantes(
          this.dataByDate,
          data.fechaInicio,
          data.fechaFin
        );
      }
    } else {
      this.dataByDate = [];
    }

    if (this.dataByDate.length > 0) {
      this.numArray = 1;
    } else {
      this.numArray = 0;
    }

    this.validarVacio();
  }

  loadProducts() {
    let HistoryPedido: BehaviorSubject<DataHistorialPedidosModel[]> =
      new BehaviorSubject<DataHistorialPedidosModel[]>([]);

    if (HistoryPedido.value == null || HistoryPedido.value.length == 0) {
      this.historial.getHistorialPedidos().subscribe((result) => {
        if (result?.unknownError) {
          this.boolvalidarVacio = true;
          return;
        }
        if (result?.datos) {
          result.datos.forEach((element) => {
            if (element.ESTADO == '3') element.ESTADO = '4';
          });
          localStorage.setItem('HistorialPedido', JSON.stringify(result.datos));
          this.InitProceso(result.datos);
        } else {
          this.boolvalidarVacio = true;
          return;
        }
      });
    } else {
      this.InitProceso(HistoryPedido.value);
    }
  }

  InitProceso(datos: DataHistorialPedidosModel[]) {
    let pedidos = datos;
    if (pedidos.length > 0) {
      this.historialFiltrado = pedidos.filter((producto) =>
        this.accountService.userValue.OBRAS.some(
          (obra) => obra.OB_CODIGO === producto.COD_OBRA
        )
      );
      this.dataByDate = Utilidades.groupBy(this.historialFiltrado, 'FECHA_SOL');

      this.cargarData(
        this.historialFiltrado,
        this.accountService.userValue.OBRAS
      );

      //TODO: si filtrado viene vacio, en casi todos los casos, hay que cargarle el selected
      this.eventoFiltrar(this.filtrado);
    } else {
      this.boolvalidarVacio = true;
      return;
    }

    if (this.dataByDate.length > 0) {
      this.numArray = 1;
    } else {
      this.numArray = 0;
    }
  }

  private cargarData(data: DataHistorialPedidosModel[], listObras) {
    for (let index = 0; index < listObras.length; index++) {
      let element: ObraModel = listObras[index];
      let codigo = parseInt(element.OB_CODIGO).toString();
      let Elementos = data.filter((x) => x.COD_OBRA == codigo);
      if (Elementos.length > 0) {
        let comboType: ComboType = new ComboType();
        comboType.id = parseInt(element.OB_CODIGO).toString();
        comboType.value = element.OB_NOMBRE;
        comboType.isEmpty = false;
        this.lstObras.push(comboType);
      } else {
        let comboType: ComboType = new ComboType();
        comboType.id = parseInt(element.OB_CODIGO).toString();
        comboType.value = element.OB_NOMBRE;
        comboType.isEmpty = true;
        this.lstObras.push(comboType);
      }
    }
    this.lstObras.sort(function (a, b) {
      return a.value > b.value ? 1 : a.value < b.value ? -1 : 0;
    });
    this.lstObras = [...this.lstObras];
  }

  public getFormattedDate(fecha: string) {
    moment.updateLocale('es', {
      invalidDate: '-',
    });
    const dateToObject = moment(fecha, 'YYYYMMDD');
    if (!this.isMobile) return dateToObject.format('dddd D');

    const day = dateToObject.format('dd D');
    return day.charAt(0).toUpperCase() + day.slice(2);
  }

  public getformatearFecha(fecha: string): string {
    moment.updateLocale('es', {
      invalidDate: '-',
    });

    const dateToObject = moment(fecha, 'YYYYMMDD');
    const fechaFormateada = dateToObject.format('dddd, D [de] MMMM YYYY');
    return fechaFormateada.charAt(0).toUpperCase() + fechaFormateada.slice(1);
  }

  public getFormattedDateDiaCorto(fecha: string) {
    moment.updateLocale('es', {
      invalidDate: '-',
    });
    const dateToObject = moment(fecha, 'YYYYMMDD');
    return dateToObject.format('ddd DD-MM-YYYY');
  }

  public getFormattedDateMonth(fecha: string) {
    moment.updateLocale('es', {
      invalidDate: '-',
    });
    const dateToObject = moment(fecha, 'YYYYMMDD');
    return dateToObject.format('MMMM');
  }

  public getFormattedDateMonthYear(fecha: string) {
    moment.updateLocale('es', {
      invalidDate: '-',
    });
    const dateToObject = moment(fecha, 'YYYYMMDD');
    return dateToObject.format('MMMM YYYY');
  }

  validarVacio() {
    if (!this.dataByDate) return (this.boolvalidarVacio = false);
    this.boolvalidarVacio = this.dataByDate.length === 0;
  }

  keyDescOrder = (
    a: KeyValue<string, any>,
    b: KeyValue<string, any>
  ): number => {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  };

  keyAscOrder = (
    a: KeyValue<string, any>,
    b: KeyValue<string, any>
  ): number => {
    return a.key < b.key ? -1 : b.key < a.key ? 1 : 0;
  };

  public async downloadAsPDFOld() {
    const doc = new jsPDF();

    const pdfPage = this.pdfPage.nativeElement;
    const div = pdfPage;
    await html2canvas(div).then((canvas) => {
      // Few necessary setting options
      const imgWidth = 208; // your own stuff to calc the format you want
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // your own stuff to calc the format you want
      const contentDataURL = canvas.toDataURL('image/png');
      doc.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
      doc.save(this.mesInicio + '-' + this.mesFin + '.pdf'); // save / download
    });
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  public async downloadAsPDF() {
    let DateIni = new BehaviorSubject<string>(
      JSON.parse(localStorage.getItem('DateIni'))
    );
    let DateFin = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('DateFin'))
    );

    if (DateIni != undefined) {
      let fechaFormattedIni = Utilidades.getMomentToISO_8601(
        moment(DateIni.value)
      );
      let fechaFormattedFin = Utilidades.getMomentToISO_8601(
        moment(DateFin.value)
      );
      let tempListadoFiltered = this.ListadoFilteredGlobal.filter(
        (pedido) =>
          pedido.FECHA_SOL >= fechaFormattedIni &&
          pedido.FECHA_SOL <= fechaFormattedFin
      );

      this.dataByDatePDF = Utilidades.groupBy(tempListadoFiltered, 'FECHA_SOL');

      if (this.dataByDatePDF.length == 0) {
        localStorage.setItem(
          'SIN_DATOS_PARA_DESCARGAR_HISTORIAL',
          JSON.stringify(true)
        );
        return;
      } else {
        localStorage.setItem(
          'SIN_DATOS_PARA_DESCARGAR_HISTORIAL',
          JSON.stringify(false)
        );
      }
    }

    await this.sleep(100);

    let divContainer = document.getElementById('Container');

    const doc = new jsPDF('p', 'mm');

    divContainer.style.display = 'block';
    const pdfPage = this.pdfTable.nativeElement;

    const div = pdfPage;
    await html2canvas(div, { useCORS: true }).then((canvas) => {
      divContainer.style.display = 'none';
      // Few necessary setting options
      const imgWidth = 208;
      var pageHeight = 293;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png', 0.6);

      var position = 0;

      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      doc.save(this.mesInicio + '-' + this.mesFin + '.pdf'); // save / download

      this.eventoCerrarModalDescargar();
    });
  }

  getEstado(ItemHistoria) {
    if (ItemHistoria.CONFIR == '1') {
      return 'Confirmado';
    } else {
      switch (ItemHistoria.ESTADO) {
        case this.enumEstados.Pendiente:
          return 'Pendiente';
        case this.enumEstados.Programado:
          return 'Programado';
        case this.enumEstados.Rechazado:
          return 'Rechazado';
        case this.enumEstados.Eliminado:
          return 'Eliminado';

        default:
          return '';
      }
    }
  }

  getBarColor(ItemHistoria) {
    if (ItemHistoria.CONFIR == '1') {
      return 'success';
    } else {
      switch (ItemHistoria.ESTADO) {
        case this.enumEstados.Pendiente:
          return 'warning';

        case this.enumEstados.Programado:
          return 'success';

        case this.enumEstados.Rechazado:
          return 'secondary';

        case this.enumEstados.Eliminado:
          return 'danger';
        default:
          return '';
      }
    }
  }

  truncarText(Texto: string) {
    return Texto.length > 40 ? Texto.substring(0, 37) + '...' : Texto;
  }

  truncarTextDemanda(Texto: string, maxSize: number) {
    return Texto.length > maxSize
      ? Texto.substring(0, maxSize - 3) + '...'
      : Texto;
  }

  convertTimeToAMPM(hora) {
    return Utilidades.getTimeAMPM(hora);
  }

  eventoCerrarModalDescargar() {
    this.ClaseModalDescargar = Constantes.classCierreModal;
  }

  eventoAbrirModalDescargar() {
    this.ClaseModalDescargar = Constantes.classAperturaModal;
  }

  rellenarFechasFaltantes(
    data: any[],
    fechaInicio: moment.Moment,
    fechaFin: moment.Moment
  ) {
    const fechaActual = fechaInicio.clone();

    while (fechaActual.isSameOrBefore(fechaFin, 'day')) {
      const fechaString = fechaActual.format('YYYYMMDD');
      const existeFecha = data[fechaString];

      if (existeFecha == undefined) {
        data[fechaString] = [];
      }

      fechaActual.add(1, 'day');
    }

    return data;
  }

  toggleDatePicker(event: Event): void {
    event.stopPropagation();
    this.showDatePicker = !this.showDatePicker;
  }

  onDateChange(event: any): void {
    const fechaSeleccionada = event.value;

    let fechaInicio = fechaSeleccionada.clone().startOf('isoWeek');
    let fechaFin = fechaSeleccionada
      .clone()
      .endOf('isoWeek')
      .subtract(1, 'days');

    let dataFiltro: FiltroHistorialPedidoModel = {};
    dataFiltro.selectedObra = this.menu.filterFields.selectedObra;
    if (this.IsDia) {
      dataFiltro.fechaInicio = fechaSeleccionada;
      dataFiltro.fechaFin = fechaSeleccionada;
      this.mesInicio = this.getformatearFecha(
        fechaSeleccionada.format('YYYYMMDD')
      );
    } else {
      dataFiltro.fechaInicio = fechaInicio;
      dataFiltro.fechaFin = fechaFin;
      this.mesInicio = this.getFormattedDateMonthYear(
        fechaSeleccionada.format('YYYYMMDD')
      );
    }
    dataFiltro.selectedEstado = this.menu.filterFields.selectedEstado;
    this.eventoFiltrar(dataFiltro);
  }

  onChangeTipoFiltro(event: any) {
    let fechaSeleccionada: moment.Moment = moment();
    let dataFiltro: FiltroHistorialPedidoModel = {};

    if (this.selectTipoFiltro == 'Semana') {
      this.IsSemana = true;
      this.IsDia = false;
      this.mesInicio = this.getFormattedDateMonthYear(
        fechaSeleccionada.format('YYYYMMDD')
      );

      let fechaInicio = fechaSeleccionada.clone().startOf('isoWeek');
      let fechaFin = fechaSeleccionada
        .clone()
        .endOf('isoWeek')
        .subtract(1, 'days');
      dataFiltro.selectedObra = this.menu.filterFields.selectedObra;
      dataFiltro.fechaInicio = fechaInicio;
      dataFiltro.fechaFin = fechaFin;

      this.eventoFiltrar(dataFiltro);
    } else {
      this.IsSemana = false;
      this.IsDia = true;

      this.mesInicio = this.getformatearFecha(
        fechaSeleccionada.format('YYYYMMDD')
      );
      let fechaInicio = fechaSeleccionada;
      let fechaFin = fechaSeleccionada;

      dataFiltro.selectedObra = this.menu.filterFields.selectedObra;
      dataFiltro.fechaInicio = fechaInicio;
      dataFiltro.fechaFin = fechaFin;

      this.eventoFiltrar(dataFiltro);
    }
  }

  prevDay() {
    debugger
    let fechaSeleccionada: moment.Moment = this.menu.filterFields.fechaInicio;
    let dataFiltro: FiltroHistorialPedidoModel = {};
    let fechaInicio = fechaSeleccionada.clone().subtract(1, 'day');
    let fechaFin = fechaSeleccionada.clone().subtract(1, 'day');

    dataFiltro.selectedObra = this.menu.filterFields.selectedObra;
    dataFiltro.fechaInicio = fechaInicio;
    dataFiltro.fechaFin = fechaFin;
    this.mesInicio = this.getformatearFecha(fechaInicio.format('YYYYMMDD'));
    dataFiltro.selectedEstado = this.menu.filterFields.selectedEstado;
    this.eventoFiltrar(dataFiltro);
  }

  nextDay() {
    let fechaSeleccionada: moment.Moment = this.menu.filterFields.fechaInicio;
    let dataFiltro: FiltroHistorialPedidoModel = {};
    let fechaInicio = fechaSeleccionada.clone().add(1, 'day');
    let fechaFin = fechaSeleccionada.clone().add(1, 'day');

    dataFiltro.selectedObra = this.menu.filterFields.selectedObra;
    dataFiltro.fechaInicio = fechaInicio;
    dataFiltro.fechaFin = fechaFin;
    this.mesInicio = this.getformatearFecha(fechaInicio.format('YYYYMMDD'));
    dataFiltro.selectedEstado = this.menu.filterFields.selectedEstado;
    this.eventoFiltrar(dataFiltro);
  }

  prevWeek() {
    let fechaSeleccionada: moment.Moment = this.menu.filterFields.fechaInicio;
    let fechaSeleccionadaFin: moment.Moment = this.menu.filterFields.fechaFin;
    let dataFiltro: FiltroHistorialPedidoModel = {};
    let fechaInicio = fechaSeleccionada.clone().subtract(1, 'week');
    let fechaFin = fechaSeleccionadaFin.clone().subtract(1, 'week');

    dataFiltro.selectedObra = this.menu.filterFields.selectedObra;
    dataFiltro.fechaInicio = fechaInicio;
    dataFiltro.fechaFin = fechaFin;
    this.mesInicio = this.getFormattedDateMonthYear(
      fechaInicio.format('YYYYMMDD')
    );
    dataFiltro.selectedEstado = this.menu.filterFields.selectedEstado;
    this.eventoFiltrar(dataFiltro);
  }

  nextWeek() {
    let fechaSeleccionada: moment.Moment = this.menu.filterFields.fechaInicio;
    let fechaSeleccionadaFin: moment.Moment = this.menu.filterFields.fechaFin;
    let dataFiltro: FiltroHistorialPedidoModel = {};
    let fechaInicio = fechaSeleccionada.clone().add(1, 'week');
    let fechaFin = fechaSeleccionadaFin.clone().add(1, 'week');

    dataFiltro.selectedObra = this.menu.filterFields.selectedObra;
    dataFiltro.fechaInicio = fechaInicio;
    dataFiltro.fechaFin = fechaFin;
    this.mesInicio = this.getFormattedDateMonthYear(
      fechaInicio.format('YYYYMMDD')
    );
    dataFiltro.selectedEstado = this.menu.filterFields.selectedEstado;
    this.eventoFiltrar(dataFiltro);
  }
}
