import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Transportistas';
  private config: {version: string}; 
  /**
   *
   */
  constructor(private titleService: Title,private httpClient: HttpClient) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit() {
   /* this.config = require('./../assets/config.json'); 
    console.log(this.config.version);


    const headers = new HttpHeaders()
    .set('Cache-Control', 'no-cache')
    .set('Pragma', 'no-cache'); 

    this.httpClient.get<{version: string}>('/assets/config.json')
    .subscribe(config => {
      console.log(config);
      if (config.version !== this.config.version) {
        this.httpClient
        .get("", { headers, responseType: "text" })
        .subscribe(() => location.reload());
      }
    }); 
    */
  }
}
