import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/services/auth';
import { OrderService } from 'src/app/services/pedidos/pedidos.service';

@Component({
  selector: 'app-Login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  btnDisable = true;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  loggedIn: boolean;
  fieldTextType: boolean;
  etapa = 0;
  enumEtapa = {
    Login: 0,
    SelecionEmpresa: 1,
  };

  /*vCard: string = `
BEGIN:VCARD
VERSION:3.0
FN:John Doe
TEL:+1234567890
END:VCARD
  `;*/
  vCard = `MECARD:N:xxxxx;TEL:123;;`;

  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private alertService: AlertService,
    private orderService: OrderService
  ) {
    if (this.accountService.userValue) {
      this.orderService.setValues(this.accountService.userValue.KEY);
      this.loggedIn = true;
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      rut: ['', Validators.required],
      password: ['', Validators.required],
      rutEmp: [''],
    });

    this.returnUrl =
      this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    if (this.accountService.userValue !== null) {
        this.orderService.setValues(this.accountService.userValue.KEY);
        this.router.navigate([this.returnUrl]);
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    if (this.etapa == this.enumEtapa.SelecionEmpresa) {
      if (this.f.rutEmp.value.length > 1) {
        this.onIniciarSessionEmpresa();
      } else {
        this.onDisableButton();
      }
    } else {
      this.loading = true;
      this.accountService
        .loginPolpaico(
          this.f.rut.value.replace(/[.]/g, '').replace(/[-]/g, ''),
          this.f.password.value
        )
        .pipe(first())
        .subscribe(
          (data) => {
            if (data.length === 0) {
              console.log('El usuario o contraseña son incorrectos');
              this.alertService.error(
                'El usuario o contraseña son incorrectos'
              );
              this.loading = false;
            } else {
              if (data.Estado) {
                this.etapa = this.enumEtapa.SelecionEmpresa;
              } else {
                  this.router.navigate([this.returnUrl]);
              }
            }
          },
          (error) => {
            console.log(
              error
            );
            this.alertService.error(
              'Actualmente se encuentra en mantención. Intente más tarde'
            );
            this.loading = false;
          }
        );
    }
  }
  onIniciarSessionEmpresa() {
    this.accountService
      .loginConRutEmpresaPolpaico(
        this.f.rut.value.replace(/[.]/g, '').replace(/[-]/g, ''),
        this.f.password.value,
        this.f.rutEmp.value.replace(/[.]/g, '').replace(/[-]/g, '')
      )
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.length === 0) {
            this.alertService.error('El Rut de la empresa es incorrecto.');
            this.loading = false;
          } else {
            this.router.navigate([this.returnUrl]);
          }
        },
        (error) => {
          this.alertService.error('Rut empresa inválido.');
          this.loading = false;
        }
      );
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  onDisableButton() {
    this.btnDisable = this.f.rutEmp.value.length == 0;
  }
}
