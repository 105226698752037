import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-confirmar-cerrar-session',
  templateUrl: './modal-confirmar-cerrar-session.component.html',
  styleUrls: ['./modal-confirmar-cerrar-session.component.css']
})
export class ModalConfirmarCerrarSessionComponent implements OnInit {
  @Input() classModal;
  @Output() enventoCerrarModalPadre =new EventEmitter<void>();
  @Output() enventoCerrarSessionPadre =new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }
  eventoCerrarModal()
  {
    this.enventoCerrarModalPadre.emit();
  }
  eventoCerrarSession()
  {
    this.enventoCerrarSessionPadre.emit();
  }
}
