import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { CertificadosModel } from "src/app/shared/models/CertificadosModel";
import { ContratosModel } from "src/app/shared/models/ContratosModel";
import { FacturasModel } from "src/app/shared/models/FacturasModel";
import { GuiaDespachoModel } from "src/app/shared/models/GuiaDespachoModel";
import { HistorialPedidosModel } from "src/app/shared/models/HistorialPedidosModel";
import { KpiResponse } from "src/app/shared/models/KpiModel";
import { SaldosModel } from "src/app/shared/models/SaldosModel";
import { TicketsResponse } from "src/app/shared/models/TicketsModels";
import { ListOf } from "src/app/shared/models/UserLoginModel.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })

export class GetDataService{
    private EndPointSaldos:string;
    private endpointCertificados:string;
    private endpointContratos:string;
    private endpointTracking:string;
    private endpointFacturas:string;
    private endpointGuias:string;
    private endpointTikect:string;
    private endpointKpi:string;
    private endpointHistorial:string;
constructor(private router: Router,
    private http: HttpClient) {
        this.endpointCertificados=environment.ResourcePolpaico +"certificados_det.aspx";;
        this.EndPointSaldos=environment.ResourceSaldoProductos;
        this.endpointContratos = environment.ResourcePolpaico + "contratos.aspx";
        this.endpointTracking = environment.ResourcePolpaico + "tracking.aspx";
        this.endpointFacturas=environment.ResourcePolpaico +"facturas.aspx";
        this.endpointGuias = environment.ResourcePolpaico + "documentos.aspx";
        this.endpointTikect = environment.ResourcePolpaico + environment.EPTickets;
        this.endpointHistorial = environment.ResourcePolpaico + environment.EPPedidosH;
        
}

 getSaldos(key:string) {
    return this.http.get<ListOf<SaldosModel>>(this.EndPointSaldos,
      {
        headers:
        {
          'Key': key
         
        }
      });
  }


  getCertificadosServicios(key:string) {
    return this.http.get<CertificadosModel>(this.endpointCertificados, { headers:{
      "Key": key
    } });
  }

  getContratos(key:string) {
    return this.http.get<ContratosModel>(this.endpointContratos,
      {
        headers:
        {
          'Key': key,
          'Fuente': 'CMD'
        }
      });
  }

  getDespachoRuta() {
    return this.http.get<any>(this.endpointTracking)
      .pipe(catchError(error => of({ 'unknownError': error })));
  }

  getFacturasServicios(key:string) {
    return this.http.get<FacturasModel>(this.endpointFacturas, { headers:{
      "Key": key
    } });
  }


  getGuiaServicios(key:string) {
    return this.http.get <GuiaDespachoModel> (this.endpointGuias, {
        headers: {
            "Key": key
        }
    });
  }


  getTickets(key:string) {
    return this.http.get<TicketsResponse>(this.endpointTikect, {
      headers: {
        Key: key,
        Action: "GET",
      },
    });
  }

  
  

  getHistorialPedidos(key:string): Observable<HistorialPedidosModel> {
    return this.http.get<any>(this.endpointHistorial,{ headers:{
      "Key": key
    }})
      .pipe(catchError(error => of({ 'unknownError': error })));
  }




}
  