import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-modal-call-to-action',
  templateUrl: './modal-call-to-action.component.html',
  styleUrls:['./modal-call-to-action.component.css']
})
export class ModalCallToActionComponent implements OnInit {
  @Input() classModal;
  @Output() enventoCerrarModalPadre =new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }
  eventoCerrarModal()
  {
    this.enventoCerrarModalPadre.emit(true);
  }
}
