<header
  class="header-user"
  style="
    height: 75px;
    padding: 15px 1rem;
    display: flex;
    justify-content: space-between;
  "
>
  <img
    style="cursor: pointer;"
    (click)="toDashboard()"
    src="../../../assets/images/html/logo-polpaico.png"
    alt="logo"
    width="50"
    class="logo"
  />

  <div
    class="buttons"
    style="
      display: flex;
      align-items: center;
      position: relative !important;
      top: 0px;
      left: 0px;
      padding: 0px;
    "
  >
    <div
      (click)="openModalCall()"
      style="cursor: pointer"
      class="icon-hide phone"
      *ngIf="!isCemar"
    >
      Llamar
    </div>
    <div
      (click)="openModalperfil()"
      style="cursor: pointer; margin-left: 20px"
      class="icon-hide user"
      [ngClass]="statusCss"
    >
      Perfil
    </div>
  </div>
</header>
<div
  style="
    background-color: #f2f2f2;
    color: #0033a1;
    display: flex;
    padding: 1rem;
    align-content: center;
    position: sticky;
    top: 75px;
    z-index: 2;
  "
  *ngIf="isMixer"
>
  <mat-icon *ngIf="!isInDashboard()" (click)="goBack()" style="cursor: pointer"
    >keyboard_arrow_left</mat-icon
  >
  <div style="margin-left: 1rem; display: flex; align-items: center">
    <h6 style="font-weight: 700; margin: 0px">
      {{ menuService.tilteMenu }}
    </h6>
  </div>
</div>
<app-modal-call-to-action
  [classModal]="clasModalCall"
  (enventoCerrarModalPadre)="cerrarModalCall()"
></app-modal-call-to-action>
<app-modal-perfil
  [classModal]="clasModalPerfil"
  (enventoCerrarModalPadre)="cerrarModalperfil()"
></app-modal-perfil>
<router-outlet></router-outlet>
<div *ngIf="!isMixer">
  <app-dashboard-bottom></app-dashboard-bottom>
</div>
