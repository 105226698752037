import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AccountService, AlertService } from 'src/app/services/auth';
import { MenuService } from 'src/app/services/menu/menu-service';
import { ViajesService } from 'src/app/services/viajes-cli/viajes.service';
import { Constantes } from 'src/app/shared/Constantes/constantes';
import { ComboType } from 'src/app/shared/models/ComboType';

@Component({
  selector: 'app-historial-programacion-seguimiento',
  templateUrl: './historial-programacion-seguimiento.component.html',
  styleUrls: ['./historial-programacion-seguimiento.component.css'],
})
export class HistorialProgramacionSeguimientoComponent implements OnInit {
  lstObras: ComboType[] = new Array();
  arrivalOrder = [
    'Primero',
    'Segundo',
    'Tercero',
    'Cuarto',
    'Quinto',
    'Sexto',
    'Séptimo',
    'Octavo',
    'Noveno',
    'Décimo',
    'Undécimo',
    'Duodécimo',
    'Decimotercero',
    'Decimocuarto',
    'Decimoquinto',
    'Decimosexto',
    'Decimoséptimo',
    'Decimoctavo',
    'Decimonoveno',
    'Vigésimo',
  ];
  classFiltro = Constantes.classCierreModal;
  obraSelecionada = '';
  codigoObraSelecionada = '0';

  isAccordionOpen = false;
  isAccordionOpenDet = false;
  center = { lat: 51.505, lng: -0.09 };
  zoom = 12;

  PasoSeguimiento = false;
  PasoRechazo = false;
  PasoComentarios = false;
  PasoFirma = false;

  public id: string = '';
  public fechaPedido: string = '';
  public PasoOpen: string = '';
  public foundItem: boolean = false;

  ListaPedidos = [];

  pedidosEntregado = 0;
  pedidosTransito = 0;
  pedidoPendiente = 0;
  TotalPedido = 0;

  mostrarMapaPedido={
      viewMapa:false,
      viewImg:false
  };
  safeSrc: any;

  PedidoAceptar: any;

  isAccordionOpenTrucks = false;
  selectedTruck: any = null;
  indexTruck = 0;
  orderSelected: any;

  @ViewChild('contenidoModal') contenidoModal!: TemplateRef<any>;
  @ViewChild('modalInformation') modalInformation!: TemplateRef<any>;
  @ViewChild('modalGuia') modalGuia!: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;
  CantRetorno = 0;

  mensajePedido = '';

  isAnonimo: boolean = false;
  urlGuia="";
  zoomLevel: number = 1;

  constructor(
    private menu: MenuService,
    private route: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer,
    private ViajesServ: ViajesService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private accountService: AccountService,
  ) {
    this.setZoomLevel();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setZoomLevel();
  }

  setZoomLevel() {
    if (window.innerWidth <= 768) {
      this.zoomLevel = 0.75; // Nivel de zoom para móviles
    } else {
      this.zoomLevel = 1; // Nivel de zoom para escritorio
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params?.id;
      this.fechaPedido = params?.fecha;
      this.PasoOpen = params?.paso;
      const tkt = this.route.snapshot.queryParams.tkt;
      const key = this.route.snapshot.queryParams.key;
      this.menu.btnPrevius = true;
      this.menu.tilteMenu = 'Nº pedido ' + this.id;
      this.pedidos(this.id,this.fechaPedido, tkt, key);

      this.obraSelecionada = this.menu.productoSelecionado;
    });

    if (!this.id) {
      this.foundItem = false;
      return;
    }
  }

  pedidos(numeroPedido: string, fechaPedido: string, tkt?: string, key? :string) {
    let HistoryPedido = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('listaGuias'))
    );

    if (HistoryPedido.value != null && HistoryPedido.value.length > 0) {
      let FechaForemateada = this.formatearFecha(fechaPedido);
      this.ListaPedidos = HistoryPedido.value.filter(
        (pedido) =>
          pedido.NUMERO_PEDID_H === numeroPedido &&
          pedido.FECHA_PED_DATE_H == FechaForemateada
      );
      this.ListaPedidos.forEach((pedido) => {
        pedido['isAccordionOpen'] = pedido.STATUS_H != '10' ? true : false;

        pedido.STATUS_H = this.statusMajor(
          pedido.MX_ULTIMO_ESTADO_MANUAL_H,
          pedido.STATUS_H
        );
      });
      this.processPedido();
    }
    if (this.ListaPedidos.length === 0)
      this.getDataFromServer(numeroPedido, fechaPedido, tkt, key);
  }

  validateReturn(pedido: any): number {
    if (pedido.MX_M3_RETORNO != null && pedido.MX_M3_RETORNO != '')
      return parseFloat(pedido.MX_M3_RETORNO);
    return 0;
  }

  processPedido() {
    if (this.ListaPedidos.length > 0) {
      this.ListaPedidos.sort((a, b) => {
        return a.NROCARGA_H - b.NROCARGA_H;
      });
      this.ListaPedidos.forEach((pedido) => {
        pedido.isAccordionOpen = true;
        pedido.STATUS_H = this.statusMajor(
          pedido.MX_ULTIMO_ESTADO_MANUAL_H,
          pedido.STATUS_H
        );
      });
      this.pedidosEstados();
      if (this.PasoOpen == '4') this.PasoFirma = true;
      else if (this.PasoOpen == '3') this.PasoComentarios = false;
      else this.PasoSeguimiento = true;

      this.selectTruck(this.ListaPedidos[0]);
      this.isAccordionOpenTrucks = false;
    }
  }

  getDataFromServer(numeroPedido: string, fechaPedido: string, tkt?: string, key?: string) {
    this.ViajesServ.GetDataOrderByQr({
      key: key,
      id1: tkt,
    }).subscribe((result) => {
      if (result?.unknownError) return;
      if (result?.datos) {
        this.ListaPedidos = result?.datos[0].VIAJESH;
        this.PedidoAceptar = this.ListaPedidos[0];
       
        if (this.PedidoAceptar && this.PedidoAceptar.MX_CLI_FIRMA != '') {
          this.dialogRef = this.dialog.open(this.modalInformation);
          if (this.accountService.userValue)
            this.router.navigate(['/dashboard']);
          else this.router.navigate(['/login']);
        }else{
          this.dialogRef = this.dialog.open(this.modalGuia);
        }
        this.urlGuia=this.PedidoAceptar.PDF_GUIA_H
        
        if (this.accountService.userValue) {
          if (!this.haveAccess(result?.datos[0].VIAJESH[0].CODIGO_PROYE_H)) {
            this.router.navigate(['/dashboard']);
          }
        } else {
          this.isAnonimo = true;
        }

        this.processPedido();

        if (this.PedidoAceptar) {
          this.obraSelecionada =
            this.menu.productoSelecionado == undefined
              ? this.PedidoAceptar.NOMBRE_PROYECTO_H
              : '';
        }
      }
    });
  }

  haveAccess(proyectCode: string): boolean {
    const obra = this.accountService.userValue.OBRAS.find(
      (obra) => obra.OB_CODIGO == proyectCode
    );
    return obra != undefined;
  }

  pedidosEstados() {
    this.ListaPedidos.forEach((pedido) => {
      if (pedido.MX_MOTIVO_RECHAZO == '') {
        if (this.validateStatuSig(pedido.STATUS_H, '1'))
          this.pedidosEntregado += parseFloat(pedido.CANT_DESP_REAL_H);
        else if (pedido.STATUS_H === '1' || pedido.STATUS_H === '2')
          this.pedidoPendiente += parseFloat(pedido.CANT_DESP_REAL_H);
        else this.pedidosTransito += parseFloat(pedido.CANT_DESP_REAL_H);
      }
    });

    this.TotalPedido =this.ListaPedidos[0].CANTIDAD_PROGRAMADA_H;
  }

  mostrarMapa(pedido: any) {
    
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.validateStatusMap(pedido));
  }

  validateStatusMap(pedido):string{

    if (parseInt(pedido.STATUS_H)>6) {
      this.mostrarMapaPedido.viewImg = true;
      this.mostrarMapaPedido.viewMapa = false;
      return "../assets/images/html/banner_app_mixer_pedido_entregado.svg"
    }else{
      this.mostrarMapaPedido.viewImg = false;
      this.mostrarMapaPedido.viewMapa = true;
      return pedido.URLMAP;
    }

   
  }

  toggleAccordionDet() {
    this.isAccordionOpenDet = !this.isAccordionOpenDet;
  }

  toggleAccordion(pedido: any) {
    pedido.isAccordionOpen = !pedido.isAccordionOpen;
  }

  toggleAccordionTrucks() {
    this.isAccordionOpenTrucks = !this.isAccordionOpenTrucks;
  }

  selectTruck(truck: any) {
    this.toggleAccordionTrucks();
    this.indexTruck = this.ListaPedidos.findIndex(
      (pedido) => pedido.NROCARGA_H === truck.NROCARGA_H
    );
    this.ListaPedidos.forEach((pedido) => {
      if (pedido.NUMERO_PEDID_H === truck.NUMERO_PEDID_H) {
        //this.mostrarMapa(pedido);
        this.selectedTruck = truck;
        this.isAccordionOpen = true;
      }
    });
  }

  RechazarCamion(truck: any) {
    this.closeModal();
    this.PasoSeguimiento = false;
    this.PasoRechazo = true;
    this.orderSelected = {
      orderNumber: truck.NUMERO_PEDID_H,
      tkt: truck.TKT_H,
    };
  }

  AceptarPedido(pedido: any) {
    
    this.closeModal();
    const returnM3 = this.validateReturn(pedido);
    if (returnM3 > 0) {
      this.CantRetorno = returnM3;
      this.onOpenModal();
    }

    this.PasoSeguimiento = false;
    this.PasoRechazo = false;
    this.PasoComentarios = true;
    this.PedidoAceptar = pedido;
  }

  RegresarSeguimiento() {
    this.dialogRef = this.dialog.open(this.modalGuia);
    this.PasoSeguimiento = false;
    this.PasoComentarios = false;
    this.PasoRechazo = false;
  }

  formatearFecha(fechaOriginal) {
    if (fechaOriginal.length === 8) {
      const year = fechaOriginal.slice(0, 4);
      const month = fechaOriginal.slice(4, 6);
      const day = fechaOriginal.slice(6, 8);

      return `${year}-${month}-${day}`;
    } else {
      console.error('Formato de fecha original incorrecto');
    }
  }

  getStatusInfo(estadoNumero: string) {
    let estado: string;
    let color: string;
    let Backcolor: string;

    switch (estadoNumero) {
      case '1':
        estado = 'Asignado';
        color = '#14598c';
        Backcolor = '#a8cae3';
        break;
      case '2':
        estado = 'Cargando';
        color = '#e96921';
        Backcolor = '#f8ceb7';
        break;
      case '3':
        estado = 'A obra';
        color = '#F1AE2F';
        Backcolor = '#fbe7c1';
        break;
      case '4':
        estado = 'En obra';
        color = '#0033A1';
        Backcolor = '#b3c2e3';
        break;
      case '5':
        estado = 'Descargando';
        color = '#5a108a';
        Backcolor = '#c09ad9';
        break;
      case '6':
        estado = 'Fin descarga';
        color = '#007a47';
        Backcolor = '#b3d8c8';
        break;
      case '0':
        estado = 'Fin descarga';
        color = '#007a47';
        Backcolor = '#b3d8c8';
        break;
      case '7':
        estado = 'Fin descarga';
        color = '#007a47';
        Backcolor = '#b3d8c8';
        break;
      case '8':
        estado = 'Fin descarga';
        color = '#007a47';
        Backcolor = '#b3d8c8';
        break;
      case '9':
        estado = 'Fin descarga';
        color = '#007a47';
        Backcolor = '#b3d8c8';
        break;
      case '10':
        estado = 'Fin descarga';
        color = '#007a47';
        Backcolor = '#b3d8c8';
        break;
      default:
        estado = 'Desconocido';
        color = '#061676';
        Backcolor = '#8186a4';
        break;
    }

    return { estado, color, Backcolor };
  }

  GuardarComentarios(data) {
    this.ViajesServ.UpdateComentariosGuiasCliente(data).subscribe((result) => {
      if (result?.unknownError) {
        this.alertService.error('No se pudo actualizar el pedido.');
        return;
      }
      if (result?.Estado == 'Insertado') {
        this.PasoComentarios = false;
        this.PasoFirma = true;
      } else {
        this.alertService.error('No se pudo actualizar el pedido.');
        return;
      }
    });
  }

  abrirEnNuevaPestana(url: string): void {
    window.open(url, '_blank');
  }

  onOpenModal() {
    this.dialogRef = this.dialog.open(this.contenidoModal, {
      maxWidth: '90%',
    });
  }

  onCierrenModal() {
    this.dialogRef.close();
  }

  validateStatuSig(status, isDigital): boolean {
    if (
      (status === '0' ||
        status === '5' ||
        status === '6' ||
        status === '7' ||
        status === '8' ||
        status === '9') &&
      isDigital == '1'
    ) {
      return true;
    } else {
      return false;
    }
  }

  IsSigOrReject(pedido): string {
    if (pedido.MX_CLI_FIRMA.length > 0) return 'Esta guía ya está firmada';
    else if (pedido.MX_MOTIVO_RECHAZO != '') return 'Esta guía está rechazada';

    return '';
  }

  statusMajor(statusManuel, statusCommand): string {
    let statusCommandNumber = statusCommand == '' ? 0 : parseInt(statusCommand);
    let statusManualNumber = statusManuel == '' ? 0 : parseInt(statusManuel);
    let valueMajor = 0;

    if (statusManualNumber > statusCommandNumber) {
      valueMajor = statusManualNumber;
    } else {
      valueMajor = statusCommandNumber;
    }

    return valueMajor.toString();
  }

  getOrderTruck(index: number) {
    return this.arrivalOrder[index];
  }

  isReject(pedido: any): boolean {
    return pedido.MX_MOTIVO_RECHAZO != '';
  }

  getStimatedTime(pedido: any): string {
    let seconds = +pedido.HORA_TRES_H.split(':')[2];
    let minutes = +pedido.HORA_TRES_H.split(':')[1] + +pedido.TIEMPO_DE_VIAJE_H;
    let hours = +pedido.HORA_TRES_H.split(':')[0];
    if (minutes >= 60) {
      minutes -= 60;
      hours++;
    }
    if (hours >= 24) {
      hours -= 24;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  closeModal() {
    this.dialogRef.close();
  }


}
