<div *ngIf="!loaded">Cargando datos...</div>

<div
  class="dashboard pt-md-5 content-desktop-card dashboard-mixer"
>
  
  <div class="dashboard-buttons">
    <nav>
      <ul
        class="list-dashboard center list-dashboard-mixer"
      >
        <li *appPerfil="perfilView.itemProgramacion" class="despachos">
          <a
            [routerLink]="['/historialprogramacion']"
            (click)="updateSelectWork()"
            ><i class="icon icon-calendar"></i>Despachos<i
              class="icon angle-right"
            ></i>
          </a>
        </li>

        

        <li
          *appPerfil="perfilView.ItemPedidosPorDespachar"
          class="hacer-programacion"
        >
          <a [routerLink]="['/pedidospendiente']"
            ><i class="icon icon-despachar"></i>Pedidos por despachar<i
              class="icon angle-right"
            ></i>
          </a>
        </li>
        <li *appPerfil="perfilView.itemHistorialMixer" class="despachos">
          <a [routerLink]="['/pedidosEntregados']"
            ><i class="icon icon-historial-pedido"></i>Historial de pedidos<i
              class="icon angle-right"
            ></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>

  <div class="modal-overlay"  (click)="closeModal()" *ngIf="showModal">
    <div class="modal-container">
      <span class="modal-close" (click)="closeModal()">X</span>
      <h6 class="modal-title">Estado activo</h6>
      <p class="modal-text">
        Recuerda que el estado se mantendrá en
        <span class="modal-title"> 2 horas</span> de tiempo
        <span class="modal-title">activo</span>. Si no hay actividad, cambiará a
        <span class="modal-title">ausente, y no recibirás pedidos.</span>
      </p>
      <img
        src="/assets/images/icons/userActivo.svg"
        style="color: red"
        alt="Usuario"
        class="modal-image"
      />
      <button class="btn btn-primary" (click)="closeModal()">Aceptar</button>
    </div>
  </div>


</div>
