
<button class="floating-button" (click)="openModal()" *ngIf="!IsMovil"></button>

<button class="floating-button-movil" (click)="openModal()" *ngIf="IsMovil"></button>

<div class="modal" [ngClass]="{'open': showModal}">
  <div class="modal-content"  [ngClass]="{'modal-content-ajustado':IsProgramacion, 'modal-content-right':!IsMovil}">
    <div class="modal-header" >
      <h6 *ngIf="!IsCompleteEncuesta">¿{{Pregunta}}?</h6>
      <span class="close" (click)="closeModal()">&times;</span>
    </div>
    
    <div class="modal-body">
      <div class="rating-stars" *ngIf="!IsCompleteEncuesta">
        <span class="star" (click)="selectStars(1)" [ngClass]="{'selected': selectedStars >= 1 }">&#9733;</span>
        <span class="star" (click)="selectStars(2)" [ngClass]="{'selected': selectedStars >= 2 }">&#9733;</span>
        <span class="star" (click)="selectStars(3)" [ngClass]="{'selected': selectedStars >= 3 }">&#9733;</span>
        <span class="star" (click)="selectStars(4)" [ngClass]="{'selected': selectedStars >= 4 }">&#9733;</span>
        <span class="star" (click)="selectStars(5)" [ngClass]="{'selected': selectedStars >= 5 }">&#9733;</span>
      </div>
      
      <div *ngIf="!IsCompleteEncuesta">
        <ng-container *ngIf="selectedStars >= 1 && selectedStars <= 3" >
            <h6>Selecciona una o más categorías:</h6>
            <div class="categories">
              <button class="category" (click)="toggleCategory(category)" [ngClass]="{'selected': category.selected}" *ngFor="let category of categories">{{ category.name }}</button>
            </div>

            
          </ng-container>
          
          <ng-container *ngIf="selectedStars >= 1 && selectedStars <= 5" >
            <h6>Déjanos tu comentario (Opcional):</h6>
            <textarea [(ngModel)]="comment"></textarea>
          </ng-container>
      </div>
      
      <div *ngIf="IsCompleteEncuesta" class="ComentariosFinales">
        <h4>Gracias por tu respuesta!</h4>
        <img src="/assets/images/html/GraciasEncuesta.png" width="100" height="100" alt="Gracias">
      </div>
    </div>
    
    <div class="modal-footer" *ngIf="IsVisible && !IsCompleteEncuesta">
      <button class="btn btn-primary btn-filtrar" (click)="sendFeedback()">Enviar</button>
    </div>
  </div>
</div>
