<div class="dashboard" style="text-align: center">
  <div class="card-box card-perfil">
    <div class="card-header-perfil">
      <div class="row-photo">
        <div class="img-perfil">
          <img
            [src]="'/assets/images/html/photo-perfil.png'"
            width="40"
            alt=""
          />
        </div>

        <div class="name-perfil">
          <div class="title-blue-700">{{ dataUsusario.NOMBRE_USUARIO }}</div>
          <div class="text-size-sm">{{ dataUsusario.RUT_USUARIO | rut }}</div>
        </div>
      </div>
    </div>
    <!--end header-->

    <div class="card-body-perfil">
      <dl>
        <dd>Nombre <span *ngIf="!isMixer">&nbsp;Empresa</span></dd>
        <dt>{{ dataUsusario.NOMBRE_EMPRESA }}</dt>
        <dd>RUT</dd>
        <dt>{{ dataUsusario.RUT_EMPRESA | rut }}</dt>
        <dd>E-mail</dd>
        <dt>{{ dataUsusario.MAIL_USUARIO }}</dt>
        <dd>Teléfono</dd>
        <dt>{{ dataUsusario.FONO_USUARIO }}</dt>

        <div class="signature-container" *ngIf="dataUsusario.firma.length==0 && !isMixer">
          <p class="signature-text">¡Puedes guardar aquí tu firma y usarla más adelante para firmar tus guías de despacho!</p>
        </div>
        
      </dl>
    </div>
  </div>
  <!--end card-->

  <div class="buttons button-sticky-bottom">
    <button
      class="btn btn-primary btn-editar-perfil btn-l-md"
      (click)="onChangeEdicion()"
      type="button"
    >
      Editar Perfil
    </button>
  </div>
</div>
