import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/auth';
import { OrderService } from 'src/app/services/pedidos/pedidos.service';
import { RejectReason } from 'src/app/shared/models/RejectReason';

@Component({
  selector: 'app-rechazo-camion',
  templateUrl: './rechazo-camion.component.html',
  styleUrls: ['./rechazo-camion.component.css'],
})
export class RechazoCamionComponent implements OnInit {
  rejectForm = new FormGroup({
    rejectReasons: new FormControl(''),
    rejectObservations: new FormControl(''),
  });

  @ViewChild('fileInput') fileInput: ElementRef;
  images: { file: File; preview: string }[] = [];
  @Output() returnButton = new EventEmitter<any>();
  @Output() confirmButton = new EventEmitter<any>();

  @ViewChild('modalContent') modalContent!: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;

  @Input() order: any;
  rejectReasons: RejectReason[] = [];
  rejectReasonsSelected: RejectReason[] = [];
  isConfirm = false;
  isSelectOpen = false;

  constructor(
    private route: ActivatedRoute,
    private readonly orderService: OrderService,
    private dialog: MatDialog,
    private accountService: AccountService,
    private scrollStrategyOptions: ScrollStrategyOptions,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.orderService.getRejectReasons().subscribe((data) => {
      this.rejectReasons =this.transformData(data).filter(x=>x.neg==this.order.neg);
    });
    this.isSelectOpen = true;
  }

  private transformData(data: any): RejectReason[] {
    return Object.keys(data).reduce((acc, neg) => {
      const entries = Object.entries(data[neg]).map(([id, description]) => 
        new RejectReason(Number(id), description.toString(), neg)
      );
      return acc.concat(entries);
    }, []);
  }

  openFileSelector() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!this.isValidFile(file)) continue;

      // Leer la imagen como una URL de datos para la previsualización
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.images.push({ file, preview: e.target.result });
      };
      reader.readAsDataURL(file);
    }

    // Limpiar el input file para permitir seleccionar el mismo archivo en futuras cargas
    this.fileInput.nativeElement.value = '';
  }

  isValidFile(file: File): boolean {
    const allowedExtensions = ['.jpg', '.jpeg', '.png'];
    const fileNameParts = file.name.split('.');
    const fileExtension = `.${fileNameParts[fileNameParts.length - 1]}`;
    return allowedExtensions.includes(fileExtension.toLowerCase());
  }

  closeModal() {
    this.dialogRef.close();
  }

  confirmModal() {
    this.dialogRef = this.dialog.open(this.modalContent, {
      maxWidth: '90%',
      scrollStrategy: this.scrollStrategyOptions.noop(),
    });
  }

  submit() {
   
    const files = this.images.map((image) => image.file);
    const reasons = this.rejectReasonsSelected
      .map((reason) => reason.description)
      .toString();
    this.orderService
      .setRejectValues(
        this.order.tkt.toString(),
        this.order.orderNumber.toString(),
        reasons,
        this.rejectForm.value.rejectObservations,
        files,
        true,
        '' 
      )
      .subscribe((data) => {
        this.isConfirm = true;
        this.confirmButton.emit();
      });
  }

  ok() {
    this.closeModal();
    this.router.navigate(['/pedidospendiente']);
  }

  goBack() {
    this.returnButton.emit();
  }

  openSelect() {
    this.isSelectOpen = !this.isSelectOpen;
  }

  clickReason(reason: RejectReason) {
    if (this.rejectReasonsSelected.includes(reason)) this.removeReason(reason);
    else this.selectReason(reason);
  }

  selectReason(reason: RejectReason) {
    this.rejectReasonsSelected.push(reason);
  }

  removeReason(reason: RejectReason) {
    this.rejectReasonsSelected = this.rejectReasonsSelected.filter(
      (item) => item.id !== reason.id
    );
  }

  isSelected(reason: RejectReason) {
    return this.rejectReasonsSelected.includes(reason);
  }

  isDisabled() {
    return this.rejectReasonsSelected.length === 0;
  }

  removeImage(image: any) {
    const index = this.images.indexOf(image);
    if (index !== -1) {
      this.images.splice(index, 1);
    }
  }
}
