<div class="smaller-container mt-3">
  <div class="box">
    <div class="div-title">
      <label class="text-color-label-title mb-2 mb-sm-0"
        >¡Ayúdanos a mejorar!</label
      >
    </div>

    <h6>{{ Pregunta }}</h6>

    <div
      *ngFor="let category of categories; let i = index"
      class="rating-stars mb-2 mb-sm-0"
    >
      <label class="text-color-label-desc mb-3 mb-sm-0">{{ category }}</label
      ><br />
      <div
        *ngFor="let star of [1, 2, 3, 4, 5]"
        class="col-icon-img"
        (click)="selectStars(i, star)"
        [ngClass]="{
          'icon-uncheck-start': selectedStars[i] < star,
          'icon-check-start': selectedStars[i] >= star
        }"
      ></div>
    </div>

    <div>
      <ng-container>
        <h6 class="text-color-label-title">
          ¿Quieres dejarnos un comentario adicional?
        </h6>
        <textarea class="form-control" [(ngModel)]="comment"></textarea>
      </ng-container>
    </div>

    <div *ngIf="IsCompleteEncuesta" class="ComentariosFinales">
      <h4>Gracias por tu respuesta!</h4>
      <img
        src="/assets/images/html/GraciasEncuesta.png"
        width="100"
        height="100"
        alt="Gracias"
      />
    </div>
  </div>

  <div
    class="d-block d-sm-flex justify-content-center align-items-center mt-3 mb-3 mb-sm-0"
  >
    <button
      class="btn btn-primary-volver col-12 col-sm-3 mx-sm-2 mb-3 mb-sm-0"
      [routerLink]="['/historialprogramacion']"
    >
      Ir a inicio
    </button>
    <button
      class="btn btn-primary btn-filtrar col-12 col-sm-3 mx-sm-2"
      [ngClass]="{ disabled: !isValidData() }"
      [disabled]="!isValidData()"
      (click)="sendFeedback()"
    >
      Enviar
    </button>
  </div>
</div>
