<div class="container-filtros">
  <div class="col-selected">
    <strong class="text-color-blue-primary">Obra: </strong>
    <strong>{{ selectObrasText }}</strong>
  </div>
  <div class="col-action wrapperBoxBoton download-option" *ngIf="Opcion">
    <div (click)="DescargarPage()" style="cursor: pointer" class="link-filtros">
      <i class="icon-hide download-file-blue-icon"></i
      ><strong>Descargar</strong>
    </div>
  </div>
  <div class="col-action" *ngIf="Opcion">
    <div
      style="cursor: pointer"
      class="link-filtros"
      (click)="eventoAbrirModal()"
    >
      <i class="icon-hide filter"></i><strong>Filtros</strong>
    </div>
  </div>
</div>

<div [class]="'filtros-busqueda lightbox-content  ' + ClaseModal">
  <div class="content lightbox-content-show">
    <div class="content-header">
      <div
        style="cursor: pointer"
        (click)="eventoCerrarModal()"
        class="icon-hide close-filters icon-close"
      >
      </div>
    </div>
    <!--filtros-->
    <div class="filtros-info">
      <h2 class="text-center">Filtros</h2>
      <p class="text-center">
        Aplica los filtros que encuentres necesarios para visualizar tus datos.
      </p>
      <div class="form-group">
        <mat-form-field class="mat-select" appearance="fill">
          <mat-label>OBRAS</mat-label>
          <mat-select [(value)]="selectObras">
            <div class="col-xl-12 p-1">
              <input
                class="form-control"
                style="border: 3px solid #f5f5f5"
                placeholder="Buscar "
                id="'InputSearch'"
                (keyup)="onKey($event.target.value)"
                #InputSearch
              />
            </div>
            <mat-option value="0">Selecciona la obra</mat-option>
            <mat-option
              *ngIf="obrasArray === null || !obrasArray.length"
              disabled
            >
              <div style="display: flex; align-items: center">
                <span style="flex: 1"
                  >Tu búsqueda no ha arrojado resultados...</span
                >
                <mat-spinner class="spinner" diameter="30"></mat-spinner>
              </div>
            </mat-option>
            <mat-option *ngFor="let obra of obrasArray" [value]="obra.id">
              {{ obra.id }} - {{ obra.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group" [formGroup]="formEdicion">
        <input
          type="text"
          formControlName="numeroBuscar"
          class="form-input mat-input"
          name="numeroBuscar"
          id="numeroBuscar"
          placeholder="Escribe tu búsqueda"
        />
        <label for="numeroBuscar" class="mat-label">Escribe tu búsqueda</label>
        <button type="button" class="btn-search">
          <i class="search"></i>
        </button>
        <div class="messages"></div>
        <div class="help-text">Busca por número de pedido, o producto.</div>

        <div class="row">
          <div class="col-12">
            <div
              class="form-group"
              (focus)="picker.open()"
              (click)="picker.open()"
            >
              <mat-form-field appearance="fill" style="width: 100% !important">
                <mat-label>Selecciona una fechas</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="start"
                  id="start"
                  placeholder="Fecha"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="cal d-none"></div>

      <div class="filtros-row filtros-row-estados">
        <h3>Estado</h3>
        <div class="row">
          <div class="col-programacion col-confirmado">
            <div
              style="cursor: pointer"
              [ngClass]="{ filtros: true, active: estadoSelected(5) }"
              (click)="estadoPedido = 5"
            >
              <span class="point positive confirm-circle"></span>
              <span class="filter-text">Confirmado</span>
            </div>
          </div>
          <div class="col-programacion col-confirmado">
            <div
              style="cursor: pointer"
              [ngClass]="{ filtros: true, active: estadoSelected(1) }"
              (click)="estadoPedido = 1"
            >
              <span class="point positive"></span>
              <span class="filter-text">Entregado</span>
            </div>
          </div>
          <!--end col-->
          <div class="col-programacion col-programado">
            <div
              style="cursor: pointer"
              [ngClass]="{ filtros: true, active: estadoSelected(2) }"
              (click)="estadoPedido = 2"
            >
              <span class="point pendiente"></span>
              <span class="filter-text">Programado</span>
            </div>
          </div>
          <!--end col-->
          <div class="col-programacion col-pendiente">
            <div
              style="cursor: pointer"
              [ngClass]="{ filtros: true, active: estadoSelected(4) }"
              (click)="estadoPedido = 4"
            >
              <span class="point negative"></span>
              <span class="filter-text">Anulado</span>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
          <div class="col-programacion col-todas text-sm-center">
            <div
              style="cursor: pointer"
              [ngClass]="{ filtros: true, active: estadoSelected(-1) }"
              (click)="estadoPedido = -1"
            >
              Todas
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons bottom-0">
    <button
      type="button"
      class="btn btn-primary btn-filtrar"
      [disabled]="!isSelectObrasValid()"
      (click)="onFiltrar()"
    >
      Filtrar
    </button>
  </div>
</div>
