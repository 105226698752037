import { Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cambiar-password-ok',
  templateUrl: './cambiar-password-ok.component.html'
})
export class CambiarPasswordOkComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
