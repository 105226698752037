<div [class]="'filtros-busqueda lightbox-content ' + classModal  ">
    <div class="content lightbox-content-show">
        <div class="content-header">
            <div href="" class="icon-hide close-call icon-close" style="cursor:pointer;" (click)="eventoCerrarModal()">
                Cerrar</div>
        </div>
        <!--share-->
        <div class="share-info">
            <div *ngIf="etapa.etapaDescargar">
                <h2 class="text-center">Compartir información</h2>
                <p class="text-center">{{mensajePregunta}}</p>
                <div class="buttons share">
                    <div class="row">

                        <div class="col-12 text-center">
                            <a (click)="saveToClipboard()" [routerLink]="" style="cursor: pointer;">
                                <i class="icon-hide">
                                    <span class="icon-span clipboard">clipboard</span>
                                </i>
                                Copiar al portapapeles
                            </a>
                        </div>
                    </div>
                    <div id="div2">
                        <img [src]="urlImagen" style="height: 0px; width: 0px;" class="imagen-asda" alt="">
                    </div>
                </div>
            </div>
            <div class="response" *ngIf="etapa.etapaOk">
                <h2 class="text-center">Copiado con éxito</h2>
                <p class="text-center">{{mensaje}}</p>

                <div class="number-ticket">
                    <i class="icon-form-ok"></i>
                </div>
            </div>
        </div>

    </div>
</div>