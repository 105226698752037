// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  host: 'https://dev-ecommerce.azurewebsites.net/',//'https://dev-ecommerce.azurewebsites.net/',
  ResourceServer: 'https://polpaicotest.rankeadictos.cl/api/',
  ApiVersion: 'v1',
  ResourceLogin: 'https://www.polpaicoconexion.cl/clientes/ws/login_qa.aspx',
  ResourcePassWordRecovery:'https://www.polpaicoconexion.cl/clientes/ws/recupera_clave.aspx',
  ResourceSaldoProductos:'https://www.polpaicoconexion.cl/clientes/ws/contratos.aspx',
  ResourcePolpaico: 'https://www.polpaicoconexion.cl/clientes/ws/',
  ApiEcomer:  'https://back-ecommerce-dev.azurewebsites.net/api/v2/EcommerceOrder',
  ResourceDespachoSeguimiento: 'https://www.polpaicoconexion.cl/clientes/map.aspx?code=',
  ApiRunPdf:'https://www.polpaicoconexion.cl/guias_cemento/guiaspd_setfirma.aspx',

  //endpoints
  EPPedidosHSet: 'pedidos_h_set_qa.aspx',
  EPPedidosH: 'pedidos_h.aspx',
  EPTickets: 'tickets_qa.aspx',
  PedidosLogs: 'pedidos_log.aspx',
  PedidosMasivo: 'pedidos_h_set_qados.aspx',
  SolicitarClave: 'solclave.aspx',
  Encuesta: 'mx_set_satisfaccion.aspx',
  pedidos: 'mx_viaje_qa.aspx',
  rejectReasons: 'mx_motivos_rechazo_qa.aspx',
  returnReasons: 'mx_motivos_retorno.aspx',
  setValues: 'mx_set_values_qa.aspx',
  setValuesCli: 'mx_set_values_qa.aspx',
  setStatus: 'mx_set_estado_qa.aspx',
  EncuestaCli:"set_satisfaccion.aspx"
};

/*
https://www.polpaicoconexion.cl/clientes/ws/pedidos_log.aspx
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
