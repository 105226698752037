<div class="container box mt-4">
  <form>
    <div class="form-group row">
      <div class="col-12">
        <label for="" class="text-title" style="font-size: 18px"
          >Firmar guía</label
        >
      </div>

      <div class="col-12"><br /></div>
      <div class="col-xl-12 p-0">
        <div class="col-xl-12">
          <input
            class="form-input mat-input form-control"
            [type]="'text'"
            name="NombreRecptor"
            [(ngModel)]="data.name"
            placeholder="Ingresa tu nombre"
          />
          <label class="mat-label label-input" for="Obs">Nombre</label>
        </div>
        <div class="col-12"><br /></div>

        <div class="row col-xl-12 d-flex flex-wrap align-items-center">
          <div class="col-xl-4 col-md-5 col-sm-5 col-6 mb-3">
            <input
              class="form-input mat-input form-control"
              [type]="'number'"
              minlength="7"
              maxlength="8"
              name="RutReceptor"
              [(ngModel)]="data.rut"
              placeholder="Ingresa tu RUT"
              (keydown)="onKeyDown($event)"
              pattern="^\d{6,7}[0-9kK]{1}$"
            />
            <label class="mat-label label-input" for="Obs">Rut</label>
          </div>
          <div class="col-xl-1 col-md-1 col-sm-1 col-1 mb-3" >
            <strong  >-</strong>
          </div>
          <div class="col-xl-3 col-md-4 col-sm-4 col-4 mb-3">
            <input
              class="form-input mat-input form-control"
              [type]="'text'"
              minlength="1"
              maxlength="1"
              name="dvRutReceptor"
              [(ngModel)]="data.dvrut"
              placeholder="Dv"
              (keydown)="onKeyDown($event)"
              pattern="^[0-9kK]{1}$"
              #dvRutReceptor="ngModel"
              style="width: 5ch;margin-left: -10%;"
              
            />
            <label class="mat-label label-input" for="Obs">DV</label>
          </div>
        </div>

        

        <div class="col-12"><br /></div>
        <div class="col-xl-12">
          <input
            class="form-input mat-input form-control"
            type="email"
            name="mailReceptor"
            [(ngModel)]="data.mail"
            placeholder="Ingresa tu correo"
            #mail="ngModel"
            [ngModelOptions]="{updateOn: 'blur'}"
            [pattern]="emailPattern"
          />
          <label class="mat-label label-input" for="Obs">Correo</label>
          <small class="text-info">Este mail lo utilizaremos para mandarte una copia de la guía de despacho firmada.</small>

          <div *ngIf="mail.invalid && mail.dirty && mail.value">
            <small class="text-danger" *ngIf="mail.errors?.pattern">Por favor ingresa un correo válido.</small>
          </div>
        </div>

        
      </div>
      <div class="firma-container col-xl-12">
        <div class="firma-box">
          <div class="firma-content">
            <canvas
              #signatureCanvas
              width="300"
              height="120"
              (mousedown)="startDrawing($event)"
              (mouseup)="stopDrawing()"
              (mousemove)="draw($event)"
            ></canvas>
          </div>
          <div class="firma-actions">
            <input
              type="file"
              #fileInput
              (change)="onFileSelected($event)"
              accept=".jpg,.jpeg,.png"
              style="display: none"
            />
            <input
              type="file"
              #fileInputCamera
              (change)="onFileSelected($event)"
              accept="image/*"
              capture="camera"
              style="display: none"
            />
            <div
              class="col-12"
              (click)="clearSignature()"
              style="cursor: pointer"
            >
              <div class="row">
                <div class="col-icon-img">
                  <img
                    src="assets/images/icons/recargarfirma.svg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex mt-4" (click)="clearSignature()">
          <div
            class="col-2 d-flex align-items-center justify-content-center col-icon-img"
          >
            <img
              src="assets/images/icons/Advertencia-Imagen.svg"
              class="img-fluid"
              alt=""
            />
          </div>
          <div>
            <label class="text-firma"
              >Esta firma también será considerada en el caso de adición de agua
              y/u otros.</label
            >
          </div>
        </div>
      </div>
    </div>

    <button
      type="button"
      [disabled]="isDisabled()"
      class="btn btn-primary"
      (click)="saveData()"
    >
      Aceptar
    </button>
  </form>
</div>

<div class="margin-lateral-modal">
  <ng-template #contenidoModal>
    <div>
      <div class="header-container-modal">
        <label></label>
        <button mat-icon-button class="close-icon" (click)="onCierrenModal()">
          <mat-icon aria-label="Cerrar">close</mat-icon>
        </button>
      </div>

      <div style="text-align: center">
        <img
          [src]="'assets/images/icons/Check-modal.svg'"
          class="img-fluid style-warning"
          alt=""
        /><br />
        <h6 class="text-title-modal">Guía firmada con éxito</h6>
        <p class="text-descripcion-modal">
          Podrás visualizar la guía firmada cuando vuelvas a tener conexión.
        </p>
      </div>

      <div>
        <button type="button" class="btn btn-primary" (click)="goBack()">
          Aceptar
        </button>
      </div>
    </div>
  </ng-template>
</div>
