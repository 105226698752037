import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserLoggedModel } from 'src/app/shared/models/UserLoginModel.model';

@Component({
  selector: 'app-perfil-usuario-detalle',
  templateUrl: './perfil-usuario-detalle.component.html',
})
export class PerfilUsuarioDetalleComponent implements OnInit {
  constructor() {}
  @Output() eventoPadreCambiarEtapa = new EventEmitter<number>();
  @Input() numberEtapas: any;
  @Input() dataUsusario: UserLoggedModel;

  isMixer: boolean = false;

  ngOnInit(): void {
    if (this.dataUsusario.ROL === 'MIXER') this.isMixer = true;
  }

  onChangeEdicion() {
    this.eventoPadreCambiarEtapa.emit(this.numberEtapas.Modifi);
  }
}
