<div [class]="'call-to-action ' + classModal">
  <div class="content lightbox-content-show">
    <div class="form-remove-file">
      <div class="content-header">
        <div
          class="icon-hide close-call icon-close"
          style="cursor: pointer"
          (click)="cerrarModal()"
        >
          Cerrar
        </div>
      </div>
      <ng-container *ngIf="!finishedProcess">
        <h2 class="text-center">Editar programación</h2>
        <!-- <p class="text-center">Si optas por editar la programación perderás todos los datos guardados.</p> -->
        <form class="form-solicitud" [formGroup]="form">
          <div class="form-group">
            <input
              class="form-input mat-input"
              type="number"
              placeholder="Permitido aumentar o disminuir 3m3 "
              formControlName="cantidad"
              min="3"
              (keyup)="onkeypressValidarCantidadMinima($event)"
              (change)="onkeypressValidarCantidadMinima($event)"
              (paste)="onPasteValidarCantidadMinima($event)"
              name="inputCantidad"
              max="9999"
              [value]="cantidadSelecionada"
            />
            <label class="mat-label" for="inputCantidad">Cantidad total</label>
            <div class="messages"></div>
          </div>
          <div *ngIf="ErrorCantidad" class="alert alert-danger">
            {{MsgErrorCantidad}}
          </div>
        </form>

        <div class="buttons">
          <button
            type="button"
            [class]="'btn btn-primary btn-enviar-solicitud'"
            [disabled]="ErrorCantidad"
            (click)="editarPedido()"
          >
            Editar
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="finishedProcess && successfulProcess">
        <div class="response">
          <h2 class="text-center">Programación modificada</h2>
          <p class="text-center">La programación fue editada con éxito.</p>

          <div class="number-ticket">
            <i class="icon-form-ok"></i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="finishedProcess && !successfulProcess">
        <div class="response">
          <h2 class="text-center">
            Lo sentimos, tu solicitud no pudo ser enviada
          </h2>
          <p class="text-center">
            Por favor intenta editar tu programación nuevamente más tarde.
          </p>

          <div class="number-ticket">
            <i class="icon-form-error"></i>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
