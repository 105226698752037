import { Component, OnInit } from '@angular/core';
import { AccountService, AlertService } from 'src/app/services/auth';
import { MenuService } from 'src/app/services/menu/menu-service';
import { PerfilService } from 'src/app/services/perfil/perfil.service';
import { PerfilModel } from 'src/app/shared/models/PerfilModel';
import { UserLoggedModel } from 'src/app/shared/models/UserLoginModel.model';

@Component({
  selector: 'app-cambiar-password',
  templateUrl: './cambiar-password.component.html'
})
export class CambiarPasswordComponent implements OnInit {
  EnumEtapas= {
    CambiarPassword: 0,
    Ok: 1 
  };
 private etapa:Number=this.EnumEtapas.CambiarPassword;
 dataUsuario:UserLoggedModel;
  constructor(menuService:MenuService, 
    private usuarioService:AccountService,
    private perfilSevice:PerfilService,
    private alertService: AlertService) {
    menuService.tilteMenu="Cambiar Contraseña";
    menuService.btnPrevius=true;
    this.dataUsuario= usuarioService.userValue;
  }
  ngOnInit(): void {
  }
  validarEtapa(enumEtapa:number)
  {
    return this.etapa === enumEtapa;
  }
  onChangeEtapa(enumEtapa:number)
  {
    this.etapa=enumEtapa;
  }
  actualizarClaveUsuario(clave:string)
  {
    let perfil = new PerfilModel();
      perfil.Clave=clave;
      perfil.FONO_USUARIO=this.dataUsuario.FONO_USUARIO;
      perfil.Key=this.dataUsuario.KEY;
      perfil.MAIL_USUARIO=this.dataUsuario.MAIL_USUARIO;
      perfil.NOMBRE_USUARIO=this.dataUsuario.NOMBRE_USUARIO;
      perfil.RUT=this.dataUsuario.RUT_USUARIO;
      perfil.opemail1=this.dataUsuario.opemail1;
      perfil.opemail2=this.dataUsuario.opemail2;
      perfil.opemail3=this.dataUsuario.opemail3;
      perfil.opemail4=this.dataUsuario.opemail4;
      perfil.opsms=this.dataUsuario.opsms;
    this.perfilSevice.updatePerfil(perfil).subscribe((x)=>
    {
      if (x["Estado"]==="Modificado")
      {
        
        this.usuarioService.saveClave(perfil.Clave);
        this.etapa=this.EnumEtapas.Ok;
      }else
      {
        this.alertService.error("Error actualizar la contraseña. Reintente");
      }
    }
    );
  }
}
