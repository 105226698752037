import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService, AlertService } from 'src/app/services/auth';
//import { EnviarMailService } from 'src/app/services/saldo-cliente/enviar-mail.service';
import { MailSaldoCliente } from 'src/app/shared/models/MailSaldoClientModel';
import { validateRut,cleanRut,getRutDv } from "chilean-formatter";
import { ignoreElements } from 'rxjs/operators';

@Component({
  selector: 'app-solicitar-clave',
  templateUrl: './solicitar-clave.component.html',
  providers:[]

})
export class SolicitarClaveComponent implements OnInit {

  requestPassForm: FormGroup;
  submitted:boolean =false;
  showExitMessage:boolean = false;

  constructor(private formBuilder: FormBuilder,/*private mailService:EnviarMailService*/
    private router:Router,private alertService: AlertService ) { }




  ngOnInit(): void {


    this.requestPassForm = this.formBuilder.group({

      rutEmp: ['', { validators:  [Validators.required, Validators.minLength(8),Validators.maxLength(10),Validators.pattern("^[0-9]+[0-9kK]{1}$")], updateOn: "blur" }],
      nombreEmp: ['', { validators:  [Validators.required, Validators.minLength(2),Validators.maxLength(100)] , updateOn: "blur"}],
      rutUsuario: ['',{ validators:  [Validators.required, Validators.minLength(8),Validators.maxLength(12)], updateOn: "blur" }],
      nombreUsuario: ['',{ validators:  [Validators.required, Validators.minLength(2),Validators.maxLength(100)], updateOn: "blur" }],
      email: ['',{ validators:  [Validators.required, Validators.minLength(2),Validators.maxLength(200), Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")], updateOn: "blur" }],
      fono : ['',{ validators:  [Validators.required, Validators.minLength(8),Validators.maxLength(15)] , updateOn: "blur"}],
      rol:['',{ validators:  [Validators.required] }],
    });
  }


  get f() { return this.requestPassForm.controls; }



  //quita todo lo que no sea digitos
  filterOnlyDigits(val){
    return val.replace(/[^0-9]/g, '');
  }

  applyFilterFono(val:string){
    let fono = this.filterOnlyDigits(val);
    this.requestPassForm.get("fono").setValue(fono);
  }


  onSubmit(){


    this.alertService.clear();
    this.submitted= true;
    debugger;
    let rutEmp= this.requestPassForm.get("rutEmp").value;
    let rutUsuario= this.requestPassForm.get("rutUsuario").value;
    let rutEmpValidation = (validateRut(rutEmp.toUpperCase()) &&  (rutEmp.length >= 8 )?true : false);
    let rutUsuarioValidation = (validateRut(rutUsuario.toUpperCase()) &&  (rutUsuario.length >= 8 )?true : false);




    if(!this.requestPassForm.valid){
        return;
    }

    if(!rutEmpValidation){
      this.alertService.error("El RUT de empresa es incorrecto");
      this.requestPassForm.controls['rutEmp'].setErrors({'incorrect': true});
    }
    if(!rutUsuarioValidation){
      this.alertService.error("El RUT de usuario es incorrecto");
      this.requestPassForm.controls['rutUsuario'].setErrors({'incorrect': true});
    }


    if(!rutEmpValidation || !rutUsuarioValidation)return;





    let data: any = this.requestPassForm.value;
    try{

      /*this.mailService.enviarMailSolicitudPassword(data).subscribe((res)=> {
        if(res)
          this.showExitMessage = res;
      });*/
     }
    catch (ex) {
      console.log(ex);
    }

  }

  back(){
    this.router.navigate(['/']);
  }



}
