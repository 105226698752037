import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Ng9RutModule } from 'ng9-rut';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor, JwtInterceptor } from './services/auth/helpers';
import { LayoutComponent } from './components/layout/layout.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { P404Component } from './components/p404/p404.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecoverpasswordComponent } from './components/recoverpassword/recoverpassword.component';
import { AlertComponent } from './components/alert';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DashboardlayoutComponent } from './components/dashboardlayout/dashboardlayout.component';
import { MatButtonModule } from '@angular/material/button';
import { ModalCallToActionComponent } from './components/modal-call-to-action/modal-call-to-action.component';
import { ModalDescargarComponent } from './components/modal-descargar/modal-descargar.component';
import { ModalCompartirComponent } from './components/modal-compartir/modal-compartir.component';
import { DashboardBottomComponent } from './components/dashboard-bottom/dashboard-bottom.component';
import { ExcelService } from './services/export/excel.service';
import { ModalPerfilComponent } from './components/modal-perfil/modal-perfil.component';
import { MenuService } from './services/menu/menu-service';
import { ModalConfirmarCerrarSessionComponent } from './components/modal-confirmar-cerrar-session/modal-confirmar-cerrar-session.component';
import { PerfilUsuarioComponent } from './components/perfil-usuario/perfil-usuario.component';
import { PerfilUsuarioDetalleComponent } from './components/perfil-usuario/perfil-usuario-detalle/perfil-usuario-detalle.component';
import { PerfilUsuarioModifiComponent } from './components/perfil-usuario/perfil-usuario-modifi/perfil-usuario-modifi.component';
import { PerfilUsuarioChangeOkComponent } from './components/perfil-usuario/perfil-usuario-change-ok/perfil-usuario-change-ok.component';
import { CambiarPasswordComponent } from './components/cambiar-password/cambiar-password.component';
import { CambiarPasswordModifiComponent } from './components/cambiar-password/cambiar-password-modifi/cambiar-password-modifi.component';
import { CambiarPasswordOkComponent } from './components/cambiar-password/cambiar-password-ok/cambiar-password-ok.component';
import { NotificacionesComponent } from './components/notificaciones/notificaciones.component';
import { PerfilDirective } from './directivas/perfil.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HistorialProgramacionComponent } from './components/historial-programacion/historial-programacion.component';
import { HistorialProgramacionItemComponent } from './components/historial-programacion/historial-programacion-item/historial-programacion-item.component';
import { HistorialProgramacionDetalleComponent } from './components/historial-programacion/historial-programacion-detalle/historial-programacion-detalle.component';
import { HistorialProgramacionFiltrosComponent } from './components/historial-programacion/historial-programacion-filtros/historial-programacion-filtros.component';
import { HistorialPedidosService } from './services/historial-pedidos/historial-pedidos.service';
import { CanDeactivateGuard } from './services/auth/helpers/can-deactivate.guard';
import { NgPipesModule } from 'ngx-pipes';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderInterceptorService } from './interceptors/loader/loader-interceptor.service';
import { ModalAnularPedidoComponent } from './components/historial-programacion/historial-programacion-detalle/modal-anular-pedido/modal-anular-pedido.component';
import { SolicitarClaveComponent } from './components/solicitar-clave/solicitar-clave.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatStepperModule } from '@angular/material/stepper';
import { OnlyNumbersDirective } from './directivas/only-numbers.directive';
import { ModalEditarPedidoComponent } from './components/historial-programacion/historial-programacion-detalle/modal-editar-pedido/modal-editar-pedido.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CommonModule } from '@angular/common';
import { ModalDescargarItemsComponent } from './components/historial-programacion/historial-programacion-filtros/modal-descargar-items/modal-descargar-items.component';
import { EncuestaSatisfacionComponent } from './components/encuesta-satisfacion/encuesta-satisfacion.component';
import { PedidoPendienteComponent } from './components/pedido-pendiente/pedido-pendiente.component';
import { PedidoEntregadosComponent } from './components/pedido-entregados/pedido-entregados.component';
import { PedidoPendienteDetalleComponent } from './components/pedido-pendiente/pedido-pendiente-detalle/pedido-pendiente-detalle.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { PedidosPendienteFiltroComponent } from './components/pedido-pendiente/pedidos-pendiente-filtro/pedidos-pendiente-filtro.component';
import { PedidosPendienteVisualizarguiaComponent } from './components/pedido-pendiente/pedidos-pendiente-visualizarguia/pedidos-pendiente-visualizarguia.component';
import { PedidosPendienteObservacionesComponent } from './components/pedido-pendiente/pedido-pendiente-detalle/pedidos-pendiente-observaciones/pedidos-pendiente-observaciones.component';
import { PedidosPendienteFirmaComponent } from './components/pedido-pendiente/pedido-pendiente-detalle/pedidos-pendiente-firma/pedidos-pendiente-firma.component';
import { PedidosPendienteQrComponent } from './components/pedido-pendiente/pedido-pendiente-detalle/pedidos-pendiente-qr/pedidos-pendiente-qr.component';
import { QRCodeModule } from 'angularx-qrcode';
import { RechazoCamionComponent } from './components/rechazo-camion/rechazo-camion.component';
import { MatOptionModule } from '@angular/material/core';
import { HistorialProgramacionItemEditarComponent } from './components/historial-programacion/historial-programacion-item/historial-programacion-item-editar/historial-programacion-item-editar.component';
import { HistorialProgramacionItemGuiasComponent } from './components/historial-programacion/historial-programacion-item/historial-programacion-item-guias/historial-programacion-item-guias.component';
import { HistorialProgramacionSeguimientoComponent } from './components/historial-programacion-seguimiento/historial-programacion-seguimiento.component';
import { HistorialProgramacionSeguimientoComentariosComponent } from './components/historial-programacion-seguimiento/historial-seguimiento-comentarios/historial-programacion-seguimiento-comentarios.component';
import { EncuestaGuiaComponent } from './components/encuesta-guia/encuesta-guia.component';
import { HistorialSeguimientoFirmaComponent } from './components/historial-programacion-seguimiento/historial-seguimiento-comentarios/historial-seguimiento-firma/historial-seguimiento-firma.component';

import { OrderService } from './services/pedidos/pedidos.service';
import { VisualizarImagenesPedidosComponent } from './components/visualizar-imagenes-pedidos/visualizar-imagenes-pedidos.component';
import { GeolocationService } from './services/geolocation/geolocation.service';
import { ChatComponent } from './components/chat/chat.component';
// Add OwlModule to imports at below section

@NgModule({
  exports: [
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatStepperModule,
    MatOptionModule,
  ],
  imports: [
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  declarations: [],
})
export class MaterialModule {}

@NgModule({
  declarations: [
    LoaderComponent,
    AppComponent,
    LoginComponent,
    LayoutComponent,
    UnauthorizedComponent,
    P404Component,
    DashboardComponent,
    RecoverpasswordComponent,
    AlertComponent,
    DashboardlayoutComponent,
    ModalCallToActionComponent,
    ModalDescargarComponent,
    ModalCompartirComponent,
    DashboardBottomComponent,
    ModalPerfilComponent,
    ModalConfirmarCerrarSessionComponent,
    PerfilUsuarioComponent,
    PerfilUsuarioDetalleComponent,
    PerfilUsuarioModifiComponent,
    PerfilUsuarioChangeOkComponent,
    CambiarPasswordComponent,
    CambiarPasswordModifiComponent,
    CambiarPasswordOkComponent,
    NotificacionesComponent,
    PerfilDirective,
    HistorialProgramacionComponent,
    HistorialProgramacionItemComponent,
    HistorialProgramacionDetalleComponent,
    ModalAnularPedidoComponent,
    ModalEditarPedidoComponent,
    HistorialProgramacionFiltrosComponent,
    SolicitarClaveComponent,
    OnlyNumbersDirective,
    ModalDescargarItemsComponent,
    PedidoPendienteComponent,
    PedidoEntregadosComponent,
    PedidoPendienteDetalleComponent,
    PedidosPendienteFiltroComponent,
    PedidosPendienteVisualizarguiaComponent,
    PedidosPendienteObservacionesComponent,
    PedidosPendienteFirmaComponent,
    PedidosPendienteQrComponent,
    RechazoCamionComponent,
    HistorialProgramacionItemEditarComponent,
    HistorialProgramacionItemGuiasComponent,
    HistorialProgramacionSeguimientoComponent,
    HistorialProgramacionSeguimientoComentariosComponent,
    EncuestaGuiaComponent,
    HistorialSeguimientoFirmaComponent,
    VisualizarImagenesPedidosComponent,
    ChatComponent,
    EncuestaSatisfacionComponent
  ],
  imports: [
    BrowserModule,
    NgPipesModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatNativeDateModule,
    MatDatepickerModule,
    PdfViewerModule,
    Ng9RutModule,
    MaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatMomentDateModule,
    MatStepperModule,
    SlickCarouselModule,
    CommonModule,
    GoogleMapsModule,
    QRCodeModule,
  ],
  providers: [
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
    ExcelService,
    MenuService,
    HistorialPedidosService,
    CanDeactivateGuard,
    OrderService,
    GeolocationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
