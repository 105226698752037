import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AccountService, AlertService } from 'src/app/services/auth';
import { ViajesService } from 'src/app/services/viajes-cli/viajes.service';

@Component({
  selector: 'app-historial-seguimiento-firma',
  templateUrl: './historial-seguimiento-firma.component.html',
  styleUrls: ['./historial-seguimiento-firma.component.css'],
})
export class HistorialSeguimientoFirmaComponent implements OnInit {
  @ViewChild('contenidoModal') contenidoModal!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileInputCamera') fileInputCamera: ElementRef;
  dialogRef: MatDialogRef<any>;
  isChecked: boolean = false;
  isGuiaOpen: boolean = false;
  @Input() pedido: any;

  @ViewChild('signatureCanvas') signatureCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('modalContentSurveySuccess')
  modalContentSurveySuccess!: TemplateRef<any>;
  private context: CanvasRenderingContext2D;
  private drawing = false;
  isMobile: boolean;
  isTablet: boolean;
  preloadSignature: boolean = false;
  innerWidth: number;

  IsComplet = false;
  isWithImage = false;

  isAnonimo:boolean=false;
  constructor(
    private dialog: MatDialog,
    private ViajesServ: ViajesService,
    private alertService: AlertService,
    private deviceService: DeviceDetectorService,
    private accountService: AccountService,
    private router: Router,
    private scrollStrategyOptions: ScrollStrategyOptions
  ) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();

    if (!this.accountService.userValue) {
      this.isAnonimo=true;
      this.isChecked=true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.updateCanvasSize();
  }

  ngAfterViewInit() {
    this.context = this.signatureCanvas.nativeElement.getContext('2d');
    this.updateCanvasSize();
    if (this.pedido.MX_CLI_FIRMA) this.drawImage(this.pedido.MX_CLI_FIRMA);

    
  }

 

  updateCanvasSize() {
    if (innerWidth < 768) {
      this.context.canvas.width = 300;
      this.context.canvas.height = 150;
    } else {
      this.context.canvas.width = 580;
      this.context.canvas.height = 150;
    }
    if (this.isMobile || this.isTablet) this.touchStart();
  }

  startDrawing(event: MouseEvent) {
    this.drawing = true;
    this.context.beginPath();
    this.context.moveTo(this.getMouseX(event), this.getMouseY(event));
  }

  draw(event: MouseEvent) {
    if (!this.drawing) return;
    this.context.lineTo(this.getMouseX(event), this.getMouseY(event));
    this.context.stroke();
  }

  stopDrawing() {
    this.drawing = false;
    this.pedido.MX_CLI_FIRMA = this.getSignatureAsBase64();
  }

  clearSignature() {
    this.context.clearRect(
      0,
      0,
      this.signatureCanvas.nativeElement.width,
      this.signatureCanvas.nativeElement.height
    );
    this.pedido.MX_CLI_FIRMA = '';
    this.isWithImage = false;
  }

  private getMouseX(event: MouseEvent): number {
    return (
      event.clientX -
      this.signatureCanvas.nativeElement.getBoundingClientRect().left
    );
  }

  private getMouseY(event: MouseEvent): number {
    return (
      event.clientY -
      this.signatureCanvas.nativeElement.getBoundingClientRect().top
    );
  }

  getSignatureAsBase64(): string {
    return this.signatureCanvas.nativeElement.toDataURL('image/png');
  }

  touchStart() {
    this.signatureCanvas.nativeElement.ontouchstart = (event) => {
      event.preventDefault();
      this.drawing = true;
      this.context.beginPath();
      this.context.moveTo(this.getTouchX(event), this.getTouchY(event));
    };
    this.signatureCanvas.nativeElement.ontouchmove = (event) => {
      event.preventDefault();
      if (!this.drawing) return;
      this.context.lineTo(this.getTouchX(event), this.getTouchY(event));
      this.context.stroke();
    };
    this.signatureCanvas.nativeElement.ontouchend = (event) => {
      event.preventDefault();
      this.drawing = false;
      this.pedido.MX_CLI_FIRMA = this.getSignatureAsBase64();
    };
  }

  getTouchX(event: TouchEvent): number {
    return (
      event.touches[0].clientX -
      this.signatureCanvas.nativeElement.getBoundingClientRect().left
    );
  }

  getTouchY(event: TouchEvent): number {
    return (
      event.touches[0].clientY -
      this.signatureCanvas.nativeElement.getBoundingClientRect().top -
      10
    );
  }

  getDefaultSignature() {
    if (!this.accountService.userValue.firma) return;
    this.drawImage(this.accountService.userValue.firma);
    this.pedido.MX_CLI_FIRMA = this.accountService.userValue.firma;
  }

  drawImage(preview: string) {
    this.clearSignature();
    this.context.clearRect(
      0,
      0,
      this.signatureCanvas.nativeElement.width,
      this.signatureCanvas.nativeElement.height
    );
    const image = new Image();
    image.onload = () => {
      this.context.drawImage(image, 0, 0);
    };
    image.onload = () => {
      const ratio = Math.min(
        this.context.canvas.width / image.width,
        this.context.canvas.height / image.height
      );
      const x = (this.context.canvas.width - image.width * ratio) / 2;
      const y = (this.context.canvas.height - image.height * ratio) / 2;
      const width = image.width * ratio;
      const height = image.height * ratio;

      this.context.drawImage(image, x, y, width, height);
    };
    image.src = preview;
  }

  havePreloadSignature() {
    return this.accountService.userValue== null? false: this.accountService.userValue.firma;
  }

  isValidData() {
    return (
      this.pedido &&
      this.pedido.MX_CLI_FIRMA != '' &&
      (!this.isChecked ||
        (this.pedido.MX_CLI_FIRMA_RUT != '' &&
          this.pedido.MX_CLI_FIRMA_NOMBRE != '' && this.pedido.MX_CLI_FIRMA_CORREO != ''))
    );
  }

  trimLeadingWhitespaceAndUpdateModel(event: Event, ngModel: any) {
    const input = event.target as HTMLInputElement;
    const trimmedValue = input.value;
    input.value = trimmedValue;
    ngModel.control.setValue(trimmedValue);
  }

  guardar() {
    this.alertService.clear();

    if (this.pedido.MX_CLI_FIRMA_RUT == '' && !this.isAnonimo) {
      this.pedido.MX_CLI_FIRMA_RUT = this.accountService.userValue.RUT_USUARIO;
      this.pedido.MX_CLI_FIRMA_NOMBRE =
        this.accountService.userValue.NOMBRE_USUARIO;
    }

    this.pedido.MX_CLI_FIRMA = this.getSignatureAsBase64();
    console.log(this.isWithImage);
    if (!this.isWithImage)
      this.ViajesServ.UpdateFirmaGuiasCliente(this.pedido).subscribe(
        (result) => {
          if (result?.unknownError) {
            this.alertService.error('No se pudo actualizar el pedido.');
            return;
          }
          if (result?.Estado == 'Insertado') {
            debugger;
            this.ViajesServ.RunPdfGuia().subscribe((response) => {
              //actualizo los pdf

              if (!this.pedido.PDF_GUIA_H.includes('GD_en_proceso')) {
                this.pedido.PDF_GUIA_H = this.pedido.PDF_GUIA_H.replace(
                  '.pdf',
                  '_.pdf'
                );
              }

              this.IsComplet = true;
            });
          } else {
            this.alertService.error('No se pudo actualizar el pedido.');
            return;
          }
        }
      );
    else
      this.ViajesServ.UpdateFirmaGuiasClienteWithImage(this.pedido).subscribe(
        (result) => {
          if (result?.unknownError) {
            this.alertService.error('No se pudo actualizar el pedido.');
            return;
          }
          if (result?.Estado == 'Insertado') {
            debugger;
            this.ViajesServ.RunPdfGuia().subscribe((response) => {
              //actualizo los pdf

              if (!this.pedido.PDF_GUIA_H.includes('GD_en_proceso')) {
                this.pedido.PDF_GUIA_H = this.pedido.PDF_GUIA_H.replace(
                  '.pdf',
                  '_.pdf'
                );
              }

              this.IsComplet = true;
            });
          } else {
            this.alertService.error('No se pudo actualizar el pedido.');
            return;
          }
        }
      );
  }

  showGuia() {
    this.isGuiaOpen = true;
  }

  closeGuia() {
    this.isGuiaOpen = false;
  }

  togglePreloadSignature() {
    this.preloadSignature = !this.preloadSignature;
    if (this.preloadSignature) this.getDefaultSignature();
    else this.clearSignature();
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.drawImage(e.target.result);
        this.isWithImage = true;
        this.pedido.MX_CLI_FIRMA = e.target.result;
      };
      reader.readAsDataURL(file);
    }

    this.fileInput.nativeElement.value = '';
    if (this.isMobile) this.fileInputCamera.nativeElement.value = '';
  }

  openFileSelectorCamera() {
    this.fileInputCamera.nativeElement.click();
  }

  openFileSelector() {
    this.fileInput.nativeElement.click();
  }

  showModalSurveySuccess() {
    this.dialogRef = this.dialog.open(this.modalContentSurveySuccess, {
      maxWidth: '90%',
      disableClose: true,
      scrollStrategy: this.scrollStrategyOptions.noop(),
    });
  }

  closeModal() {
    this.router.navigate(['/historialprogramacion']);
    this.dialogRef.close();
  }
}
