    
    <header class="header-user">
        <a [routerLink]="['/login']" class="icon-hide back">Volver</a>
        <h1>Solicitud de Clave</h1>
    </header>
    <!--end header--->
    
    <!--editar perfil de usuario-->
    
    <div class="dashboard">
      
      
    <form *ngIf="!showExitMessage" [formGroup]="requestPassForm" id="formCambiarPerfil" (ngSubmit)="onSubmit()">
            
            <div class="header-page">
                <h2 class="text-center py-2">Completa los siguientes campos para solicitar tu usuario y clave Polpaico</h2>
            </div>
           
            <div class="form-group">
                
                <input id="rutEmpresa" style="padding-right: 8%;" class="form-input mat-input form-control" type="text"  maxlength="9"
                [ngClass]="{ 'is-invalid': submitted && f.rutEmp.errors }" placeholder="Rut de Empresa" name="rutemp" formControlName="rutEmp">
                <!-- <label for="rut" class="mat-label">RUT</label> -->
                <label style="bottom: unset;" for="rut" class="mat-label">Rut de empresa</label>
                <div *ngIf="submitted && f.rutEmp.errors" class="invalid-feedback pull-left">
                    <div *ngIf="f.rutEmp.errors" class="pull-left">
                        Ingresa un rut válido en formato 12345678K.
                    </div>
                  
                </div>
            
            </div>
    
    
            <div class="form-group">
                <input type="text" style="padding-right: 8%;" minlength="1" maxlength="100" class="form-input mat-input form-control" [ngClass]="{ 'is-invalid': submitted && f.nombreEmp.errors }" 
                name="nombreEmpresa" id="nombreEmpresa" formControlName="nombreEmp" placeholder="Nombre Empresa">
                <label style="bottom: unset;" for="rut" class="mat-label">Nombre empresa</label>
                <div *ngIf="submitted && f.nombreEmp.errors" class="invalid-feedback pull-left">
                    <div *ngIf="f.nombreEmp.errors" class="pull-left">
                        Ingrese el nombre de la empresa.
                    </div>
                    
                </div>
               
            </div>
    
            <div class="form-group">
                <input type="text" style="padding-right: 8%;" [ngClass]="{ 'is-invalid': submitted && f.rutUsuario.errors }" 
                class="form-input mat-input form-control" 
                name="rutUsuario" id="rutUsuario" maxlength="9" formControlName="rutUsuario" placeholder="Rut de Usuario">
                <label style="bottom: unset;" for="rut" class="mat-label">Rut de usuario</label>
                <div *ngIf="submitted && f.rutUsuario.errors" class="invalid-feedback pull-left">
                    <div *ngIf="f.rutUsuario.errors" class="pull-left">
                        Ingresa un rut válido en formato 12345678K.
                    </div>
                </div>
            </div>
    
                
    
    
    
    
            <div class="form-group">
                <input type="text" style="padding-right: 8%;" minlength="2" maxlength="100"  [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }" class="form-input mat-input form-control" 
                name="nombreUsuario" formControlName="nombreUsuario" id="nombreUsuario" placeholder="Nombre Usuario">
                <label style="bottom: unset;" for="rut" class="mat-label">Nombre de usuario</label>
                <div *ngIf="submitted && f.nombreUsuario.errors" class="invalid-feedback pull-left">
                    <div *ngIf="f.nombreUsuario.errors" class="pull-left">
                        Ingresa un nombre de usuario.
                    </div>
                </div>
            </div>
    
            <div class="form-group">
                <input type="text" style="padding-right: 8%;" minlength="5" maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-input mat-input form-control" 
                name="email" formControlName="email" id="email" placeholder="Correo">
                <label style="bottom: unset;" for="rut" class="mat-label">Correo</label>
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback pull-left">
                    <div *ngIf="f.email.errors" class="pull-left">
                        Ingrese un correo válido.
                    </div>
                </div>
            </div>
    
            <div class="form-group">
                <input type="text" style="padding-right: 8%;" minlength="8" maxlength="15" #fono (keyup)="applyFilterFono(fono.value)" [ngClass]="{ 'is-invalid': submitted && f.fono.errors }" class="form-input mat-input form-control" 
                name="telefono"  formControlName="fono"  id="telefono" placeholder="Teléfono">
                <label style="bottom: unset; " for="rut" class="mat-label">Télefono</label>
                <div *ngIf="submitted && f.fono.errors" class="invalid-feedback pull-left">
                    <div *ngIf="f.fono.errors" class="pull-left">
                        Ingrese un télefono de 8 dígitos o más
                    </div>
                </div>
            </div>

            <div class="form-group">
                <select type="text" style="padding-right: 8%;" minlength="8" maxlength="15" #rol  [ngClass]="{ 'is-invalid': submitted && f.rol.errors }" class="form-input mat-input form-control" 
                name="Rol"  formControlName="rol"  id="rol" placeholder="Teléfono">
                <option value="" selected>Selecciona tu Rol</option>
                <option value="Rol_Programador" >Programador</option>
                <option value="Rol_Oficina" >Oficina central</option>
                <option value="Rol_Calidad" >Calidad</option>
                <option value="Rol_Admin" >Administrador</option>
                </select>
                <label style="bottom: unset; " for="rut" class="mat-label">Rol</label>
                <div *ngIf="submitted && f.rol.errors" class="invalid-feedback pull-left">
                    <div *ngIf="f.rol.errors" class="pull-left">
                        Ingrese un rol
                    </div>
                </div>
            </div>
        
            <!-- <div class="buttons button-sticky-bottom"> -->
                <div class="buttons">
                <button class="btn btn-primary btn-cambiar-perfil" id="formButton" type="submit">Enviar</button>
            </div>
        </form>
    
        <!--respuesta form-->
        <div class="respuesta-form" *ngIf="showExitMessage">
            <div class="content">
                <h2 class="text-center">Tu solicitud de usuario y clave ha sido enviada con éxito.</h2>
                <p class="text-center">En 24 horas te llegará un correo con tus credenciales.</p>
                <div class="number-ticket">
                    <i class="icon-form-ok"></i>
                </div>
                <div class="buttons">
                    <button type="button" (click)="back()" class="btn btn-primary btn-back"><i class="icon-hide icon-back"></i>Volver</button>
                </div>
            </div>
        </div>
        <!--end respuesta form lightbox-->
        
    </div>
    
    <script src="js/form-new-user.js"></script>
    
    
