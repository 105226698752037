import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private chatVisibleSubject = new BehaviorSubject<boolean>(false);
  chatVisible$: Observable<boolean> = this.chatVisibleSubject.asObservable();
  private timeLeft = 1800; // 30 minutes in seconds

  constructor() {
    this.startTimer();
  }

  sendMessage() {
    this.resetTimer();
  }

  private startTimer() {
    timer(0, 1000)
      .pipe(
        takeWhile(() => this.timeLeft > 0)
      )
      .subscribe(() => {
        this.timeLeft--;
        if (this.timeLeft === 0) {
          this.chatVisibleSubject.next(false);
        }
      });
  }

  private resetTimer() {
    this.timeLeft = 1800; 
    this.startTimer();
  }
}
