export class DataHistorialPedidosModel {
    public ID_WEB: string
    public ID_CMD: string
    public COD_OBRA: string
    public CONFIR: string
    public OBRA: string
    public COD_CLI: string
    public RUT_EMPRESA: string
    public NOMBRE_EMPRESA: string
    public RUT_SOLICITA: string
    public NOMBRE_SOLICITA: string
    public FONO_SOLICITA: string
    public EMAIL_SOLICITA: string
    public NOMBRE_RECEPCIONA: string
    public FONO_RECEPCIONA: string
    public EMAIL_RECEPCIONA: string
    public NOMBRE_RECEPCIONA2: string
    public FONO_RECEPCIONA2: string
    public EMAIL_RECEPCIONA2: string
    public FECHA_PEDIDO: string
    public FECHA_SOL: string
    public HORA_SOL: string
    public CANT: string
    public PROD_COD: string
    public PRODUCTO: string
    public MUESTRA: string
    public T_CARGA: string
    public M_DESCARGA: string
    public FRECUENCIA: string
    public CANT_PROG: string
    public ESPECIFICAC: string
    public ESTADO: string
    public FECHA_MODIFICAC: string
    public SERVICIO: string
    public SERVICIOS: string
    public LLEGAPCAM :string
    public COD_ANUL: string
    public GUIA_PENDIENTE_FIRMA: string
    public TIENEVIAJES: string
}
export class HistorialPedidosModel {
    public unknownError?;
    public datos: DataHistorialPedidosModel[]
}

export class RequestAnulacionPedido {
    cod_obra: any
    confir:any
    obra: any
    cod_cli: any
    rut_empresa: any
    nombre_empresa: any
    nombre_recepciona: any
    fono_recepciona: any
    email_recepciona: any
    nombre_recepciona2: any
    fono_recepciona2: any
    email_recepciona2: any
    fecha_sol: any
    hora_sol: any
    cant: any
    prod_cod: any
    producto: any
    muestra: any
    t_carga: any
    m_descarga: any
    frecuencia: any
    especificac: any
    servicio: any
    ID_WEB: any
    cod_anul:any
  }
