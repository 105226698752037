<div class="dashboard">
  <div class="d-flex justify-content-center editar-perfil">
    <form
      [formGroup]="formEdicion"
      (ngSubmit)="onSubmit()"
      class="editar-perfil-form"
    >
      <div class="form-group disabled">
        <input
          id="nombre"
          class="form-input mat-input"
          type="text"
          name="nombre"
          formControlName="nombre"
        />
        <label class="mat-label" for="nombre">Nombre</label>
      </div>
      <div class="form-group disabled">
        <input
          type="text"
          class="form-input mat-input"
          name="rut"
          id="rut"
          formControlName="rut"
        />
        <label for="rut" class="mat-label">RUT </label>
      </div>
      <div class="form-group disabled">
        <input
          type="text"
          class="form-input mat-input"
          name="nombreEmpresa"
          id="nombreEmpresa"
          formControlName="nombreEmpresa"
        />
        <label for="nombreEmpresa" class="mat-label">Nombre Empresa </label>
      </div>
      <div class="form-group disabled">
        <input
          type="text"
          class="form-input mat-input"
          name="rutEmpresa"
          id="rutEmpresa"
          formControlName="rutEmpresa"
        />
        <label for="rutEmpresa" class="mat-label">RUT Empresa </label>
      </div>
      <div class="form-group disabled">
        <input
          type="text"
          class="form-input mat-input"
          name="email"
          id="email"
          formControlName="email"
        />
        <label for="email" class="mat-label">E-mail </label>
      </div>
      <div class="form-group active">
        <input
          type="text"
          class="form-input mat-input"
          name="telefono"
          id="telefono"
          formControlName="telefono"
          (input)="Modificar()"
          (keypress)="soloNumero($event)"
        />
        <label for="telefono" class="mat-label">Teléfono </label>
        <div
          class="text-left"
          *ngIf="
            formEdicion.controls['telefono'].invalid &&
            formEdicion.controls['telefono'].errors
          "
        >
          <div *ngFor="let validation of user_validation_messages.telfono">
            <div
              class="obligatorio"
              *ngIf="formEdicion.get('telefono').hasError(validation.type)"
            >
              {{ validation.message }}
            </div>
          </div>
        </div>
      </div>
      <div class="firma-container col-xl-12" *ngIf="!Mixer">
        <div class="firma-box">
          <div class="firma-content">
            <canvas
              #signatureCanvas
              width="300"
              height="120"
              (mousedown)="startDrawing($event)"
              (mouseup)="stopDrawing()"
              (mousemove)="draw($event)"
            ></canvas>
          </div>
          <div class="firma-actions">
            <div class="col-12" (click)="clearSignature()">
              <div class="row">
                <div class="col-icon-img">
                  <img
                    src="assets/images/icons/recargarfirma.svg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons mt-4 mt-md-5">
        <button
          class="btn btn-primary btn-cambiar-perfil"
          type="submit"
          [disabled]="!NewUpdate"
        >
          Actualizar Perfil
        </button>

        <div class="buttons">
          <a
            href="javascript:void(0)"
            (click)="goBack()"
            class="btn btn-primary btn-cambiar-perfil mt-3"
            ><i class="icon icon-back"></i>Volver</a
          >
        </div>
      </div>
    </form>
  </div>
</div>
